import styles from './UserHeader.module.css';
import type { ReactNode } from 'react';
import type { TCommunitiesForBadge, TRole } from '@tiger21-llc/connect-shared/src/typings';
import { Avatar } from '@ui-kit/components';
import { noop } from '@utils';

const UserHeader = ({ fullName, roles, avatarUrl, communitiesForBadge, children, onClick }: IUserHeaderProps) => {
	return (
		<div className={styles.userHeader__header}>
			<div className={styles.userHeader__headerAvatar} onClick={onClick} onKeyDown={noop}>
				<Avatar imageUri={avatarUrl} outline={roles.includes('ROLE_CHAIR')} size={32} title={fullName} />
				<div className={styles.userHeader__userInfo}>
					<span className={styles.userHeader__userTitle}>{fullName}</span>
					{communitiesForBadge ? (
						<span className={styles.userHeader__userSubtitle}>{communitiesForBadge?.shortNames?.join(' • ')}</span>
					) : null}
				</div>
			</div>
			<div className={styles.userHeader__footer}>{children}</div>
		</div>
	);
};

export interface IUserHeaderProps {
	fullName: string;
	roles: TRole[];
	avatarUrl?: string;
	communitiesForBadge?: TCommunitiesForBadge;
	/** React component to render in the right side of the header */
	children?: ReactNode;
	onClick?: () => void;
}

export default UserHeader;
