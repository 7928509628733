import { useService, useMutation, useNotification } from '@hooks';
import type { TTip, TTipReview } from '@typings';
import type { MutateOptions } from '@tanstack/react-query';
import type { TTipReviewWithSharingForm } from '@schemas';

export const useEditTipReviewMutation = (options?: MutateOptions<void, Error, TEditTipMutationPayload>) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showUnknownError } = useNotification();

	return useMutation<void, Error, TEditTipMutationPayload>(
		['sendTipReview'],
		async ({ tipReviewId, ...tipReviewForm }) => {
			await api.tips.putTipReview(tipReviewId, tipReviewForm);
		},
		{
			...options,
			onSuccess: async (response, variables, context) => {
				await reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.tip(variables.tipId));
				reactQuery.queryClient.invalidateQueries(
					reactQuery.queryKeys.tipReview(variables.tipId, variables.tipReviewId),
				);
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.tipReviews(variables.tipId));
				reactQuery.queryClient.removeQueries(
					reactQuery.queryKeys.attachedMeetingsForTipCreation(variables.tipReviewId),
				);
				options?.onSuccess?.(response, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};

type TEditTipMutationPayload = TTipReviewWithSharingForm & {
	tipId: TTip['id'];
	tipReviewId: TTipReview['id'];
};
