import { useInfiniteQuery, useMemo } from '@hooks';
import { noop } from '@utils';
import type { QueryFunction, QueryKey, UseInfiniteQueryOptions } from '@tanstack/react-query';

/** Wrapper around useInfiniteQuery to handle page data normalization & provide fetchMore function.
 * 	Compatible with V2 BE API, for V1 BE API use usePaginatedInfiniteQuery.ts.
 */
export const usePaginatedInfiniteQueryV2 = <
	T = unknown,
	TQueryFnData extends T[] = T[],
	TError = Error,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	queryKey: TQueryKey,
	queryFn: QueryFunction<TQueryFnData, TQueryKey>,
	options?: Omit<UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>, 'queryKey' | 'queryFn'>,
) => {
	const infiniteQuery = useInfiniteQuery<TQueryFnData, TError, TData, TQueryKey>(queryKey, queryFn, {
		...options,
		getNextPageParam: (lastPage, allPages) => {
			const lastPageLength = lastPage?.length;
			const allPagesLength = allPages?.length;
			return lastPageLength === 0 || lastPageLength < 10 ? null : allPagesLength + 1;
		},
	});
	const data = useMemo(
		function normalizeData() {
			return infiniteQuery.data ? (infiniteQuery.data.pages.flat() as T[]) : [];
		},
		[infiniteQuery.data],
	);

	const fetchMore = infiniteQuery.hasNextPage ? infiniteQuery.fetchNextPage : noop;

	return {
		...infiniteQuery,
		data,
		fetchMore,
	};
};
