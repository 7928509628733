import React, { useEffect } from 'react';
import { isEmpty, noop } from '@utils';

import { useState, useContext } from 'react';
import type { IInternalShare } from '@typings';

const INTERNAL_SHARE_DEFAULT_VALUE = {} as IInternalShare;

const InternalShareContext = React.createContext({
	hasInternalShare: false,
	internalShare: INTERNAL_SHARE_DEFAULT_VALUE,
	resetInternalShare: noop,
	setInternalShare: noop,
});

const useInternalShare = () => {
	const [internalShare, setInternalShare] = useState<IInternalShare>(INTERNAL_SHARE_DEFAULT_VALUE);
	const [hasInternalShare, setHasInternalShare] = useState(false);

	useEffect(() => {
		if (!isEmpty(internalShare)) {
			setHasInternalShare(true);
		}
	}, [internalShare]);

	const resetInternalShare = () => {
		setInternalShare(INTERNAL_SHARE_DEFAULT_VALUE);
		setHasInternalShare(false);
	};

	return {
		hasInternalShare,
		internalShare,
		resetInternalShare,
		setInternalShare,
	};
};

function useInternalShareContext() {
	return useContext(InternalShareContext);
}

function InternalShareProvider({ children }: IInternalShareProvideProps) {
	const { hasInternalShare, internalShare, resetInternalShare, setInternalShare } = useInternalShare();
	return (
		<InternalShareContext.Provider value={{ hasInternalShare, internalShare, resetInternalShare, setInternalShare }}>
			{children}
		</InternalShareContext.Provider>
	);
}

export interface IInternalShareProvideProps {
	children: React.ReactNode;
}

export { useInternalShareContext, InternalShareProvider };
