import { isEmpty, isNil, omitBy, toString } from '@utils';
import type {
	TFolder,
	TGroupTabName,
	TChapterTabName,
	TNetworkTabName,
	TUid,
	TReportContentType,
	TDealTabName,
	TDealsTabName,
	TDeal,
	TNetworkConnectionTabName,
	TNetworkConnection,
	TTip,
	TTipsTabName,
	TTipContext,
} from '@typings';
import { qs } from '@utils';
import type { TOktaVerificationFlow } from '../services/interfaces/OktaService.interface';
import type { TAssetAllocationTabSlug } from '@typings';
import type { TPatchTipForm } from '@schemas';

const formatToSearchParams = (params?: Record<string, unknown>) => {
	if (typeof params !== 'object' || params === null) return '';
	const preparedParams = omitBy(params, isNil);
	if (isEmpty(preparedParams)) return '';
	return '?' + qs.stringify(preparedParams);
};

export const PUBLIC_AUTH_ROUTES = {
	/** Basic login page. */
	signIn: (searchParams?: Record<string, string>) =>
		'/sign-in' + (searchParams ? `?${new URLSearchParams(searchParams)}` : ''),
	resetPassword: () => '/reset-password',
	/** User appears here after sign in if Okta will ask about MFA verification. */
	verifySignIn: (flow?: TOktaVerificationFlow) => '/sign-in/verify' + (flow ? `?flow=${flow}` : ''),
	/** Set new password form which can be opened after successful starting recover password flow and verification. */
	setNewPassword: () => '/sign-in/set-new-password',
	/** Return page from Okta sign in callback.  */
	signInCallback: () => '/sign-in/callback',
	/** Go here if you want to perform sign out. */
	signMeOut: () => '/sign-me-out',
	/** Return page from Okta sign out callback.  */
	signOut: () => '/sign-out',
	/** Return page adding an additional level of verification before allowing the user to login. */
	trustedDevice: () => '/trusted-device',
};

export const ROUTES = {
	...PUBLIC_AUTH_ROUTES,

	// Auth pages.
	home: () => '/',
	chat: () => '/chat',
	createChat: (internalShare?: string) => {
		// @todo use formatToSearchParams instead of doing it manually [@hrant]
		let url = '/chat/create';
		if (internalShare) {
			url += `?internalShare=${internalShare}`;
		}
		return url;
	},
	/** Dima as soon as add here ? in path i get no matching route error for some reason : WILL FIX */
	createChatGroup: (selectedUserIds = ':selectedUserIds', internalShare?: string) => {
		// @todo use formatToSearchParams instead of doing it manually [@hrant]
		let url = `/chat/create-group/selectedUserIds/${selectedUserIds}`;
		if (internalShare) {
			url += `?internalShare=${internalShare}`;
		}
		return url;
	},
	chatChannel: (channelId = ':channelId', internalShare?: string) => {
		// @todo use formatToSearchParams instead of doing it manually [@hrant]
		let url = `/chat/messages/${channelId}`;
		if (internalShare) {
			url += `?internalShare=${internalShare}`;
		}
		return url;
	},
	chatViewMembers: (channelId = ':channelId') => `/chat/${channelId}/members`,
	chatRenameGroup: (channelId = ':channelId') => `/chat/${channelId}/rename`,
	chatEditGroup: (channelId = ':channelId') => `/chat/${channelId}/edit`,
	chatUnPauseMembers: (channelId = ':channelId') => `/chat/${channelId}/resume-members`,

	calendar: () => '/calendar',
	calendarEvent: (eventId?: string) => (eventId ? `/calendar/events/${eventId}` : `/calendar/events/:eventId`),
	calendarMeeting: (eventId?: string) =>
		eventId ? `/calendar/group_meetings/${eventId}` : `/calendar/group_meetings/:eventId`,
	viewEvent: (eventId = ':eventId') => `/view/events/${eventId}`,
	viewMeeting: (eventId = ':eventId') => `/view/group_meetings/${eventId}`,
	learn: () => `/networks/learn`,
	learnTab: (feedName = ':feedName') => `/networks/learn/${feedName}`,

	// Tips
	tipJar: () => `/tips`,
	tipJarTab: (tipsTabName: TTipsTabName | ':tipsTabName' = ':tipsTabName', searchParams = '') =>
		`/tips/${tipsTabName}${searchParams}`,
	tipPage: (tipId = ':tipId', tipContext?: TTipContext) => `/tips/tip/${tipId}` + formatToSearchParams(tipContext),
	editTip: (tipId = ':tipId') => `/tips/tip/${tipId}/edit`,
	newTip: (tipCreateData?: TPatchTipForm) => `/tips/new` + formatToSearchParams(tipCreateData),
	newTipCategories: (tipCreateData?: TPatchTipForm & { edited?: boolean }) =>
		`/tips/new/categories` + formatToSearchParams(tipCreateData),
	newTipMatches: (tipCreateData?: TPatchTipForm) => `/tips/new/matches` + formatToSearchParams(tipCreateData),
	createTip: (tipCreateData?: TPatchTipForm) => `/tips/create` + formatToSearchParams(tipCreateData),
	createTipReview: (tipIdOrTipCreateForm?: { tipId: TTip['id'] } | TPatchTipForm) =>
		`/tips/create/review` + formatToSearchParams(tipIdOrTipCreateForm),
	tipEdit: (tipId = ':tipId') => `/tips/tip/${tipId}/edit`,
	tipReview: (
		tipId = ':tipId',
		tipReviewIdOrNew: string | 'new' | undefined = ':tipReviewIdOrNew',
		tipContext?: TTipContext,
	) => `/tips/tip/${tipId}/review/${tipReviewIdOrNew}` + formatToSearchParams(tipContext),
	meetingsRequests: () => '/group_meeting_attendance_requests',

	// Network pages
	networks: () => '/networks',
	networksType: (type: 'invest' | string) => `/networks/${type}`, // generic to navigate.
	network: (networkId = ':networkId') => `/networks/${networkId}`,
	networkLearnTab: (feedName = ':feedName') => `/networks/learn/${feedName}`,
	networkTab: (
		networkId?: string,
		networkTab: TNetworkTabName = 'discussions',
		navigateBackOnJoin = false,
		subTab = '',
	) =>
		networkId
			? // If used to navigate.
				`/networks/${networkId}/${networkTab}${navigateBackOnJoin ? '?navigateBackOnJoin=true' : ''}${
					subTab ? `?subTab=${subTab}` : ''
				}`
			: // If used as the route path.
				`/networks/:networkId/:networkTab`,
	networkFolder: (networkId?: string, folderId?: TFolder['id']) =>
		networkId
			? // If used to navigate.
				`/networks/${networkId}/files/${folderId}`
			: // If used as the route path.
				`/networks/:networkId/files/:folderId`,
	requestNetwork: () => '/networks/request',
	requestNetworkConfirmation: (networkRequestId = ':networkRequestId') => `/networks/request/${networkRequestId}`,

	// Group pages
	groups: () => '/groups',
	group: (groupId = ':groupId') => `/groups/${groupId}`,
	groupTab: (groupId?: string, groupTab: TGroupTabName = 'meetings') =>
		groupId
			? // If used to navigate.
				`/groups/${groupId}/${groupTab}`
			: // If used as the route path.
				`/groups/:groupId/:groupTab`,
	groupFolder: (groupId?: string, folderId?: TFolder['id']) =>
		groupId
			? // If used to navigate.
				`/groups/${groupId}/files/${folderId}`
			: // If used as the route path.
				`/groups/:groupId/files/:folderId`,

	// Chapter pages
	chapters: () => '/groups/chapters',
	chapter: (chapterId = ':chapterId') => `/groups/chapters/${chapterId}`,
	chapterTab: (chapterId?: string, chapterTab: TChapterTabName = 'members') =>
		chapterId
			? // If used to navigate.
				`/groups/chapters/${chapterId}/${chapterTab}`
			: // If used as the route path.
				`/groups/chapters/:chapterId/:chapterTab`,
	chapterFolder: (chapterId?: string, folderId?: TFolder['id']) =>
		chapterId
			? // If used to navigate.
				`/groups/chapters/${chapterId}/files/${folderId}`
			: // If used as the route path.
				`/groups/chapters/:chapterId/files/:folderId`,

	// Deals pages
	deals: () => '/deals',
	dealsTabs: (dealsTab?: TDealsTabName) => (dealsTab ? `/deals/${dealsTab}` : `/deals/:dealsTab`),
	dealFilters: (communityId?: string) => `/deal-filters/${communityId}`,
	allDealFilters: () => `/all-deal-filters`,

	dealFolder: (dealId?: TDeal['id'], folderId?: TFolder['id'] | null) =>
		// Only for navigate to
		folderId ? `/deal/${dealId}/files?folderId=${toString(folderId)}` : `/deal/${dealId}/files`,
	dealTabs: (dealId?: string, dealTab?: TDealTabName) =>
		dealId
			? // If used to navigate.
				`/deal/${dealId}/${dealTab || 'details'}`
			: // If used as the route path.
				`/deal/:dealId/:dealTab`, // TODO
	newDeal: (communityId?: string) => (communityId ? `/new-deal?communityId=${communityId}` : '/new-deal'),
	editDeal: (dealId?: string, sourceCommunityId?: string) =>
		dealId
			? sourceCommunityId
				? `/edit-deal/${dealId}?sourceCommunityId=${sourceCommunityId}` // used only to determine whether we come form Network deals or Invest deals to satisfy T21C-4554 [@DmitriyNikolenko]
				: `/edit-deal/${dealId}`
			: `/edit-deal/:dealId`,
	thirdPartyDeal: (dealId?: string) => (dealId ? `/third-party-deal/${dealId}` : `/third-party-deal/:dealId`),
	// Account pages
	profile: () => '/profile',
	profileEvents: (profileId = ':profileId') => `/profile/${profileId}/events`,
	memberProfile: (profileId = ':profileId') => `/profiles/${profileId}`,
	contact: (profileId = ':profileId') => `/contact/${profileId}`,
	editContact: () => '/edit-contact',
	addressBook: () => '/address-book',
	memberGuideBook: () => '/member-guide-book',
	partnerBenefits: () => '/partner-benefits',
	tigerPulse: () => '/tiger-pulse',
	staffAndSupport: () => '/staff-and-support',
	referAssociate: () => '/refer-an-associate',
	policies: () => '/policies',
	settings: () => '/settings',
	notificationSettings: () => '/notification-settings',
	blockedUsersSettings: () => '/settings/blocked-users',
	notificationSettingsGroup: (groupName?: string) =>
		groupName ? `/notification-settings-group/${groupName}` : '/notification-settings-group/:groupName',
	dealSettings: () => '/deal-settings',
	// Asset allocation
	assetAllocation: (tabName?: TAssetAllocationTabSlug) =>
		tabName ? `/asset-allocation/${tabName}` : '/asset-allocation/:tabName',
	assetAllocationNetwork: (networkId?: string) =>
		networkId
			? `/asset-allocation/networks` + (networkId ? `/${networkId}` : '')
			: '/asset-allocation/:tabName/:networkId?',
	// Network connections
	createNetworkConnection: (communityId = ':communityId') => `/network/${communityId}/create-network-connection`,
	editNetworkConnection: (networkConnectionId = ':networkConnectionId') =>
		`/network-connections/${networkConnectionId}/edit`,
	networkConnectionFolder: (networkConnectionId?: TNetworkConnection['id'], folderId?: TFolder['id'] | null) =>
		// Only for navigate to
		folderId
			? `/network-connections/${networkConnectionId}/files?folderId=${toString(folderId)}`
			: `/network-connections/${networkConnectionId}/files`,
	networkConnection: (networkConnectionId?: string, networkConnectionTab?: TNetworkConnectionTabName) =>
		networkConnectionId
			? `/network-connections/${networkConnectionId}/${networkConnectionTab || 'details'}`
			: '/network-connections/:id/:tab?',
	/** Network connections filters page */
	networkConnectionFilters: (networkConnectionId?: string) =>
		networkConnectionId ? `/network-connection-filters/${networkConnectionId}` : `/network-connection-filters/:id`,

	// Edit Profile
	editProfileInformation: () => '/edit-profile/information',
	editProfileActivities: () => '/edit-profile/activities',
	editProfileEvents: () => '/edit-profile/events',
	editProfileBiography: () => '/edit-profile/biography',
	editProfileDetails: () => '/edit-profile/details',
	editProfileInvestmentExpertise: () => '/edit-profile/investments',
	editProfileExpertise: () => '/edit-profile/expertise',
	editProfileInterests: () => '/edit-profile/interests',
	editDietaryRestrictions: () => '/edit-profile/dietary-restrictions',
	editProfileTags: (section = ':section') => `/edit-profile/${section}/tags`,

	// Posts related.
	addPost: () => `/posts/add`,
	post: (activityId = ':activityId', streamFeedId?: string) =>
		`/posts/${activityId}` + (streamFeedId ? `?streamFeedId=${encodeURI(streamFeedId)}` : ''),
	editPost: (activityId = ':activityId') => `/posts/${activityId}/edit`,

	reportContent: (type?: TReportContentType, id?: TUid, feedSlug?: string) =>
		type // If used to navigate.
			? `/report-content?type=${type}&id=${id}&feedSlug=${feedSlug}`
			: // If used as the route path.
				'/report-content',
	message: (channelId = ':channelId') => `/messages/${channelId}`,
	searchResults: (searchQuery?: string) =>
		searchQuery ? `/search-result?searchQuery=${encodeURI(searchQuery)}` : '/search-result',

	// Cross promotions.
	newPost: () => '/new-post',
	postSettings: () => '/post-settings',

	supportFaq: () => '/support-faq',
	supportAppTutorials: () => '/support-app-tutorials',
	supportRequestHelp: () => '/support-request-help',
};
