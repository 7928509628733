import { useService, useQuery, useMe } from '@hooks';

/** Retrieves a User resource. Included profile & myContacts.
 *  Returns data from the cache if it is current user.
 */
export const useGetUserQuery = (userId: string | undefined) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	const { user } = useMe();
	const isMe = !userId || user?.id === userId;
	const fullName = `${user.firstName} ${user.lastName}`;

	// It's controlled that either of hooks passed in a condition.
	if (isMe) {
		return {
			isMe,
			fullName,
			me: user,
			// eslint-disable-next-line react-hooks/rules-of-hooks
			...useQuery(reactQuery.queryKeys.getMe(), async () => await api.user.getMe(), {
				staleTime: Infinity,
			}),
		};
	} else {
		return {
			isMe,
			fullName,
			me: user,
			// eslint-disable-next-line react-hooks/rules-of-hooks
			...useQuery(['user.getUser', userId], async () => await api.user.getUser(userId)),
		};
	}
};
