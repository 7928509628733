import { FiltersDropdown } from '@ui-kit/components';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from '@hooks';
import styles from './TipFiltersDropdown.module.css';
import clsx from 'clsx';
import { isEqual, noop } from '@utils';
import type { TTipTag } from '@typings';
import { PlusIcon, CrossIcon } from '@ui-kit/icons';

const TipFiltersDropdown = ({ tipCategories, initialSelectedCategories, onApply }: ITipFiltersDropdownProps) => {
	const { t } = useTranslation();
	const [selectedTags, setSelectedTags] = useState<TTipTag[]>(initialSelectedCategories);
	const selectedAllTags = () => setSelectedTags(tipCategories);
	const unselectAllTags = () => setSelectedTags([]);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const selectable = !!selectedTags;
	const noCategoryChanges = useMemo(
		() =>
			isEqual(
				initialSelectedCategories.map((category) => category.title).sort(),
				selectedTags.map((category) => category.title).sort(),
			),
		[initialSelectedCategories, selectedTags],
	);

	const handleApply = () => {
		onApply(selectedTags);
	};

	const resetSelectedTags = () => {
		setSelectedTags(initialSelectedCategories);
	};

	return (
		<FiltersDropdown
			appliedFilters={initialSelectedCategories.map((tag) => tag.title)}
			applyDisabled={noCategoryChanges}
			applyLabel={t('Apply')}
			cancelLabel={t('Cancel')}
			filteredByLabel={t('Category Filters')}
			isDropdownOpen={isDropdownOpen}
			setIsDropdownOpen={setIsDropdownOpen}
			title={t('Filter by Category')}
			onApply={handleApply}
			onCancel={resetSelectedTags}
		>
			<div className={styles.tipFiltersDropdown}>
				<span className={styles.tipFiltersDropdown__actionLabel}>{t('Select')}</span>
				<span className={clsx(styles.tipFiltersDropdown__actionLink)} onClick={selectedAllTags} onKeyDown={noop}>
					{t('All')}
				</span>
				<span className={styles.tipFiltersDropdown__actionLabel}>|</span>
				<span className={clsx(styles.tipFiltersDropdown__actionLink)} onClick={unselectAllTags} onKeyDown={noop}>
					{t('None')}
				</span>
			</div>
			<div className={styles.tipFiltersDropdown__tags}>
				{tipCategories.map((tag) => {
					const selected = selectable ? selectedTags.find((selectedTag) => selectedTag.id === tag.id) : false;

					return (
						<span
							className={clsx(styles.tipFiltersDropdown__tag, selected && styles.tipFiltersDropdown__tagSelected)}
							key={tag.id + tag.title}
							role="button"
							tabIndex={0}
							onClick={() => {
								setSelectedTags((selectedTags) =>
									selected
										? selectedTags.filter((selectedTag) => selectedTag.id !== tag.id)
										: [...selectedTags, tag].sort(),
								);
							}}
							onKeyDown={noop}
						>
							{selectable && (
								<span
									className={
										selected ? styles.tipFiltersDropdown__iconWrapperPlus : styles.tipFiltersDropdown__iconWrapper
									}
								>
									{selected ? (
										<CrossIcon fill={'#ffff'} height={10} width={10} />
									) : (
										<PlusIcon fill={'#00000'} height={10} width={10} />
									)}
								</span>
							)}
							<span>{tag.title}</span>
						</span>
					);
				})}
			</div>
		</FiltersDropdown>
	);
};

export interface ITipFiltersDropdownProps {
	tipCategories: TTipTag[];
	initialSelectedCategories: TTipTag[];
	onApply: (selectedCategories: TTipTag[]) => void;
}

export default TipFiltersDropdown;
