import { useService, useQuery } from '@hooks';
import { compact, dayjs } from '@utils';
import { useFetchMyGroupAndChapterCommunityIds } from '@ui-modules/account/hooks/useMemberMembershipQuery';
import type { TTipContext, TTipWithDetails } from '@typings';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useTipQuery = <T = TTipWithDetails>(
	tipId: string | undefined | null,
	tipContext?: TTipContext,
	options?: UseQueryOptions<TTipWithDetails, Error, T>,
) => {
	const reactQuery = useService('ReactQueryService');
	const api = useService('ApiService');
	const fetchMyGroupAndChapterCommunityIds = useFetchMyGroupAndChapterCommunityIds();

	return useQuery<TTipWithDetails, Error, T>(
		reactQuery.queryKeys.tip(tipId as string, tipContext),
		async () => {
			const tip = await api.tips.getTip(tipId as string);
			const getTipDetails = await api.tips.getTipsDetails({
				page: 1,
				tips: [tip.id],
				communities: compact([
					tipContext?.contextCommunityId,
					...(tipContext?.contextMyCommunities ? await fetchMyGroupAndChapterCommunityIds() : []),
				]),
				calendarItems: tipContext?.contextCalendarItemId ? [tipContext.contextCalendarItemId] : undefined,
			});
			const tipWithDetails: TTipWithDetails = { ...tip, details: getTipDetails[0] };
			return tipWithDetails;
		},
		{
			enabled: !!tipId,
			staleTime: dayjs.duration(15, 'minute').asMilliseconds(),
			cacheTime: dayjs.duration(15, 'minute').asMilliseconds(),
			...options,
		},
	);
};
