import { useNavigate } from '@hooks';
import { useGetTipMeatballMenuOptions } from '@ui-modules/tipJar/hooks/useGetTipMeatballMenuOptions';
import { useTipQuery } from '@ui-modules/tipJar/hooks/useTipQuery';
import { useTranslation } from 'react-i18next';
import styles from './TipHeader.module.css';
import TipPageHeader from '@ui-modules/tipJar/components/TipPageHeader';
import type { TTip } from '@typings';

const TipHeader = ({ tipId, isTipEditable }: ITipHeaderProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { data: tip } = useTipQuery(tipId); // it's important to proper permission handling to fetch a tip without context
	const [meatballMenuOptions, ConfirmationModals] = useGetTipMeatballMenuOptions({
		onDeleted: () => {
			return navigate(-1);
		},
	});

	if (!tip) return null;
	return (
		<div className={styles.tipReviewList__container}>
			<TipPageHeader
				meatballMenuOptions={meatballMenuOptions(tip)}
				theme={'light'}
				title={isTipEditable ? t('Review AI Match') : t('Tip')}
			/>
			{ConfirmationModals}
		</div>
	);
};

interface ITipHeaderProps {
	tipId: TTip['id'];
	isTipEditable?: boolean;
}

export default TipHeader;
