import { StreamChat } from 'stream-chat';
import { EventEmitter } from '../abstracts/EventEmitter';
import type { Channel, ChannelMemberResponse, ChannelFilters, UserResponse, DefaultGenerics } from 'stream-chat';
import type { IStreamChatConfig, IEnvironmentConfig } from '../interfaces/AppConfig.interface';
import type { IChat } from '../interfaces/ChatService.interface';
import type { TBlockedUserRelation, TPaginatedList, TRole, TUser } from '@typings';
import type { ApiService } from './ApiService';
import type { IDevLogger } from '../interfaces/DevLogger.interface';
import type { IBugTracker } from '../interfaces/BugTracker.interface';
import type { TChatUser, TChatType, TChannelCommunity, TChannelDeal } from '@typings';
import type { StreamMessage } from 'stream-chat-react';
import { translateUserRole } from '@utils';
import type { TFunction } from 'i18next';
import type { ReactQueryService } from './ReactQueryService';

export class StreamChatService extends EventEmitter<{ health: boolean }> implements IChat {
	static inject = ['AppConfigService', 'ApiService', 'logger', 'SentryService', 'ReactQueryService'] as const;

	constructor(
		appConfig: IStreamChatConfig & IEnvironmentConfig,
		api: ApiService,
		logger: IDevLogger,
		private readonly sentry: IBugTracker,
		private readonly reactQuery: ReactQueryService,
	) {
		super();

		this.logger = logger.child('StreamChatService');
		this.chatClient = StreamChat.getInstance(appConfig.STREAM_API_KEY, {
			timeout: 30000, // Changed default timeout limit to 30000 ms. (Default 3000ms). Reason: T21C-3615, T21C-3683
		});
		this.requestToken = async () => {
			return await this.reactQuery.queryClient.fetchQuery(
				['streamChat.getToken'],
				async () => await api.streamChat.getToken(),
			);
		};
	}
	private logger: IDevLogger;
	readonly chatClient: StreamChat;

	pausedUsers: UserResponse[] = [];
	requestToken: () => Promise<string>;

	async connect(userId: string, name: string) {
		try {
			await this.chatClient.connectUser({ id: userId, name: name }, () => this.requestToken());
			// await this.chatClient.unbanUser(userId);
			this.logger.debug('connected', userId, name);
		} catch (error) {
			this.logger.error(error);
			this.sentry.captureException(error as Error);
			throw error;
		}
	}

	async getMembersChannel(channel: Channel) {
		const members = Object.values(channel?.state?.members || {});
		const usersId = members.map((member: ChannelMemberResponse) => {
			return {
				id: member?.user?.id,
				role: member?.role,
				banned: member.user?.banned,
			};
		});
		return usersId;
	}

	async queryChannelUser(channel: Channel | null, userId: string) {
		if (!channel) return;
		const members = Object.values(channel?.state?.members || {});
		return members.filter((item) => item.user?.id === userId)[0];
	}
	// Get channel name for message screen
	async getChannelNameForMessageScreen(channel: Channel, userId: string, t: TFunction) {
		if (channel.state.members && Object.keys(channel.state.members).length === 1) {
			if (this.isOneToOne(channel)) {
				const otherMemberId = this.extractOtherOneToOneMemberId(channel, userId);
				return otherMemberId ? (await this.queryUser(otherMemberId))?.name : '';
			} else {
				return channel.data?.name;
			}
		} else if (channel.state.members && Object.keys(channel.state.members).length > 1) {
			return this.getChannelName(channel, userId, t);
		} else {
			return this.channelNameForMessageScreen(channel, userId);
		}
	}
	async getMemberAvatar(channel: Channel): Promise<string[]> {
		const members = Object.values(channel?.state?.members);
		const avatars = [];
		for (let i = 0; i < members.length; i++) {
			if (avatars.length > 4) return avatars;
			if (members[i]?.user?.avatar && members[i]?.user?.avatar !== '') {
				avatars.push(members[i]?.user?.avatar as string);
			}
		}
		return avatars;
	}
	async disconnect() {
		try {
			await this.chatClient.disconnectUser();
			this.logger.debug('disconnected');
		} catch (error) {
			this.logger.error(error);
			this.sentry.captureException(error as Error);
			throw error;
		}
	}

	async openChannel(channelId: string, channelName: string, members: string[]) {
		const channel = await this.chatClient.channel('messaging', channelId, {
			members: members,
			name: channelName,
		});
		await channel.watch();
		return channel;
	}

	checkIfOpponentInOneToOneChatIsBlocked(
		chatMembers: Record<string, ChannelMemberResponse>,
		blockedUserRelations: TBlockedUserRelation[],
		userSlug: string,
	) {
		const members = Object.values(chatMembers);
		if (members?.length === 2) {
			const myOpponent = members.find((member) => member.user_id !== userSlug);
			const isOpponentIsBlocked = blockedUserRelations.find(
				(member) => member.blockedUser.id === myOpponent?.user?.userId,
			);
			return !!isOpponentIsBlocked;
		}
		return false;
	}

	checkIfMeInOneToOneChatIsBlocked(
		chatMembers: Record<string, ChannelMemberResponse>,
		usersBlockingMeRelations: TBlockedUserRelation[],
		userSlug: string,
	) {
		const members = Object.values(chatMembers);
		if (members?.length === 2) {
			const myOpponent = members.find((member) => member.user_id !== userSlug);
			const meIsBlocked = usersBlockingMeRelations.find((member) => member.owner.id === myOpponent?.user?.userId);
			return !!meIsBlocked;
		}
		return false;
	}

	async openOneOnOneChannel(members: string[]) {
		const channel = this.chatClient.channel('messaging', {
			members: members,
		});
		await channel.watch();
		return channel;
	}
	async setNotificationChannel(channel: Channel, mutedStatus: boolean) {
		mutedStatus ? await channel.unmute() : await channel.mute();
	}

	async banMember(userID: string) {
		await this.chatClient.banUser(userID);
	}

	async leaveChannel(userId: string, channel: Channel | null | undefined, isGroup: boolean, name: string) {
		if (!channel) return;
		try {
			if (isGroup) {
				await channel.removeMembers(
					[userId],
					// TODO T21C-4999 hide this message until James decide how to ONLY send them in specific circumstances [@DmitriyNikolenko].
					// { text: `${name} has left the chat` }
				);
			} else {
				await channel.hide(userId, false);
			}
			await channel.stopWatching();
		} catch (error) {
			console.error(`Error leaving channel: ${error}`);
		}
	}
	/*
	  When removed from group we send message user has left the chat get stream send that message also to the user
	  is removed the group and because of that even after you removed the group that channel comes in top as it things
	  that channel got new message. That's why we may need to check also if current user is member of channel or not.
	  (@Haresh)
	*/
	isGroupMember(userId: string, members: Record<string, ChannelMemberResponse>) {
		return Object.values(members).some((member) => member?.user?.id === userId);
	}

	async hasChannelMembership(channel: Channel, userSlug: TUser['slug']) {
		const isMeMember = channel?.state.membership.user?.id === userSlug;
		const hasChannelMembership = !!isMeMember;

		return hasChannelMembership;
	}

	async addMembers(channel: Channel | null, members: string[], message: string, staffAndChairIds: string[]) {
		if (!channel) return;
		if (!this.isOneToOne(channel) && message !== '') {
			if (staffAndChairIds.length > 0) {
				await channel.addMembers(staffAndChairIds);
			}
			const nonStaffMembers = members.filter((item) => !staffAndChairIds.includes(item));
			if (nonStaffMembers.length > 0) {
				await channel.addMembers(nonStaffMembers, { text: message }, { hide_history: true });
			}
		} else {
			await channel.addMembers(members);
		}
	}
	async removeMember(channel: Channel, members: string[], message: string) {
		if (!this.isOneToOne(channel) && message !== '') {
			await channel.removeMembers(members, { text: message });
		} else {
			await channel.removeMembers(members);
		}
	}
	async unpauseUser(userId: TUser['id']) {
		this.logger.debug('unpauseUser', userId);
		await this.chatClient.unbanUser(userId);
	}
	async queryChannelFlaggedMessages(cid: string, userId: string) {
		try {
			const flagObject = await this.chatClient.queryMessageFlags({ channel_cid: cid });
			const messageIds: Record<string, boolean> = {};
			const flags = flagObject?.flags || [];
			for (const flag of flags) {
				if (flag.user.id === userId) {
					messageIds[flag.message.id] = true;
				}
			}
			return Object.keys(messageIds).length > 0 ? messageIds : null;
		} catch (error) {
			console.error('Error querying flagged messages:', error);
			return null;
		}
	}

	async checkIsLastMessageIsFlagged(cid: string, userId: string, messageId: string) {
		try {
			const flagObject = await this.chatClient.queryMessageFlags({ channel_cid: cid });
			const flags = flagObject?.flags || [];

			for (const flag of flags) {
				if (flag.message.id === messageId && flag.user.id === userId) {
					return true;
				}
			}
			return false;
		} catch (error) {
			console.error('Error querying flagged messages:', error);
			return false;
		}
	}
	async pauseUser(userId: TUser['id']) {
		this.logger.debug('pauseUser', userId);
		await this.chatClient.banUser(userId);
	}

	async sendChannelUpdateEvent(channel: Channel) {
		await channel.sendEvent({
			type: 'channel.updated',
		});
	}
	async refreshPausedUsers() {
		const bannedUsersResponse = await this.chatClient.queryUsers({ banned: true });
		this.pausedUsers = bannedUsersResponse.users;
		return bannedUsersResponse.users;
	}

	async hasPausedUser(members: ChannelMemberResponse[]) {
		const value = await this.channelPausedUser(members);
		return value;
	}
	async channelPausedUser(members: ChannelMemberResponse[]) {
		const users = await this.refreshPausedUsers();
		return this.getChannelPausedUser(users, members);
	}

	getChannelPausedUser(users: UserResponse[], members: any[]) {
		const pauseUserIds = users.map((item) => item.id);
		const channalPauseUser = members.filter((item) => pauseUserIds.includes(item.id + ''));
		return channalPauseUser.length > 0;
	}
	isOneToOne(channel: Channel) {
		return channel?.data?.name === '';
	}

	isGroupChat(channel: Channel | undefined | null) {
		return channel?.data?.name !== '';
	}

	async queryPauseMembers(channel: Channel) {
		const members = Object.values(channel?.state?.members || {});
		const usersId = members
			.filter((member: ChannelMemberResponse) => member.user?.banned)
			.map((member: ChannelMemberResponse) => {
				return {
					id: member?.user?.id,
					role: member?.role,
					banned: member.user?.banned,
				};
			});
		return usersId;
	}
	isInactiveUser(members: Record<string, ChannelMemberResponse>, myUserId: string) {
		const channelMemberData = Object.values(members).filter(
			(member: ChannelMemberResponse) => member?.user?.id !== myUserId,
		);
		const userRoles = channelMemberData[0]?.user?.roles as TRole[];
		return userRoles ? userRoles.includes('ROLE_INACTIVE') : false;
	}
	getChannelName(channel: Channel, userId: string, t: TFunction) {
		return this.isOneToOne(channel)
			? this.getOneToOneChatUserName(channel.state.members, userId, t)
			: channel.data?.name;
	}

	async userStatus(userId: TUser['id']) {
		const data = await this.chatClient.queryUsers({ id: userId, banned: true });
		return data.users[0].banned;
	}
	// When user get paused or unpaused at that to get the latest status of user this method will be used
	async queryUser(userId: TUser['id']) {
		const data = await this.chatClient.queryUsers({ id: userId });
		return data?.users[0];
	}

	getOneToOneChatUserName(members: Record<string, ChannelMemberResponse>, meUserId: string, t: TFunction): string {
		const channelMemberData = Object.values(members).filter(
			(member: ChannelMemberResponse) => member?.user?.id !== meUserId,
		);
		const userRoles = channelMemberData[0].user?.roles as TUser['roles'];
		if (userRoles.includes('ROLE_INACTIVE')) {
			return translateUserRole(userRoles, t);
		}
		return String(channelMemberData[0].user?.name);
	}

	async getUser(userId: TUser['id']) {
		const data = await this.chatClient.queryUsers({ id: userId });
		return data.users[0];
	}

	// Get Avatar for OneToOne chat (Currently all avatar are null in getstream)
	getOneToOneChatUserAvatar(members: Record<string, ChannelMemberResponse>, meUserId: string): string | null {
		const channelMemberData = Object.values(members).filter(
			(member: ChannelMemberResponse) => member?.user?.id !== meUserId,
		);
		return channelMemberData[0].user?.avatar ? String(channelMemberData[0].user?.avatar) : null;
	}
	// Used to get channel name at the time of channel creation only in message screen
	async channelNameForMessageScreen(channel: Channel, userId: string) {
		const membersId: string[] = channel.data?.members as string[];
		const channelName = await this.getOneToOneChatUserNameForMessageScreen(membersId, userId);
		return (this.isOneToOne(channel) ? channelName : channel.data?.name) as string | Promise<string>;
	}

	async getOneToOneChatUserNameForMessageScreen(members: string[], meUserId: string): Promise<string> {
		const channelMemberData = members.filter((memberId: string) => memberId !== meUserId);
		const user = await this.chatClient.queryUsers({ id: channelMemberData[0] });
		return user.users[0].name as string;
	}

	/* In channel list add gloden frame if profile type is chair
       Currently roles in user object are not added from backend in get stream once it will be addeded
       this will work fine */
	isChair(members: Record<string, ChannelMemberResponse>, meUserId: string): boolean | undefined {
		const channelMemberData = Object.values(members).filter(
			(member: ChannelMemberResponse) => member?.user?.id !== meUserId,
		);
		const user = channelMemberData[0].user as TChatUser;
		return user && user.roles && user.roles.includes('ROLE_CHAIR');
	}

	isOneToOneWithOneMember(channel: Channel) {
		return this.isOneToOne(channel) && Object.keys(channel.state.members).length === 1;
	}

	isDealChat(channel: Channel) {
		return channel.id?.startsWith('DL--');
	}

	extractOtherOneToOneMemberId(channel: Channel, userId: string): string {
		if (channel && channel.data && channel && channel.data && 'memberIDs' in channel.data) {
			const memberIds = channel.data.memberIDs as string[];
			const otherMemberId = memberIds.find((id) => id !== userId);
			return otherMemberId || '';
		}
		return '';
	}

	// Used to create new one to one chat or open already existing chat with user
	async getOrMarkOneToOneChatWithUser(contactUserId: string, userId: string, addLoggedInUser?: boolean) {
		const usersId: string[] = [`${contactUserId}`, userId]; // arr of both users id
		const channels = await this.chatClient.queryChannels(
			{
				type: 'messaging',
				members: { $eq: [`${userId}`, `${contactUserId}`] }, //$eq : use when we need only those specific memeber in group
				$and: [
					{
						member_count: { $eq: 2 },
					},
				],
				$or: [{ hidden: true }, { hidden: false }],
			},
			{},
		);
		const oneToOneChat = channels?.find((channel) => this.isOneToOne(channel));
		if (channels.length === 0 || !oneToOneChat) {
			const channels = await this.chatClient.queryChannels(
				{
					type: 'messaging',
					memberIDs: { $eq: [`${userId}`, `${contactUserId}`] },
					$and: [
						{
							member_count: { $eq: 1 },
						},
					],
					$or: [{ hidden: true }, { hidden: false }],
				},
				{},
			);
			if (channels.length === 0) {
				return this.openChannel(Date.now().toString(), '', usersId);
			}
			addLoggedInUser ? await channels[0].addMembers([userId]) : await channels[0].addMembers([contactUserId]);

			return channels[0];
		} else {
			return oneToOneChat || channels[0];
		}
	}

	// Save last reported messageId to check this id with lastMessageId on chat list.
	async setLastReportedMessageId(channel: Channel, messageId: string, userId: string) {
		await channel.updatePartial({
			set: { lastReportedMessageId: { ...(channel?.data?.lastReportedMessageId || {}), [userId]: messageId } },
		});
	}

	async updateNameChannel(channel: Channel, groupName: string) {
		await channel.updatePartial({ set: { name: groupName } });
	}
	// We will send this event when admin unpause the user
	async sendUnpauseEvent(userId: TUser['id']) {
		await this.chatClient.sendUserCustomEvent(userId, {
			type: 'user.unbanned',
		});
	}

	async setChannelMutedEvent(userId: string, channel: Channel) {
		await this.chatClient.sendUserCustomEvent(userId, {
			type: 'channel.muteUnmute',
			channelId: channel.id,
		});
	}
	//This will return specific channel and will be useful in case of notification where we will only have channelId.
	async getChannel(channelId: string) {
		const filters: ChannelFilters = {
			type: 'messaging',
			id: { $eq: channelId }, //$eq : use when we need only those specific member in group
			$or: [{ hidden: true }, { hidden: false }],
		};
		try {
			const channels = await this.chatClient.queryChannels(
				filters,
				{},
				{
					watch: false, // by default, it is true
					state: true,
				},
			);
			return channels[0];
		} catch (error) {
			return null;
		}
	}

	getChannelListFilters(filterQuery: string, userId: string): ChannelFilters {
		return filterQuery === ''
			? { type: 'messaging', members: { $in: [`${userId}`] } }
			: {
					type: 'messaging',
					members: { $in: [`${userId}`] },
					$or: [
						{
							'member.user.name': { $autocomplete: filterQuery },
						},
						{
							name: { $autocomplete: filterQuery },
						},
					],
				};
	}
	async getFirstChannel(userId: string) {
		const channels = await this.chatClient.queryChannels(
			{ type: 'messaging', members: { $in: [`${userId}`] } },
			{},
			{
				watch: true,
				state: true,
			},
		);
		return channels ? channels[0] : null;
	}
	async isUserPaused(userId: TUser['id']) {
		return await this.queryUser(userId).then((user) => {
			return user.banned;
		});
	}

	getUnreadCount = async (userId: string, name?: string) => {
		try {
			return await this.chatClient.connectUser({ id: userId, name }, this.requestToken);
		} catch (error) {
			return null;
		}
	};

	async getMessage(chatMessageId: StreamMessage['id']): Promise<StreamMessage> {
		const { message } = await this.chatClient.getMessage(chatMessageId);
		return message as StreamMessage;
	}

	filterChannelUser(members: ChannelMemberResponse<DefaultGenerics>[], userId: string) {
		if (!members) return;
		return members.filter((item) => item.user?.id === userId)[0];
	}

	async getChannelAdmin(channel: Channel, slug: string) {
		const members = Object.values(channel?.state?.members || {});
		const member = this.filterChannelUser(members, slug);
		// Check if channel have a moderator then he ia an channel Admin.
		if (!member) return false;
		if (member.role === 'moderator') return true;
		const isOtherUserModerator = members.find((memberUser) => memberUser.role === 'moderator');
		if (isOtherUserModerator) return false;
		// If no moderator in the channel then owner or admin is a channel Admin.
		return member.role === 'admin' || member.role === 'owner';
	}

	async getChannelOtherMemberIds(channel: Channel, userSlug: TUser['slug']): Promise<TUser['slug'][]> {
		const channelMembers = Object.values(channel?.state?.members || {}).filter((member) => member.user_id !== userSlug);
		const otherChannelMemberSlugs = channelMembers.map(
			(channelMember) => channelMember.user?.userId,
		) as TUser['slug'][];

		return otherChannelMemberSlugs;
	}

	async deleteChannel(channelId: string): Promise<void> {
		await this.chatClient.deleteChannels([channelId], { hard_delete: false });
	}

	getToken(id: string) {
		return this.chatClient.devToken(id);
	}

	async someUserPaused(userSlugs: TUser['slug'][]): Promise<boolean> {
		const { users } = await this.chatClient.queryUsers({
			id: { $in: userSlugs },
			banned: true,
		});

		return !!users.length;
	}

	canLeaveChat(channel: Channel): boolean {
		return (
			this.isOneToOne(channel) ||
			channel?.data?.backend !== true ||
			(channel?.data?.backend === true && channel?.id?.startsWith('C--') !== true)
		);
	}

	getChatType(channel: Channel): TChatType | undefined {
		const { definition } = (channel?.data?.community ?? {}) as TChannelCommunity;
		const isNetworkChannel = definition === 'network';
		const isChapterChannel = definition === 'chapter';
		const isGroupChannel = definition === 'group';
		const { id: dealId } = (channel?.data?.deal ?? {}) as TChannelDeal;
		const isDealChannel = !!dealId;
		const isManyToManyChat = channel?.data?.name !== '';
		const isOneToOneChat = channel?.data?.name === '';

		if (isNetworkChannel) return 'network';
		else if (isChapterChannel) return 'chapter';
		else if (isGroupChannel) return 'group';
		else if (isDealChannel) return 'deal';
		else if (isManyToManyChat) return 'many-to-many';
		else if (isOneToOneChat) return 'one-to-one';
		else return undefined;
	}

	getOneToOneChatAnotherUserId(channel: Channel, meUserId: TUser['id']): TUser['id'] | undefined {
		if (!this.isOneToOne(channel)) return undefined;

		const { members } = channel.state;
		const userIds = Object.values(members)
			.map((member) => member.user!.userId)
			.filter((id) => id !== meUserId);
		const anotherUserId = userIds?.[0] as TUser['id'] | undefined;

		return anotherUserId;
	}

	public async getPaginatedChats(pageParam = 0, maxPageSize: number, userId: string): Promise<TPaginatedList<Channel>> {
		try {
			const channelsResponse = await this.chatClient.queryChannels(
				{ members: { $in: [userId] }, type: 'messaging' },
				{},
				{
					limit: maxPageSize,
					offset: pageParam * maxPageSize,
				},
			);

			if (!channelsResponse.length) {
				return {
					data: [],
					page: 0,
					meta: {
						hasNext: false,
						hasPrevious: false,
					},
				};
			}

			return {
				data: channelsResponse,
				page: pageParam,
				meta: {
					hasNext: channelsResponse.length === maxPageSize,
					hasPrevious: channelsResponse?.length > maxPageSize,
				},
			};
		} catch (error) {
			this.logger.error(error);
			this.sentry.captureException(error as Error);
			throw error;
		}
	}
}
