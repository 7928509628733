import { useTranslation } from '@hooks';
import styles from './MeetingTipsList.module.css';
import { ActivityIndicator, Button, EditIcon } from '@ui-kit';
import React, { useState } from 'react';
import type { TProfile, TTip } from '@typings';
import TipCard from '@ui-modules/tipJar/components/TipCard';
import { ROUTES } from '@constants';
import { useNavigate } from 'react-router-dom';
import { MEETING_TIPS_COUNT } from '@constants';
import clsx from 'clsx';
import { TipEntity } from '@ui-modules/tipJar/utils/TipEntity';
import EventNewTip from '@ui-modules/events/components/EventNewTip';

const MeetingTipsList = ({ meetingId, tips, isLoading, isFetched, eventId }: IMeetingTipsListProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [tipsToShow, setTipsToShow] = useState<number>(MEETING_TIPS_COUNT.DEFAULT_TIPS_COUNT);

	const openProfile = (profileId: TProfile['id']) => navigate(ROUTES.memberProfile(profileId));

	const visibleTips = tips?.slice(0, tipsToShow) || [];

	const handleToggleShowAll = () => {
		setTipsToShow(
			tipsToShow !== MEETING_TIPS_COUNT.DEFAULT_TIPS_COUNT ? MEETING_TIPS_COUNT.DEFAULT_TIPS_COUNT : tips.length,
		);
	};

	return (
		<>
			{isLoading && <ActivityIndicator size="medium" type="fit" />}
			{!isLoading && isFetched && visibleTips.length > 0 && (
				<>
					<div className={styles.meetingTipsList__header}>
						<h3 className={styles.meetingTipsList__headerTitle}>{t('Tips')}</h3>
						<EventNewTip eventId={eventId} isOutline />
					</div>
					<div className={clsx(styles.meetingTipsList__content, tipsToShow && styles.meetingTipsList__contentExpanded)}>
						{visibleTips.map((tip) => (
							<TipCard
								className={styles.meetingTipsList__card}
								key={tip.id}
								tip={tip}
								onClick={() => navigate(ROUTES.tipPage(tip.id, { contextCalendarItemId: meetingId }))}
								onProfileClick={
									TipEntity.isTipWithDetails(tip) && tip.details.review
										? () => tip.details.review && openProfile(tip.details.review.owner.profileID)
										: undefined
								}
							/>
						))}
					</div>
					{tips.length > MEETING_TIPS_COUNT.DEFAULT_TIPS_COUNT ? (
						<button className={styles.meetingTipsList__button} onClick={handleToggleShowAll}>
							{tipsToShow !== MEETING_TIPS_COUNT.DEFAULT_TIPS_COUNT ? t('Show Less') : t('Show More')}
						</button>
					) : null}
				</>
			)}
		</>
	);
};

export interface IMeetingTipsListProps {
	meetingId: string | undefined;
	tips: TTip[];
	isLoading: boolean;
	isFetched: boolean;
	eventId: string;
}

export default MeetingTipsList;
