import { CameraPhotoInput, CheckboxInput, Formik, Gap, TextArea, When } from '@ui-kit';
import { useTranslation, useRef, useMutation } from '@hooks';
import TipFormsSubmitButton from '@ui-modules/tipJar/components/TipFormsSubmitButton';
import styles from './NewTipForm.module.css';
import { TipEntity } from '@ui-modules/tipJar/utils/TipEntity';
import { usePickUpFile } from '@ui-modules/files/hooks/usePickUpFile';
import type { FormikProps } from 'formik';
import type { ReactNode } from 'react';
import { patchTipFormSchema, type TPatchTipForm, type TPatchTipImage } from '@schemas';
import { IMAGES_MIME_TYPES } from '@constants';
import { getFileNameWithoutExtension } from '@utils';

const newTipFormSchema = patchTipFormSchema.omit(['tags']);

const NewTipForm = ({ initialValues, onSubmit, renderWrapper = ({ children }) => children }: INewTipFormProps) => {
	const { t } = useTranslation();

	const formikRef = useRef<FormikProps<TPatchTipForm>>(null);

	const { openFilePicker, getInputProps } = usePickUpFile((file) => {
		const photoUrl = URL.createObjectURL(file);
		const tipImage: TPatchTipImage = {
			documentUrl: photoUrl,
			isAiGenerated: false,
			name: getFileNameWithoutExtension(file.name),
		};
		formikRef.current?.setFieldValue('galleryFiles', [tipImage]);
		formikRef.current?.handleBlur('galleryFiles');
	}, IMAGES_MIME_TYPES);
	const getCurrentGeolocationMutation = useMutation<GeolocationPosition, GeolocationPositionError>({
		mutationFn: async () =>
			await new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject)),
		onSuccess: (data) =>
			formikRef.current?.setFieldValue('location', {
				lat: data.coords.latitude,
				lng: data.coords.longitude,
			}),
		onError: (error) => {
			formikRef.current?.setFieldError('location', t('Failed to get your location'));
			formikRef.current?.handleBlur('location');
		},
	});

	return (
		<Formik<TPatchTipForm>
			initialValues={initialValues as TPatchTipForm}
			innerRef={formikRef}
			isInitialValid={newTipFormSchema.isValidSync(initialValues)}
			validateOnBlur
			validationSchema={newTipFormSchema}
			onSubmit={(values) => onSubmit(values)}
		>
			{(formProps) => {
				const {
					values,
					setFieldValue,
					handleBlur,
					handleChange,
					submitForm,
					setFieldTouched,
					errors,
					isSubmitting,
					isValid,
				} = formProps;

				return renderWrapper({
					formProps,
					children: (
						<div className={styles.newTipForm}>
							<h3>{t('Take a photo...')}</h3>
							<CameraPhotoInput
								errorMessage={errors.galleryFiles}
								height={370}
								t={t}
								value={values.galleryFiles?.find((image) => !image.isAiGenerated)?.documentUrl}
								width="100%"
								onCallImageLibrary={openFilePicker}
								onChange={(photoUrl) => {
									if (photoUrl) {
										setFieldValue('galleryFiles', [
											{
												documentUrl: photoUrl,
												isAiGenerated: false,
												name: 'Image from camera',
											},
										]);
									} else {
										setFieldValue('galleryFiles', null);
									}
									handleBlur('galleryFiles');
								}}
							/>
							<input {...getInputProps()} />
							<hr className={styles.newTipForm__separatorLine} />
							<h3>{t('And/or, write something')}</h3>
							<TextArea
								charactersLimit={TipEntity.descriptionLimit}
								errorMessage={errors.description}
								label={t('What should our A.I. engine search for?')}
								placeholder={t('The Ritz-Carlton, Denver hotel')}
								showCounter
								value={values.description}
								onBlur={handleBlur('description')}
								onChange={handleChange('description')}
							/>
							<When condition={!!navigator.geolocation}>
								<CheckboxInput
									disabled={getCurrentGeolocationMutation.isLoading}
									errorMessage={errors.location}
									label={
										t('Use my current Location') +
										(getCurrentGeolocationMutation.isLoading ? ` ${t('(fetching...)')}` : '')
									}
									value={!!values.location}
									onChange={(locationAttached) => {
										handleBlur('location');
										if (locationAttached) {
											getCurrentGeolocationMutation.mutate();
										} else {
											setFieldValue('location', undefined);
										}
									}}
								/>
							</When>
							<TipFormsSubmitButton disabled={!isValid} loading={isSubmitting} title={t('Next')} onClick={submitForm} />
						</div>
					),
				});
			}}
		</Formik>
	);
};

export interface INewTipFormProps {
	initialValues: Partial<TPatchTipForm>;
	/** Form submit handler. */
	onSubmit: (values: TPatchTipForm) => Promise<unknown>;
	/** Optional renderer to wrap form content and pass formProps to a wrapper React component. */
	renderWrapper?: ({
		formProps,
		children,
	}: {
		formProps: FormikProps<TPatchTipForm>;
		children: ReactNode;
	}) => ReactNode;
}

export default NewTipForm;
