import { useService, useQuery } from '@hooks';
import type { TTipTag } from '@typings';

export const useTipCategoriesQuery = () => {
	const api = useService('ApiService');

	return useQuery<TTipTag[], Error>(
		['tips', 'categories'],
		async () => {
			return await api.tips.getTipTags();
		},
		{
			staleTime: Infinity,
			cacheTime: Infinity,
		},
	);
};
