import { useHandleBackButton } from '@hooks';
import styles from './TipPageHeader.module.css';
import { BackIcon, MeatballMenu, type TMeatballMenuOption } from '@ui-kit';

const TipPageHeader = ({ theme = 'light', meatballMenuOptions, onBackPress, title }: ITipPageHeaderProps) => {
	const { onBackButtonPress } = useHandleBackButton();
	const goBack = () => onBackButtonPress();
	return (
		<div className={styles.header} data-theme={theme}>
			<div className={styles.header__body}>
				<button className={styles.header__backButton} onClick={onBackPress ?? goBack}>
					<BackIcon className={styles.header__backButtonIcon} height={16} width={16} />
				</button>
			</div>

			{!!title && <div className={styles.header__title}>{title}</div>}

			{meatballMenuOptions.length ? (
				<MeatballMenu
					meatBallColor={theme === 'dark' ? '#fff' : '#000'}
					options={meatballMenuOptions}
					position="left"
				/>
			) : null}
		</div>
	);
};

export interface ITipPageHeaderProps {
	theme: 'light' | 'dark';
	meatballMenuOptions: TMeatballMenuOption[];
	onBackPress?: () => void;
	title?: string;
}

export default TipPageHeader;
