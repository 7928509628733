import PatchTipForm from '@ui-modules/tipJar/components/PatchTipForm';
import TipImageUploadInput from '@ui-modules/tipJar/components/TipImageUploadInput';
import { compact, noop } from '@utils';
import type { TCreateTipForm } from '@tiger21-llc/connect-shared/src/schemas/CreateTipForm.schema';
import type { IPatchTipFormMainProps } from '@ui-modules/tipJar/components/PatchTipForm';
import type { TPatchTipForm } from '@schemas';

const ImageEditTip = ({ tipId, initialValue = [], onTipPatched }: IImageEditTip) => {
	return (
		<PatchTipForm<'galleryFiles'>
			initialValues={{ galleryFiles: initialValue } as TPatchTipForm}
			propertyName="galleryFiles"
			tipId={tipId}
			onCancel={noop}
			onTipPatched={onTipPatched}
		>
			{(formikProps) => {
				return (
					<TipImageUploadInput
						disabled={!formikProps.isValid || formikProps.isSubmitting}
						uploading={formikProps.isSubmitting}
						onUploadFile={(file) => {
							formikProps.setFieldValue(
								'galleryFiles',
								compact([
									(formikProps.values.galleryFiles ?? []).find((image) => image.isAiGenerated),
									{
										isAiGenerated: false,
										documentUrl: URL.createObjectURL(file),
										name: file.name,
									},
								]),
							);
							formikProps.setFieldTouched('galleryFiles', true);
							setTimeout(() => {
								formikProps.submitForm();
							});
						}}
					/>
				);
			}}
		</PatchTipForm>
	);
};

interface IImageEditTip extends IPatchTipFormMainProps {
	initialValue: TCreateTipForm['galleryFiles'];
}

export default ImageEditTip;
