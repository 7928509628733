import { useService, usePaginatedInfiniteQuery, useMe } from '@hooks';
import type { Channel as TChannel } from 'stream-chat';
import type { TPaginatedList } from '@typings';

/**
 * Custom hook to fetch paginated chats using usePaginatedInfiniteQuery
 */
export const useGetPaginatedChatsQuery = (maxPageSize: number) => {
	const chatService = useService('ChatService');
	const { user } = useMe();

	return usePaginatedInfiniteQuery<TChannel, TPaginatedList<TChannel>, Error>(
		['getPaginatedChats'],
		async ({ pageParam = 0 }) => {
			const response = chatService.getPaginatedChats(pageParam, maxPageSize, user.slug);
			return await response;
		},
		{
			getNextPageParam: (lastPage) => {
				if (lastPage?.meta?.hasNext) {
					return lastPage.page + 1;
				}
				return undefined;
			},
		},
	);
};
