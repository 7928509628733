import { memo } from 'react';
import When from '../When';
import ActivityIndicator from '../ActivityIndicator';
import Camera from './components/Camera';
import TakeImageButton from './components/TakeImageButton';
import RetakeImageButton from './components/RetakeImageButton';
import OpenGalleryButton from './components/OpenGalleryButton';
import StartCameraButton from './components/StartCameraButton';
import { useRef, useState } from '@hooks';
import styles from './CameraPhotoInput.module.css';
import type { ICameraApi } from './components/Camera';
import InputErrorMessage from '../InputErrorMessage';

const CameraPhotoInput = ({
	value,
	height,
	width,
	errorMessage,
	onChange,
	onCallImageLibrary,
	t,
}: ICameraPhotoInputProps) => {
	const cameraRef = useRef<ICameraApi>(null);
	const [cameraStatus, setCameraStatus] = useState<'ready' | 'error' | 'loading'>('loading');

	const takePhoto = () => {
		cameraRef.current?.takePhoto().then((imageUrl) => onChange(imageUrl ?? null));
	};

	return (
		<div className={styles.cameraPhotoInput} style={{ width, height }}>
			{value ? (
				<>
					<img
						alt="The screen capture"
						className={styles.cameraPhotoInput__selectedImage}
						height={height}
						src={value}
						width={width}
					/>

					<div className={styles.cameraPhotoInput__retakeImageButtonWrapper}>
						<RetakeImageButton title={t('Retake')} onClick={() => onChange(null)} />
					</div>
					<div className={styles.cameraPhotoInput__openImageGalleryButtonWrapper}>
						<OpenGalleryButton onClick={onCallImageLibrary} />
					</div>
				</>
			) : (
				<>
					<Camera ref={cameraRef} onError={() => setCameraStatus('error')} onReady={() => setCameraStatus('ready')} />

					<div className={styles.cameraPhotoInput__takeImageButtonWrapper}>
						<When condition={cameraStatus === 'loading'}>
							<ActivityIndicator color="white" />
						</When>
						<When condition={cameraStatus === 'ready'}>
							<TakeImageButton onClick={takePhoto} />
						</When>
						<When condition={cameraStatus === 'error'}>
							<StartCameraButton
								title={t('Grant camera permissions')}
								onClick={() =>
									alert(t("Permissions was denied and it's needed to be enabled in the browser settings."))
								}
							/>
						</When>
					</div>
					<div className={styles.cameraPhotoInput__openImageGalleryButtonWrapper}>
						<OpenGalleryButton onClick={onCallImageLibrary} />
					</div>
				</>
			)}
			<InputErrorMessage text={errorMessage} />
		</div>
	);
};

export interface ICameraPhotoInputProps {
	value: string | undefined;
	width: string | number;
	height: string | number;
	errorMessage?: string;
	t: (key: string) => string;
	onChange: (photoUrl: string | null) => void;
	onCallImageLibrary: () => void;
}

export default memo(CameraPhotoInput);
