import type {
	IInternalShare,
	TActivity,
	TEvent,
	TNetworkConnection,
	TShareIntentInternalShareType,
	TCommunity,
} from '@typings';
import type { TDeal } from '@tiger21-llc/connect-shared/src/typings';

export class InternalShareEntity {
	/**
	 * Generates a string based on the given internal share object.
	 *
	 * @param internalShare - An object of type `{type: string; id: string}` containing the following properties:
	 * @returns A string representing the string for the internal share which can be stored in the DB.
	 * @example
	 * ```typescript
	 * const internalShare: IInternalShare = { type: 'event', id: '123' };
	 * const sharedContent = internalShareToShareableContent(internalShare);
	 * console.log(sharedContent); // Outputs: "events/123"
	 * ```
	 */
	public internalShareToShareableContent = (internalShare: IInternalShare): string => {
		return `${internalShare.type}/${internalShare.id}`;
	};

	/**
	 * Extracts the type and id from a shared content string.
	 *
	 * @param sharedContent - A string representing the shared content in the format `type/id`,
	 * or `null`/`undefined` if the input is invalid or not provided.
	 * @returns An object of type `{type: string; id: string}` containing the extracted type and id,
	 * or `null` if the input is invalid.
	 * @example
	 * ```typescript
	 * const sharedContent = "event/123";
	 * const result = internalSharedContentOrienteering(sharedContent);
	 * console.log(result); // Outputs: { type: 'event', id: '123' }
	 *
	 * const invalidContent = null;
	 * const result2 = internalSharedContentOrienteering(invalidContent);
	 * console.log(result2); // Outputs: null
	 * ```
	 */
	public internalSharedContentOrienteering = (
		sharedContent: string | null | undefined,
	): { type: string; id: string } | null => {
		if (typeof sharedContent !== 'string') return null;
		const [type, id] = sharedContent.split('/');
		return { type, id };
	};

	/**
	 * Generates a deep link string based on the given shareable content string.
	 *
	 * @param shareableContent - A string in the format `type/id` (e.g., `event/123`).
	 * @returns A string representing the deep link URL for the internal share.
	 * @example
	 * ```typescript
	 * const shareableContent = "event/123";
	 * const deepLink = internalShareToDeepLink(shareableContent);
	 * console.log(deepLink); // Outputs: "calendar/events/123"
	 * ```
	 */
	public internalShareToRoute = (shareableContent: string): string => {
		const [type, id] = shareableContent.split('/');

		const routeMappingFunction = this.typeShareToRouteMap[type as TShareIntentInternalShareType];

		return routeMappingFunction(id);
	};

	/**
	 * A mapping of internal share types to route generation functions.
	 */
	protected typeShareToRouteMap: Record<TShareIntentInternalShareType, (id: string) => string> = {
		discussion: (discussionId: string) => `/posts/${discussionId}`,
		network: (networkId: string) => `/networks/${networkId}`,
		deal_post: (dealPostId: string) => `/deal/${dealPostId}`,
		deal: (dealId: string) => `/networks/${dealId}`,
		network_connection: (networkConnectionId: string) => `/networks/${networkConnectionId}/connections`,
		network_connection_post: (networkConnectionPostId: string) =>
			`/network-connections/${networkConnectionPostId}/details`,
		chapter: (chapterId: string) => `/groups/chapters/${chapterId}`,
		group: (groupId: string) => `/groups/${groupId}`,
		meeting: (meetingId: string) => `/calendar/group_meetings/${meetingId}`,
		event: (eventId: string) => `/calendar/events/${eventId}`,
	};

	/**
	 * Extracts shared content data into a structured object.
	 *
	 * @param params - Objects containing event, meeting, deal, etc., data from queries.
	 * @param orientedShare - The oriented share object with `type` and `id`.
	 * @returns A structured object containing shared content data.
	 */
	public constructSharedContentData(
		params: {
			event?: TEvent | null;
			meeting?: TEvent;
			deal?: TDeal;
			networkConnection?: TNetworkConnection;
			community?: TCommunity | null;
			activity?: TActivity;
		},
		orientedShare: { type?: string; id?: string } | null | undefined,
	): IInternalShare {
		const { event, meeting, deal, networkConnection, community, activity } = params;

		return {
			type: orientedShare?.type as TShareIntentInternalShareType,
			id: (event?.id || meeting?.id || deal?.id || networkConnection?.id || community?.id || activity?.id) as string,
			source: (community?.definition || event?.['@type'] || meeting?.['@type'] || deal?.['@type']) as string,
			title: (event?.title ||
				meeting?.title ||
				deal?.name ||
				networkConnection?.title ||
				community?.name ||
				activity?.subject ||
				activity?.body) as string,
			subtitle: (event?.description ||
				meeting?.description ||
				deal?.description ||
				networkConnection?.description ||
				community?.description ||
				activity?.body) as string,
			userId: deal?.creator?.id || networkConnection?.owner?.id || activity?.actor?.id,
			image: (deal?.creator?.avatarUrl ||
				networkConnection?.owner?.avatarUrl ||
				community?.avatarUrl ||
				community?.backgroundUrl ||
				activity?.actor?.data?.avatar) as string,
			compactEvent:
				event || meeting
					? ({
							startDate: event?.startDate,
							'@type': event?.['@type'],
							endDate: event?.endDate,
							networks: event?.networks,
							groups: event?.groups,
						} as Pick<TEvent, '@type' | 'startDate' | 'endDate' | 'networks' | 'groups'>)
					: undefined,
		};
	}
}
