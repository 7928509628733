import { useService } from '@hooks';
import { dayjs } from '@utils';
import type { TTipContext, TTipWithDetails } from '@typings';

export const useFetchTip = () => {
	const reactQuery = useService('ReactQueryService');
	const api = useService('ApiService');

	return function fetchTip(tipId: string | undefined, tipContext?: TTipContext) {
		return reactQuery.queryClient.fetchQuery<TTipWithDetails, Error>(
			reactQuery.queryKeys.tip(tipId as string, tipContext),
			async () => {
				const tip = await api.tips.getTip(tipId as string);
				const getTipDetails = await api.tips.getTipsDetails({
					page: 1,
					tips: [tip.id],
					communities: tipContext?.contextCommunityId ? [tipContext.contextCommunityId] : undefined,
					calendarItems: tipContext?.contextCalendarItemId ? [tipContext.contextCalendarItemId] : undefined,
				});
				const tipWithDetails: TTipWithDetails = { ...tip, details: getTipDetails[0] };
				return tipWithDetails;
			},
			{
				staleTime: dayjs.duration(15, 'minute').asMilliseconds(),
				cacheTime: dayjs.duration(15, 'minute').asMilliseconds(),
			},
		);
	};
};
