import { useTranslation } from '@hooks';
import { FillNotificationIcon, IconButton, NotificationIcon } from '@ui-kit';
import NotificationsFeedContextProvider from '@ui-modules/notifications/components/NotificationsFeedContextProvider';
import { forwardRef, useState } from 'react';
import styles from './NotificationsHeaderButton.module.css';
import type { ForwardedRef } from 'react';

const NotificationsHeaderButton = (
	{ isActive, onClick }: INotificationsHeaderButtonProps,
	ref: ForwardedRef<HTMLAnchorElement>,
) => {
	const { t } = useTranslation();

	const [unreadCount, setUnreadCount] = useState(0);

	return (
		<NotificationsFeedContextProvider>
			{({ unread, refreshUnreadUnseen }) => {
				/** When you mark all as read and remove a notification the unread counter is goes to a negative value.
				 *  To fix this issue we need to refresh the unread counter when it's changed manually.
				 *  react-activity feed package was patched to return the unread count from refreshUnreadUnseen() method.
				 *  Fixes weird issue T21C-5867.
				 */
				if (unread !== unreadCount) {
					refreshUnreadUnseen()
						.then((data) => {
							setUnreadCount(data?.unread ?? unread);
						})
						.catch(() => {
							setUnreadCount(unread);
						});
				}

				return (
					<IconButton
						badge={unreadCount > 0 ? { type: 'dot' } : undefined}
						disableHighlight
						forcedActive={isActive}
						icon={<NotificationIcon className={styles.button__icon} height={20} width={18} />}
						iconActive={<FillNotificationIcon className={styles.button__icon_active} height={20} width={18} />}
						label={t('Notifications')}
						linkTo="/notifications"
						ref={ref}
						onClick={(event) => {
							event.preventDefault();
							onClick();
						}}
					/>
				);
			}}
		</NotificationsFeedContextProvider>
	);
};

interface INotificationsHeaderButtonProps {
	isActive: boolean;
	onClick: () => void;
}

export default forwardRef(NotificationsHeaderButton);
