import React from 'react';

import { useTranslation } from '@hooks';

import InternalShareHeaderIcon from './InternalShareHeaderIcon';
import { useInternalShareContext } from '@ui-modules/share/hooks/useInternalShare';
import styles from './ShareHeader.module.css';
import { InternalShareEntity } from '@utils';
import { useSharedContent } from '@ui-modules/share/hooks/useSharedContent';
import { ArrowLeftIcon, CloseThinIcon } from '@ui-kit';
import type { TContent } from '@ui-modules/share/components/InternalShareModal/InternalShareModal';

const ShareHeader = ({ currentContent, handleContentChange }: IShareHeaderProps) => {
	const { t } = useTranslation();

	// Internal share
	const { internalShare, resetInternalShare, hasInternalShare } = useInternalShareContext();

	const onCancel = () => {
		resetInternalShare();
		handleContentChange(null);
	};

	const internalShareEntity = new InternalShareEntity();
	const { data: sharedContent, isLoading: isShareLoading } = useSharedContent(
		internalShareEntity.internalShareToShareableContent(internalShare),
	);

	return (
		<div className={styles.share__headerWrapper}>
			{/* Header Icons and Text */}
			<div className={styles.share__headerContentWrapper}>
				{/* Back Icon */}
				{currentContent && (
					<button
						className={styles.share__back}
						onClick={() => {
							handleContentChange(null);
						}}
					>
						<ArrowLeftIcon fill={'#333'} height={20} width={20} />
					</button>
				)}
				{hasInternalShare && <InternalShareHeaderIcon isShareLoading={isShareLoading} sharedContent={sharedContent} />}
				{/* Text Information */}
				<div className={styles.share__headerTextsWrapper}>
					{/* For Internal Share Additional Information */}
					{hasInternalShare && (
						<div>
							<p className={styles.share__title}>{sharedContent.title || internalShare.subtitle}</p>
						</div>
					)}
					<p className={styles.share__shareTo}>{t('Share to')}...</p>
				</div>
			</div>

			{/* Close Button */}
			<button className={styles.share__close} onClick={onCancel}>
				<CloseThinIcon fill={'#333'} height={17} width={17} />
			</button>
		</div>
	);
};

export interface IShareHeaderProps {
	currentContent: TContent;
	handleContentChange: (content: TContent) => void;
}

export default ShareHeader;
