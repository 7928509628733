import { useTranslation } from '@hooks';
import styles from './ChatAddEditMembers.module.css';
import { formatUserSubTitle, isEqual } from '@utils';
import { HeaderSearchInput } from '@ui-kit';
import UsersSelectionBar from '@ui-kit/components/UsersSelectionBar';
import AlphabetList from '@ui-kit/components/AlphabetList';
import type { TUserProfileRecord } from '@typings';

/** Chat compoonent used to add ad edit members */
const ChatAddEditMembers = ({
	onPressed,
	onCancelPressed,
	searchQuery,
	onChangeSearchQuery,
	selectedProfileIds,
	filteredProfiles,
	userProfileRecords,
	isLoading,
	handleSelectUser,
	handleRemoveUser,
	isEditing,
	initialNoOwnerMembersIds = [],
}: IChatAddEditMembersParams) => {
	const { t } = useTranslation();
	const selectedProfiles = userProfileRecords.filter((userProfileRecord) =>
		selectedProfileIds.includes(userProfileRecord.slug),
	);

	const savingDisabled = isEditing ? isEqual(initialNoOwnerMembersIds.sort(), selectedProfileIds.sort()) : false;

	return (
		<div className={styles.memberContainer}>
			<div className={styles.member__headerContainer}>
				<div className={styles.member__headerCancelText} onClick={onCancelPressed} onKeyPress={onCancelPressed}>
					{t('Cancel')}
				</div>
				<h4>{t('Choose People')}</h4>
				{selectedProfileIds.length > 0 ? (
					<div
						className={!savingDisabled ? styles.member__headerAddText : styles.member__headerAddText_disabled}
						onClick={savingDisabled ? undefined : onPressed}
						onKeyDown={savingDisabled ? undefined : onPressed}
					>
						{isEditing ? t('Save') : t('Add')}
					</div>
				) : (
					<div />
				)}
			</div>
			<div className={styles.member__searchInputBox}>
				<HeaderSearchInput
					placeholder={t('Search members')}
					showRightSearchIcon={true}
					value={searchQuery}
					onChange={(e) => onChangeSearchQuery(e.target.value)}
				/>
			</div>
			<div className={styles.member__selectUsersPageBody}>
				<UsersSelectionBar selectedProfiles={selectedProfiles} onRemove={handleRemoveUser} />
				<div className={styles.member__selectUsersList}>
					<AlphabetList
						checkWithSlug={true}
						emptyListElement={
							<div className={styles.member__emptyListContainer}>
								<span className={styles.member__emptyStateText}>
									{t('Sorry, we couldn’t find any members that match the criteria')}
								</span>
							</div>
						}
						isLoading={isLoading}
						renderUserSubTitle={formatUserSubTitle(t)}
						selectedProfileIds={selectedProfileIds}
						userProfileRecords={filteredProfiles}
						onClick={handleSelectUser}
					/>
				</div>
			</div>
		</div>
	);
};

export default ChatAddEditMembers;

export interface IChatAddEditMembersParams {
	onPressed: () => void;
	userProfileRecords: TUserProfileRecord[];
	onCancelPressed: () => void;
	searchQuery: string;
	onChangeSearchQuery: (searchQuery: string) => void;
	selectedProfileIds: string[];
	filteredProfiles: TUserProfileRecord[];
	isLoading: boolean;
	handleSelectUser: (userProfileRecord: TUserProfileRecord, isSelected: boolean | undefined) => void;
	handleRemoveUser: (userProfileRecord: TUserProfileRecord) => void;
	isEditing?: boolean;
	initialNoOwnerMembersIds?: string[];
}
