import { useMemo, useTranslation } from '@hooks';
import { ActivityIndicator, When } from '@ui-kit';
import { useAssetAllocationRecommendationsQuery } from '@ui-modules/assetAllocation/hooks/useAssetAllocationRecommendationsQuery';
import RecommendationsMemberBlock from './RecommendationsMemberBlock';
import RecommendationNetworksBlock from './RecommendationNetworksBlock';
import type { TAssetAllocation, TCommunity, TAssetAllocationValues, TRecommendationMember } from '@typings';
import styles from './AssetAllocationRecommendations.module.css';

import { getAssetAllocationMainDifferenceData } from '@ui-modules/assetAllocation/utils/getAssetAllocationMainDifferenceData';
import RecommendationsArticlesBlock from './RecommendationsArticlesBlock';

const AssetAllocationRecommendations = ({
	networkName,
	comparingAssetAllocation,
	userAssetAllocation,
	resetScroll,
}: IAssetAllocationRecommendationsProps) => {
	const { t } = useTranslation();

	const differenceRecommendation = useMemo(() => {
		const EMPTY_ASSET_ALLOCATION = {} as TAssetAllocation; // it used for generating initial values if the user asset allocation is not created yet.
		return getAssetAllocationMainDifferenceData(t)(
			userAssetAllocation ?? EMPTY_ASSET_ALLOCATION,
			comparingAssetAllocation ?? EMPTY_ASSET_ALLOCATION,
		);
	}, [userAssetAllocation, comparingAssetAllocation]);

	const recommendationQueries = useMemo(
		() => differenceRecommendation?.map?.((item) => item.title),
		[differenceRecommendation],
	);

	const {
		data: recommendations,
		isError,
		isFetching,
	} = useAssetAllocationRecommendationsQuery({
		searchQueries: recommendationQueries,
		// Need to reset scroll position on recommendation request started as response is shuffled and scroll position is not actual.
		onRequestStarted: resetScroll,
	});

	if (isFetching) {
		return (
			<div className={styles.recommendations__loading}>
				<ActivityIndicator />
			</div>
		);
	}
	if (isError) return null;
	return (
		<div className={styles.recommendations__container}>
			<p className={styles.recommendations__title}>{t('Suggestions')}</p>

			{differenceRecommendation.length ? (
				<p className={styles.recommendations__subTitle}>
					{t('Your biggest differences are in')}{' '}
					{differenceRecommendation.map((recommendation, index, array) => (
						<>
							<span className={styles.recommendations__subTitle_highlighted} key={recommendation.title}>
								{recommendation.title}
							</span>
							{index < array.length - 1 ? <span>{` ${t('and')} `}</span> : null}
						</>
					))}
					{'. '}
					{t('Here are some')} <br />
					{t('suggestions from T21 Connect:')}
				</p>
			) : (
				<p className={styles.recommendations__subTitle}>{t('Your asset allocation is similar to other Members')}</p>
			)}
			<When condition={!!recommendations?.networks?.length}>
				<p className={styles.recommendations__listTitle}>{t('Networks')}</p>
				<div className={styles.recommendations__section}>
					<RecommendationNetworksBlock networkName={networkName} networks={recommendations?.networks as TCommunity[]} />
				</div>
			</When>
			<When condition={!!recommendations?.members?.length}>
				<p className={styles.recommendations__listTitle}>{t('Members')}</p>
				<div className={styles.recommendations__section}>
					<RecommendationsMemberBlock
						members={recommendations?.members as TRecommendationMember[]}
						networkName={networkName}
					/>
				</div>
			</When>
			<When condition={!!recommendations?.posts?.length}>
				<p className={styles.recommendations__listTitle}>{t('Articles')}</p>
				<RecommendationsArticlesBlock networkName={networkName} posts={recommendations?.posts} />
			</When>
		</div>
	);
};

interface IAssetAllocationRecommendationsProps {
	networkName: string;
	comparingAssetAllocation: TAssetAllocationValues;
	userAssetAllocation: TAssetAllocationValues;
	resetScroll: () => void;
}

export default AssetAllocationRecommendations;
