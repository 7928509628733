import { dayjs, clsx } from '@utils';
import styles from './TipDetails.module.css';
import TipContributors from '@ui-modules/tipJar/components/TipContributors';
import TipScreenHeader from '@ui-modules/tipJar/components/TipScreenHeader';
import { useTranslation } from '@hooks';
import type { TTipContext, TTipWithDetails } from '@typings';
import { Button, InfoCircleIcon, QuestionTooltip, RightTipsIcon, StarsRating } from '@ui-kit';
import TipDetailsImageCarousel from '@ui-modules/tipJar/components/TipDetailsImageCarousel';
import TipCollapsibleTextSection from '@ui-modules/tipJar/components/TipCollapsibleTextSection';
import type { IPatchTipFormMainProps } from '@ui-modules/tipJar/components/PatchTipForm';
import type { TCreateTipForm } from '@schemas';
import type { ReactNode } from 'react';
import { ROUTES } from '@constants';
import type { ITipsImageCarouselProps } from '../TipsImageCarousel/TipsImageCarousel';

const TipDetails = ({
	tip,
	isTipEditable = false,
	tipContext,
	onTipPatched,
	onDisplayedGalleryItemChanged,
	Title,
	Description,
	Gallery,
	initialDisplayedGalleryItemIndex,
}: ITipDetailProps) => {
	const { t } = useTranslation();

	const openUrl = (url: string) => window.open(url, 'blank');
	const openTipLink = () => tip.links && openUrl(String(tip.links[0].url));
	const tipId = 'id' in tip ? tip.id : undefined;
	const tipCategories = tip.tags.map((tag) => tag.title).join(', ');

	return (
		<div>
			{Title ?? (
				<TipScreenHeader isTipEditable={isTipEditable} tipId={tipId} title={tip.title} onTipPatched={onTipPatched} />
			)}
			<div className={styles.tipDetails}>
				<div className={styles.tipDetails__info}>
					<div className={styles.tipDetails__containerInner}>
						<div>
							<div className={styles.tipDetails__titleContainer}>
								<h5>{t('Category')}</h5>
								<span>{tipCategories}</span>
							</div>
							<div className={styles.tipDetails__titleContainer}>
								<h5>{t('Post Date')}</h5>
								<span>{'createdAt' in tip ? dayjs(tip.createdAt).format('D MMM YYYY') : <i>{t('Pending')}</i>}</span>
							</div>
						</div>
						<div className={styles.tipDetails__reviewsContainer}>
							{'id' in tip && (
								<div className={styles.tipDetails__titleContainer}>
									<TipContributors tipContext={tipContext} tipId={tip.id} />
								</div>
							)}
							<div className={styles.tipDetails__memberRating}>
								<h5>{t('All Member Ratings')}</h5>
								<StarsRating
									label={t('{{count}} review', {
										count: 'details' in tip ? tip.details.reviewsCount : 0,
										defaultValue_plural: '{{count}} reviews',
									})}
									labelHref={'id' in tip ? `${ROUTES.tipPage(tip.id)}#reviews` : '#reviews'}
									rating={'details' in tip ? tip.details.rate : 0}
								/>
							</div>
						</div>
					</div>
					<div className={clsx(styles.tipDetails__titleContainer, styles.tipDetails__description)}>
						{Description ?? (
							<TipCollapsibleTextSection
								collapsedTitle={t('Read More')}
								expandedTitle={t('Show Less')}
								isTextButtonLight={true}
								isTipEditable={isTipEditable}
								text={String(tip.description)}
								tipId={tipId}
								title={t('Description')}
								onTipPatched={onTipPatched}
							/>
						)}
					</div>
					{tip?.links?.length ? (
						<Button
							className={styles.tipDetails__viewButton}
							icon={<RightTipsIcon />}
							iconPosition="right"
							title={tip.links[0].title ? t('View on {{source}}', { source: tip.links[0].title }) : t('View')}
							titleClassName={styles.tipDetails__viewButtonTitle}
							type="outline"
							variant="medium"
							onClick={openTipLink}
						/>
					) : null}
				</div>
				<div className={styles.tipDetails__imageContainer}>
					{isTipEditable ? (
						<div className={styles.tipDetails__imageContainerHeader}>
							<h5>{t('Image')}</h5>
							<QuestionTooltip content={t('Displayed photo will be used a Tip cover')} Icon={InfoCircleIcon} />
						</div>
					) : null}
					{Gallery ?? (
						<TipDetailsImageCarousel
							confirmImageItemRemoving
							initialDisplayedGalleryItemIndex={initialDisplayedGalleryItemIndex}
							isTipEditable={isTipEditable}
							tipId={tipId}
							tipImages={tip.galleryFiles}
							onDisplayedGalleryItemChanged={onDisplayedGalleryItemChanged}
							onTipPatched={onTipPatched}
						/>
					)}
				</div>
			</div>
			<div id={'reviews'} />
		</div>
	);
};

interface ITipDetailProps
	extends Pick<IPatchTipFormMainProps, 'onTipPatched'>,
		Pick<ITipsImageCarouselProps, 'initialDisplayedGalleryItemIndex' | 'onDisplayedGalleryItemChanged'> {
	tip: TTipWithDetails | TCreateTipForm;
	isTipEditable: boolean;
	tipContext?: TTipContext;
	Title?: ReactNode;
	Description?: ReactNode;
	Gallery?: ReactNode;
}

export default TipDetails;
