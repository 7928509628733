import { useLocation } from 'react-router-dom';
import type { TTipContext } from '@typings';

export const useTipContextQueryParams = (): TTipContext => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const contextCommunityId = queryParams.get('contextCommunityId');
	const contextCalendarItemId = queryParams.get('contextCalendarItemId');
	const contextMyCommunities = queryParams.get('contextMyCommunities') === 'true' || undefined;

	return { contextCommunityId, contextCalendarItemId, contextMyCommunities };
};
