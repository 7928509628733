import { SinglePost } from 'react-activity-feed';
import { ActivityIndicator, When } from '@ui-kit';
import CommentControls from '@ui-modules/feed/components/CommentControls';
import FeedCommentList from '@ui-modules/feed/components/FeedCommentList';
import { useCallback, useNotification, useService, useTrackAlgoliaEventEffect, useTranslation } from '@hooks';
import { useFeedActivityQuery } from '@ui-modules/feed/hooks/useFeedActivityQuery';
import { useCrossPromotionsAnalytics } from '@ui-modules/feed/hooks/useCrossPromotionsAnalytics';
import { useValidateDisplayingActivity } from '@ui-modules/feed/hooks/useValidateDisplayingActivity';
import { usePdfViewer } from '@ui-modules/files/components/PdfViewer';
import { ActivityNoLongerAvailableError } from '@errors';
import { downloadFile, extractFileExtensionFromUrl } from '@utils';
import styles from './FeedDiscussions.module.css';
import type { TActivity } from '@typings';
import type { ActivityProps, FeedProps } from 'react-activity-feed';

const FeedDiscussions = ({
	streamActivityId,
	feedSlug,
	onCommentLikeToggled,
	onCommentSent,
	onErrorDisplaying,
	doReactionsFilterRequest,
}: IFeedDiscussionsProps) => {
	// Dependencies
	const { t } = useTranslation();
	const { showInfo, showUnknownError } = useNotification();
	const feedService = useService('FeedService');
	const { trackActivityView } = useCrossPromotionsAnalytics();
	const pdfViewer = usePdfViewer();

	// Query data.
	const { data: activity, isInitialLoading } = useFeedActivityQuery(streamActivityId, feedSlug, {
		onSuccess: (activity) => activity && trackActivityView(activity),
		onError: (error) => {
			onErrorDisplaying();
			if (error instanceof ActivityNoLongerAvailableError) {
				showInfo(ActivityNoLongerAvailableError.getNotificationMessage(t));
			} else {
				showUnknownError(error);
			}
			onErrorDisplaying();
		},
	});
	useValidateDisplayingActivity(activity, onErrorDisplaying);

	const openFileUrl = (fileUrl: string) => {
		const fileExtension = extractFileExtensionFromUrl(fileUrl);
		if (fileExtension === 'pdf') pdfViewer.open(fileUrl);
		else downloadFile(fileUrl);
	};
	useTrackAlgoliaEventEffect('Discussion Viewed', { discussionId: String(activity?.foreign_id) }, [activity]);

	const renderActivity = useCallback(
		({ activity }: ActivityProps) => {
			const hasComments = !!activity?.reaction_counts?.comment;
			return (
				<>
					<When condition={!hasComments}>
						<div className={styles.feedDiscussions__zeroState}>
							{t('No discussions available yet. Be the first to start a discussion below.')}
						</div>
					</When>
					<CommentControls.EditingContextProvider>
						<FeedCommentList
							activity={activity as unknown as TActivity}
							className={styles.feedDiscussions__commentList}
							feedSlug={feedSlug}
							openFileUrl={openFileUrl}
							onCommentLikeToggled={onCommentLikeToggled}
							onCommentSent={onCommentSent}
						/>
						<div className={styles.feedDiscussions__bottomWrapper}>
							<div className={styles.feedDiscussions__inputWrapper}>
								<CommentControls
									activity={activity as unknown as TActivity} // can't reuse stream typescript definitions ;-( .
									customClass={styles.feedDiscussions__commentControlWrapper}
									onCommentSent={onCommentSent}
								/>
							</div>
						</div>
					</CommentControls.EditingContextProvider>
				</>
			);
		},
		[feedSlug, streamActivityId], // Prevent rerendering of renderActivity as much as you can, otherwise you will struggle with issues when you do some actions (edit/delete/like etc.) (@see T21C-7602,T21C-7603,T21C-7600) [@DmitriyNikolenko]
	);
	const renderActivityIndicator = useCallback(() => <ActivityIndicator key="loader" size="medium" type="fit" />, []);

	if (isInitialLoading) return renderActivityIndicator();
	if (!activity) return <div className={styles.feedDiscussions__zeroState}>{t('Discussions are not available')}</div>; // When activity is not created, maybe because of BE issue.
	return (
		<>
			<SinglePost
				Activity={renderActivity}
				activityId={streamActivityId}
				doReactionsFilterRequest={(options) =>
					doReactionsFilterRequest
						? doReactionsFilterRequest(options)
						: (feedService.doReactionsFilterRequest(options) as any)
				} // By default, comments does not have own likes which brakes like functionality after page reload. Fix (T21C-2869). [@dmitriy.nikolenko]
				feedGroup="user"
				LoadingIndicator={renderActivityIndicator}
				notify={false} // 'notify' is disabled and all tricks are removed for now because of T21C-8369 [@DmitriyNikolenko]
				options={{ withReactionCounts: true, withOwnChildren: true }}
				userId={feedSlug}
			/>
		</>
	);
};

interface IFeedDiscussionsProps {
	streamActivityId: string;
	feedSlug: string;
	/** Called when a user is not able to see a discussion (post removed, post absent or unavailable for some reason). */
	onErrorDisplaying: () => void;
	onCommentLikeToggled?: (liked: boolean) => void;
	onCommentSent?: (wasEditing: boolean, commentId?: string) => void;
	doReactionsFilterRequest?: FeedProps['doReactionsFilterRequest'];
}

export default FeedDiscussions;
