import { useService, useMutation, useNotification, useTranslation } from '@hooks';
import { useFetchTip } from '@ui-modules/tipJar/hooks/useFetchTip';
import { useDeleteTipMutation } from '@ui-modules/tipJar/hooks/useDeleteTipMutation';
import type { MutateOptions } from '@tanstack/react-query';
import { TipEntity } from '@tiger21-llc/connect-shared/src/utils/TipEntity';
import type { TTipReview, TTipWithDetails } from '@typings';

export const useDeleteTipReviewMutation = (
	tip: TTipWithDetails,
	options?: MutateOptions<TDeleteTipReviewResultStatus, Error, TTipReview>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showSuccess, showUnknownError } = useNotification();
	const { t } = useTranslation();

	const fetchTip = useFetchTip();
	const deleteTipMutation = useDeleteTipMutation();

	return useMutation<TDeleteTipReviewResultStatus, Error, TTipReview>(
		['deleteTipReview'],
		async (tipReview) => {
			const fetchedTip = await fetchTip(tip.id); // to surely get the latest tip data without contexts

			if (TipEntity.isOwnerReview(fetchedTip, tipReview)) {
				if (TipEntity.canBeTransferredToNewOwnerAfterDeletingReview(fetchedTip)) {
					await api.tips.deleteTipReview(tipReview.id);
					return 'tip_owner_changed';
				} else {
					await deleteTipMutation.mutateAsync(fetchedTip);
					return 'tip_removed';
				}
			} else {
				await api.tips.deleteTipReview(tipReview.id);
				return 'tip_review_removed';
			}
		},
		{
			...options,
			onSuccess: async (response, variables, context) => {
				const tipReviewId = variables.id;
				const tipId = variables.tipId;

				await reactQuery.queryClient.removeQueries(reactQuery.queryKeys.tipReview(tipId, tipReviewId));

				if (response === 'tip_review_removed' || response === 'tip_owner_changed') {
					await Promise.allSettled([
						reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.tip(tipId)),
						reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.tipReviews(tipId)),
					]);
					await options?.onSuccess?.(response, variables, context);
					if (response === 'tip_review_removed') {
						showSuccess({ title: t('Tip Review deleted') });
						reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.tip(tipId));
						reactQuery.queryClient.removeQueries(reactQuery.queryKeys.tipReviews(tipId));
					} else if (response === 'tip_owner_changed') showSuccess({ title: t('Tip owner is changed') });
				} else if (response === 'tip_removed') {
					await options?.onSuccess?.(response, variables, context);

					setTimeout(function clearCacheAfterTransitionOutOfTipPage() {
						reactQuery.queryClient.removeQueries(reactQuery.queryKeys.tip(tipId));
						reactQuery.queryClient.removeQueries(reactQuery.queryKeys.tipContributors(tipId));
						reactQuery.queryClient.removeQueries(reactQuery.queryKeys.tipReviews(tipId));
					}, 1000);

					// success message is shown by useDeleteTipMutation().
				}

				reactQuery.queryClient.removeQueries(reactQuery.queryKeys.tipReview(tipId, tipReviewId));
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.myTips());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.allTips());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.groupsTips());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.communityTips());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.tipContributors(tipId));
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};

export type TDeleteTipReviewResultStatus = 'tip_review_removed' | 'tip_owner_changed' | 'tip_removed';
