import { useService, useTranslation } from '@hooks';
import { dayjs } from '@utils';
import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from '@tanstack/react-query';
import type { TAssetAllocationRecommendation } from '@typings';
import { mergeAssetAllocationRecommendationQueries } from '../utils/mergeAssetAllocationRecommendationQueries';

interface IUseAssetAllocationRecommendationsQueryOptions {
	searchQueries: string[];
	queryOptions?: UseQueryOptions<TAssetAllocationRecommendation, Error>;
	onRequestStarted: () => void;
}

export const useAssetAllocationRecommendationsQuery = ({
	searchQueries,
	queryOptions,
	onRequestStarted,
}: IUseAssetAllocationRecommendationsQueryOptions) => {
	const api = useService('ApiService');
	const { queryKeys } = useService('ReactQueryService');
	const { t } = useTranslation();

	return useQuery<TAssetAllocationRecommendation, Error>(
		queryKeys.getAssetAllocationsRecommendationCollections(searchQueries),
		async () => {
			onRequestStarted();
			const requests = searchQueries.map((searchQuery) =>
				api.assetAllocation.getAssetAllocationRecommendations({ searchQuery }),
			);
			const responses = await Promise.all(requests);
			const mergedResponses = mergeAssetAllocationRecommendationQueries(responses, t);
			return mergedResponses;
		},
		{
			enabled: !!searchQueries.length,
			staleTime: dayjs.duration(2, 'days').asMilliseconds(),
			cacheTime: dayjs.duration(2, 'days').asMilliseconds(),
			...queryOptions,
		},
	);
};
