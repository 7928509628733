import {
	ActivityIndicator,
	Avatar,
	DealsIcon,
	EventsIcon,
	NetworkFilledIcon,
	NetworkIcon,
	UserInActiveIcon,
} from '@ui-kit';

import { useGetUserQuery } from '@ui-modules/profile/hooks/useGetUserQuery';
import React from 'react';
import { useGetEventQuery } from '@ui-modules/events/hooks/useGetEventQuery';
import EventAvatar from '@ui-modules/events/components/EventAvatar/EventAvatar';
import styles from './ShareHeader.module.css';
import type { IInternalShare } from '@typings';

const InternalShareHeaderIcon = ({ sharedContent, isShareLoading }: IInternalShareHeaderIconProps) => {
	const isEventQueryEnabled = sharedContent.type === 'event';

	const { data: activityCreator, isLoading: isActivityCreatorLoading } = useGetUserQuery(sharedContent.userId);

	const { data: event, isLoading: isEventLoading } = useGetEventQuery(sharedContent.id, undefined, {
		enabled: sharedContent.type === 'meeting' || sharedContent.type === 'event',
	});

	const isLoading =
		(isActivityCreatorLoading && sharedContent.type === 'discussion') || (isEventLoading && isEventQueryEnabled);

	const renderIcon = () => {
		switch (sharedContent?.type) {
			case 'discussion':
				if (activityCreator?.roles?.includes('ROLE_INACTIVE')) {
					return <UserInActiveIcon width={32} />;
				} else {
					return (
						<Avatar
							imageUri={sharedContent.image}
							outline={activityCreator?.roles?.includes('ROLE_CHAIR') as boolean}
							size={40}
							title={`${activityCreator?.firstName} ${activityCreator?.lastName}`}
						/>
					);
				}

			case 'network_connection':
				return sharedContent.image ? (
					<Avatar imageUri={sharedContent.image} outline={false} size={34} />
				) : (
					<NetworkFilledIcon />
				);
			case 'network':
			case 'group':
			case 'chapter':
			case 'network_connection_post':
				return sharedContent.image ? (
					<Avatar imageUri={sharedContent.image} outline={false} size={34} />
				) : (
					<NetworkIcon fill={'var(--colors-primaryDarkGray)'} height={34} width={34} />
				);
			case 'deal':
			case 'deal_post':
				return sharedContent.image ? (
					<Avatar imageUri={sharedContent.image} outline={false} size={34} />
				) : (
					<DealsIcon fill={'var(--colors-primaryDarkGray)'} height={34} width={34} />
				);
			case 'event':
			case 'meeting':
				return event?.startDate || event?.endDate ? (
					<EventAvatar event={event} />
				) : (
					<EventsIcon fill={'var(--colors-primaryDarkGray)'} height={34} width={34} />
				);
			default:
				return null;
		}
	};

	return (
		<div className={styles.internalShare__iconWrapper}>
			{isLoading || isShareLoading ? <ActivityIndicator /> : renderIcon()}
		</div>
	);
};

export interface IInternalShareHeaderIconProps {
	sharedContent: IInternalShare;
	isShareLoading: boolean;
}

export default InternalShareHeaderIcon;
