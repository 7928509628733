import { useService, useQuery, useMe } from '@hooks';
import type { TCommunity, TUserMembership } from '@typings';
import { dayjs } from '@utils';

export const useMemberMembershipQuery = () => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { user } = useMe();

	return useQuery<TUserMembership[], Error>(
		reactQuery.queryKeys.getMemberRelation(),
		async () => {
			const meMemberships = await api.user.getUser(user?.id as string);
			return meMemberships.memberships || [];
		},
		{
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
		},
	);
};

export const useFetchMemberships = () => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { user } = useMe();

	return async function fetchMemberships() {
		return reactQuery.queryClient.fetchQuery<TUserMembership[], Error>(
			reactQuery.queryKeys.getMemberRelation(),
			async () => {
				const meMemberships = await api.user.getUser(user?.id as string);
				return meMemberships.memberships || [];
			},
			{
				staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			},
		);
	};
};

export const useFetchMyGroupAndChapterCommunityIds = () => {
	const fetchMemberships = useFetchMemberships();

	return async function fetchMyGroupAndChapterCommunityIds(): Promise<TCommunity['id'][]> {
		return await fetchMemberships().then((memberships) =>
			memberships
				.filter(
					(membership) => membership.community.definition === 'group' || membership.community.definition === 'chapter',
				)
				.map((membership) => membership.community.id),
		);
	};
};
