import { useService, useMutation, useNotification, useMe } from '@hooks';
import type { TTip, TTipImage } from '@typings';
import type { UseMutationOptions } from '@tanstack/react-query';
import type { TPatchTipImage } from '@schemas';

export const useUploadTipFileMutation = (
	mutationOptions?: UseMutationOptions<TTipImage, Error, TUploadTipFilePayload>,
) => {
	const api = useService('ApiService');
	const { user } = useMe();
	const { showUnknownError } = useNotification();

	return useMutation<TTipImage, Error, TUploadTipFilePayload>(
		['mediaObject.uploadDocument'],
		async ({ tipId, patchTipImage }) => {
			const tipImageUrl = patchTipImage.documentUrl;
			let imageResponse: Response;
			if (tipImageUrl.startsWith('file:') || tipImageUrl.startsWith('blob:')) {
				imageResponse = await fetch(tipImageUrl);
			} else {
				const edgeFunctionsBaseUrl = location.hostname === 'localhost' ? 'https://dev.connect.tiger21.com' : '';
				imageResponse = await fetch(`${edgeFunctionsBaseUrl}/cors-proxy/?url=${tipImageUrl}`);
			}
			const imageBlob = await imageResponse.blob();
			const image = new File([imageBlob], `tip image ${new Date().toISOString()}`, { type: imageBlob.type });
			const mediaObject = await api.mediaObject.uploadImage(image);

			const tipImage = await api.tips.postTipImage({
				isAiGenerated: patchTipImage.isAiGenerated,
				name: patchTipImage.name || 'Name to not to broke API',
				relatedEntityId: tipId,
				mediaObjectId: mediaObject.id,
				isCover: !!patchTipImage.isAiGenerated,
				ownerId: user.id,
			});

			return tipImage;
		},
		{
			...mutationOptions,
			onSuccess: (data, variables, context) => {
				mutationOptions?.onSuccess?.(data, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				mutationOptions?.onError?.(error, variables, context);
			},
		},
	);
};

type TUploadTipFilePayload = {
	tipId: TTip['id'];
	patchTipImage: TPatchTipImage;
};
