import UserHeader from '@ui-kit/components/UserHeader';
import StarsRating from '@ui-kit/components/StarsRating';
import { dayjs, noop } from '@utils';
import { useTranslation, useDebouncedCallback } from '@hooks';
import type { TMeatballMenuOption } from '@ui-kit';
import { Linkify } from '@ui-kit';
import { CheckboxInput, CollapsibleText, Gap, MeatballMenu } from '@ui-kit';
import styles from './TipReviewCard.module.css';
import ThumbUpButton from '@ui-kit/components/ThumbUpButton';
import type { TTipReview, TTipReviewWithReaction } from '@typings';

const TipReviewCard = ({
	tipReview,
	meatballsMenuOptions,
	canThumbUp = true,
	onClickUser,
	onThumbUp,
}: ITipReviewCardProps) => {
	const { t } = useTranslation();

	const debouncedOnThumbUp = useDebouncedCallback(() => onThumbUp?.(tipReview as TTipReviewWithReaction), 500, {
		leading: true,
		trailing: false,
	});
	const options = {
		attributes: {
			target: '_blank',
			rel: 'noopener noreferrer',
		},
	};
	const wasEdited = tipReview.createdAt !== tipReview.updatedAt;
	const lastChangeDate = dayjs(tipReview.createdAt).format('D MMM YYYY');
	const dateLabel = wasEdited ? t('edited on {{date}}', { date: lastChangeDate }) : lastChangeDate;

	return (
		<div className={styles.tipReviewCard}>
			<UserHeader {...tipReview.owner} onClick={onClickUser}>
				{meatballsMenuOptions?.length ? <MeatballMenu options={meatballsMenuOptions} position={'left'} /> : null}
			</UserHeader>
			<div className={styles.tipReviewCard__starsRating}>
				<StarsRating label={dateLabel} rating={tipReview.rate} />
			</div>
			<div className={styles.tipReviewCard__collapsibleText}>
				<Linkify options={options}>
					<CollapsibleText
						collapsedNumberOfLines={3}
						collapsedTitle={t('Read More')}
						expandedTitle={t('Show Less')}
						isTextButtonLight={true}
					>
						{tipReview.description}
					</CollapsibleText>
				</Linkify>
			</div>
			<div className={styles.tipReviewCard__footer}>
				{tipReview.vestedInterest ? (
					<CheckboxInput
						disabled={true}
						label={t('Has vested interest')}
						size="extra-small"
						value={tipReview.vestedInterest}
						onChange={noop}
					/>
				) : (
					<Gap />
				)}
				{'reactions' in tipReview ? (
					<div className={styles.tipReviewCard__thumbUpButton}>
						<ThumbUpButton
							active={!!tipReview.reactions?.ownReaction}
							count={tipReview.reactions?.likesCount}
							disabled={!canThumbUp}
							onClick={debouncedOnThumbUp}
						/>
					</div>
				) : null}
			</div>
		</div>
	);
};

export interface ITipReviewCardProps {
	tipReview: TTipReview | TTipReviewWithReaction;
	meatballsMenuOptions?: Array<TMeatballMenuOption>;
	onClickUser?: () => void;
	onThumbUp?: (tipReview: TTipReviewWithReaction) => void;
	canThumbUp?: boolean;
}

export default TipReviewCard;
