import { useService } from '@hooks';
import { dayjs } from '@utils';

export const useFetchAiToken = () => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return async function fetchAiToken() {
		return await reactQuery.queryClient.fetchQuery(['tips', 'aiToken'], async () => api.ai.getAiToken(), {
			staleTime: dayjs.duration(4, 'minutes').asMilliseconds(),
			cacheTime: dayjs.duration(4, 'minutes').asMilliseconds(),
		});
	};
};
