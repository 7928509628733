import axios, { type AxiosResponse } from 'axios';

export const useIsRemoteUrlImage = (): TIsRemoteUrlImage => {
	const edgeFunctionsBaseUrl = location.hostname === 'localhost' ? 'https://dev.connect.tiger21.com' : '';

	return async function (url: string) {
		const proxiedUrl = `${edgeFunctionsBaseUrl}/cors-proxy/?url=${url}`;
		const remoteUrlIsImage: boolean = await axios
			.get(proxiedUrl, { headers: { Accept: 'image/*' } })
			.then(isResponseAcceptsImage)
			.catch(() => false);

		return remoteUrlIsImage;
	};
};

function isResponseAcceptsImage(response: AxiosResponse): boolean {
	const contentType = response.headers['content-type'];
	return String(contentType).startsWith('image/');
}

type TIsRemoteUrlImage = (url: string) => Promise<boolean>;
