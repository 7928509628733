import { CommunityEntity, memoize } from '@utils';
import type { TChapterTabName, TCommunity, TUser } from '@typings';

/** Determines abilities to see single group tabs in order to restrict access. */
export const getChapterTabsPermissions = memoize((chapter: TCommunity | undefined, user: TUser) => {
	const tabs: TChapterTabName[] = [];

	if (chapter) {
		const isGroupUpcoming = CommunityEntity.isUpcomingGroup(chapter);
		const isMeetingsShown = CommunityEntity.shouldShowMeetingsOrEvents(chapter, user.roles) || isGroupUpcoming;
		const isMembersShown = CommunityEntity.shouldShowMembers(chapter, user.roles) || isGroupUpcoming;
		const isDocumentsShown = CommunityEntity.shouldShowDocumentsV2(chapter, user.roles);
		const isDiscussionsShown = CommunityEntity.shouldShowDiscussionsV2(chapter, user.roles);
		const isTipsShown = CommunityEntity.shouldShowTips(chapter, user);

		if (isMeetingsShown) tabs.push('events');
		if (isMembersShown) tabs.push('members');
		if (isDiscussionsShown) tabs.push('discussions');
		if (isDocumentsShown) tabs.push('files');
		if (isTipsShown) tabs.push('tips');

		return {
			tabs,
			isGroupUpcoming,
			isMeetingsShown,
			isMembersShown,
			isDocumentsShown,
			isDiscussionsShown,
			isTipsShown,
		};
	} else {
		return {
			tabs,
		};
	}
});
