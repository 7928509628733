import { FormPageLayout, Page } from '../../components';
import { EmptyStateMessage } from '@ui-kit';
import NewTipForm from '@ui-modules/tipJar/components/NewTipForm';
import { useTranslation, useNavigate, useSearchParams } from '@hooks';
import styles from './NewTipPage.module.css';
import { ROUTES } from '@constants';
import { UrlParser, qs, catchError } from '@utils';
import { patchTipFormSchema } from '@schemas';
import type { TPatchTipForm } from '@schemas';

const NewTipPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();
	const [paramsValidationError, params] = catchError(() =>
		patchTipFormSchema
			.pick(['galleryFiles', 'description', 'links', 'contextCalendarItemId'])
			.cast(qs.parse(searchParams.toString()), { stripUnknown: true }),
	);
	return (
		<Page title={t('New Tip')}>
			{paramsValidationError ? (
				<EmptyStateMessage text={paramsValidationError?.message || t('Something went wrong')} />
			) : (
				<NewTipForm
					initialValues={params}
					renderWrapper={({ formProps, children }) => (
						<FormPageLayout
							allowedNextPaths={[ROUTES.newTip()]}
							formProps={formProps}
							headerTitle={t('New Tip')}
							isSaveButtonHidden
						>
							<div className={styles.newTipPage__pageContent}>{children}</div>
						</FormPageLayout>
					)}
					onSubmit={async (values) => {
						const link = UrlParser.parseUrlsFromText(String(values?.description))?.[0];
						const patchTipForm: TPatchTipForm = {
							...values,
							...(link
								? {
										links: [
											{
												url: link,
												title: null,
											},
										],
									}
								: null),
							contextCalendarItemId: params.contextCalendarItemId,
						};
						navigate(ROUTES.newTip(patchTipForm), { replace: true }); // to keep initialValues in the URL if a user go back
						navigate(ROUTES.newTipCategories(patchTipForm));
					}}
				/>
			)}
		</Page>
	);
};

export default NewTipPage;
