import { useTipContributorsQuery } from '@ui-modules/tipJar/hooks/useTipContributorsQuery';
import { ActivityIndicator, Avatar } from '@ui-kit';
import styles from './TipContributors.module.css';
import type { TTipContext } from '@typings';

const TipContributors = ({ tipId, maxVisible = 4, tipContext }: ITipContributors) => {
	const { tipContributors, isInitialLoading } = useTipContributorsQuery(tipId, tipContext, maxVisible);

	const reviewOwners = tipContributors?.reviewOwners ?? [];
	const reviewsCount = tipContributors?.reviewsCount ?? 0;
	const remainingCount = reviewsCount - maxVisible;

	if (isInitialLoading) return <ActivityIndicator size="small" />;
	return (
		<div className={styles.tipContributors}>
			{reviewOwners.map((contributor, index) => (
				<div
					className={styles.tipContributors__avatar}
					key={`${index}-${contributor.id}`}
					style={{ marginLeft: index === 0 ? 0 : -15 }}
				>
					<Avatar
						className={styles.tipContributors__avatarInner}
						imageUri={contributor.avatarUrl}
						outline={contributor.roles.includes('ROLE_CHAIR')}
						size={24}
						title={contributor.fullName}
					/>
				</div>
			))}
			{remainingCount > 0 && <div className={styles.tipContributors__lastImage}>+{remainingCount}</div>}
		</div>
	);
};

interface ITipContributors {
	tipId: string;
	maxVisible?: number;
	tipContext?: TTipContext;
}

export default TipContributors;
