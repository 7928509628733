import type { ReactNode } from 'react';
import { DropdownIcon, FilterIcon } from '@ui-kit/icons';
import styles from './FiltersDropdown.module.css';
import { noop } from '@utils';
import clsx from 'clsx';

const FiltersDropdown = ({
	title,
	cancelLabel,
	applyLabel,
	filteredByLabel,
	appliedFilters = [],
	applyDisabled = false,
	children,
	onCancel,
	onApply,
	isDropdownOpen,
	setIsDropdownOpen,
}: IFiltersDropdownProps) => {
	const toggleExpanded = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	return (
		<div>
			{appliedFilters.length && !isDropdownOpen ? (
				<div
					className={clsx(styles.filtersDropdown, styles.filtersDropdown__filteredByLabel)}
					onClick={toggleExpanded}
					onKeyDown={noop}
				>
					<div className={styles.filtersDropdown__selectedFiltersBar}>
						<span className={styles.filtersDropdown__selectedFiltersLabel}>{`${filteredByLabel}: `}</span>
						<span className={styles.filtersDropdown__appliedFilters}>{appliedFilters.join(', ')}</span>
					</div>
					<div className={styles.filtersDropdown__selectedFiltersTrigger}>
						<FilterIcon height={18} width={18} />
					</div>
				</div>
			) : (
				<div
					className={[styles.filtersDropdown, isDropdownOpen ? styles.filtersDropdown_expanded : ''].join(' ')}
					onClick={toggleExpanded}
					onKeyDown={noop}
				>
					<div className={`${styles.filtersDropdown__triggerButton} ${styles.filtersDropdown__triggerButton_left}`}>
						{isDropdownOpen ? (
							<span
								className={styles.filtersDropdown__triggerLink}
								onClick={(e) => {
									e.stopPropagation();
									toggleExpanded();
									onCancel();
								}}
								onKeyDown={noop}
							>
								{cancelLabel}
							</span>
						) : (
							<FilterIcon height={18} width={18} />
						)}
					</div>
					<h3 className={styles.filtersDropdown__triggerTitle}>{title}</h3>
					<div className={`${styles.filtersDropdown__triggerButton} ${styles.filtersDropdown__triggerButton_right}`}>
						{isDropdownOpen ? (
							<span
								aria-disabled={applyDisabled}
								className={styles.filtersDropdown__triggerLink}
								data-disabled={applyDisabled}
								onClick={(e) => {
									e.stopPropagation();
									if (applyDisabled) return;
									toggleExpanded();
									onApply();
								}}
								onKeyDown={noop}
							>
								{applyLabel}
							</span>
						) : (
							<div className={styles.filtersDropdown__dropdownIcon}>
								<DropdownIcon fill="#000000" height={21} width={16} />
							</div>
						)}
					</div>
				</div>
			)}

			{isDropdownOpen && (
				<div className={[styles.filtersDropdown__dropdown, styles.filtersDropdown__dropdown_open].join(' ')}>
					{children}
				</div>
			)}
		</div>
	);
};

export interface IFiltersDropdownProps {
	title: string;
	cancelLabel: string;
	applyLabel: string;
	filteredByLabel: string;
	appliedFilters: string[];
	children: ReactNode;
	selectedTags?: string[];
	applyDisabled?: boolean;
	onCancel: () => void;
	onApply: () => void;
	isDropdownOpen: boolean;
	setIsDropdownOpen: (isOpen: boolean) => void;
}

export default FiltersDropdown;
