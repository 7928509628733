import { useService, usePaginatedInfiniteQueryV2 } from '@hooks';
import { dayjs, ORM } from '@utils';
import type { TTipWithDetails, TTipsFilter } from '@typings';

export const useAllTipsQuery = ({ categories, sort }: TTipsFilter) => {
	const reactQuery = useService('ReactQueryService');
	const api = useService('ApiService');

	return usePaginatedInfiniteQueryV2<TTipWithDetails>(
		reactQuery.queryKeys.allTips({ categories, sort }),
		async ({ pageParam = 1 }) => {
			const tags = categories.map((category) => category.id);
			const tips = await api.tips.getTips({
				page: pageParam,
				tags: categories.map((category) => category.id),
				orderBy: sort?.field,
				orderDirection: sort?.order,
			});
			const tipDetails = await api.tips.getTipsDetails({
				page: 1,
				tips: tips.map((tip) => tip.id),
				tags: tags,
				orderBy: sort?.field,
				orderDirection: sort?.order,
			});

			const tipWithDetails: TTipWithDetails[] = ORM.mapOneRelation(tips, tipDetails, {
				alias: 'details',
				parentColumn: 'id',
				childrenColumn: 'id',
			});

			return tipWithDetails;
		},
		{
			staleTime: dayjs.duration(5, 'minutes').asMilliseconds(),
			cacheTime: dayjs.duration(5, 'minutes').asMilliseconds(),
		},
	);
};
