import { useService, useInfiniteQuery, useDebounce, useMemo } from '@hooks';
import { dayjs, noop, ORM } from '@utils';
import { TipEntity } from '@ui-modules/tipJar/utils/TipEntity';
import type { TAlgoliaSearchResponse, TCommunity, TTipDetails, TTipMatch, TTipsFilter } from '@typings';
import type { UseInfiniteQueryOptions } from '@tanstack/react-query';

export const useSearchTipsQuery = (
	searchQuery = '',
	tipFilters?: Partial<TTipsFilter & { communityId?: TCommunity['id'] }>,
	options?: { hitsPerPage?: number },
	queryOptions?: UseInfiniteQueryOptions<TAlgoliaSearchResponse<TTipMatch>, Error>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const algolia = useService('AlgoliaSearchService');

	const [preparedSearchQuery] = useDebounce(searchQuery.trim(), 300);
	const isSearchEnabled = preparedSearchQuery.length >= 3;

	const infiniteQuery = useInfiniteQuery<TAlgoliaSearchResponse<TTipMatch>, Error>(
		reactQuery.queryKeys.searchTips(preparedSearchQuery, tipFilters),
		async ({ pageParam = 0 }) => {
			const searchTipsResults = await algolia.searchByTips({
				searchQuery,
				categories: tipFilters?.categories?.map?.((category) => category.title),
				page: pageParam,
				hitsPerPage: options?.hitsPerPage,
				communityId: tipFilters?.communityId,
			});
			const tipMatches = searchTipsResults.hits.map(TipEntity.mapSearchTipToTipMatch);
			const tipDetails = await api.tips.getTipsDetails({
				page: 1,
				tips: tipMatches.map((tip) => tip.id),
			});
			const tipMatchesWithDetails = ORM.mapOneRelation(tipMatches, tipDetails, {
				alias: 'details',
				parentColumn: 'id',
				childrenColumn: 'id',
				getDefaultValue: (parent) => parent.details as TTipDetails,
			});
			const tipMatchesResult: TAlgoliaSearchResponse<TTipMatch> = {
				...searchTipsResults,
				hits: tipMatchesWithDetails,
			};
			return tipMatchesResult;
		},
		{
			staleTime: dayjs.duration(10, 'seconds').asMilliseconds(),
			cacheTime: dayjs.duration(10, 'seconds').asMilliseconds(),
			keepPreviousData: true,
			...queryOptions,
			enabled: (queryOptions?.enabled ?? true) && isSearchEnabled,
			getNextPageParam: (lastPage) => {
				const isPagePresent = typeof lastPage.page === 'number';
				const maybeNextPage = lastPage.page + 1;
				const areMorePages = maybeNextPage < lastPage.nbPages;
				if (isPagePresent && areMorePages) return maybeNextPage;
				return undefined;
			},
		},
	);

	const data = useMemo(
		function normalizeData() {
			return infiniteQuery.data ? (infiniteQuery.data.pages.map((page) => page.hits).flat() as TTipMatch[]) : [];
		},
		[infiniteQuery.data],
	);
	const fetchMore = infiniteQuery.hasNextPage ? infiniteQuery.fetchNextPage : noop;

	return {
		preparedSearchQuery,
		isSearchEnabled,
		...infiniteQuery,
		data,
		fetchMore,
	};
};
