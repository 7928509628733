import { useMe, useTranslation } from '@hooks';
import styles from './EventNewTip.module.css';
import { Button, EditIcon } from '@ui-kit';
import { useNavigate } from 'react-router-dom';
import { useGetMeetingQuery } from '@ui-modules/events/hooks/useGetMeetingQuery';
import { useMemberMembershipQuery } from '@ui-modules/account/hooks/useMemberMembershipQuery';
import { ROUTES } from '@constants';
import { ProfileEntity } from '@ui-modules/profile/utils/ProfileEntity';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { clsx, MeetingEntity } from '@utils';

const EventNewTip = ({ eventId, isOutline = false }: IEventNewTipProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useMe();

	const { data: meeting } = useGetMeetingQuery(eventId);
	const { data: userMemberships = [] } = useMemberMembershipQuery();
	const { data: group } = useCommunityQuery(meeting?.groups?.[0]?.community?.id);

	if (
		!meeting ||
		!userMemberships ||
		!group ||
		!MeetingEntity.canAddTipV2(meeting, userMemberships, group, user) ||
		!ProfileEntity.isFeatureEnabled(user, 'tips')
	)
		return null;
	return (
		<Button
			className={clsx(!isOutline && styles.eventNewTip)}
			icon={isOutline && <EditIcon height={14} width={14} />}
			iconPosition="left"
			title={isOutline ? t('New') : t('New Tip')}
			type={isOutline ? 'outline' : 'clear'}
			onClick={() => navigate(ROUTES.newTip({ contextCalendarItemId: eventId, tags: [], galleryFiles: [] }))}
		/>
	);
};

export interface IEventNewTipProps {
	eventId: string;
	isOutline?: boolean;
}

export default EventNewTip;
