import PatchTipForm from '@ui-modules/tipJar/components/PatchTipForm';
import { TextArea } from '@ui-kit';
import type { IPatchTipFormMainProps } from '@ui-modules/tipJar/components/PatchTipForm';
import type { IPatchTipFormProps } from '../PatchTipForm/PatchTipForm';
import type { TPatchTipForm } from '@schemas';

const TextAreaEditTip = <T extends keyof TPatchTipForm>({
	onCancel,
	tipId,
	onTipPatched,
	propertyName,
	initialValue,
}: IInputEditTipProps<T>) => {
	return (
		<PatchTipForm
			initialValues={{ [propertyName]: initialValue } as unknown as TPatchTipForm}
			propertyName={propertyName}
			tipId={tipId}
			onCancel={onCancel}
			onTipPatched={onTipPatched}
		>
			{(formikProps) => (
				<TextArea
					errorMessage={formikProps.errors[propertyName] as string}
					value={formikProps.values[propertyName] as string}
					onChange={formikProps.handleChange('description')}
				/>
			)}
		</PatchTipForm>
	);
};

export interface IInputEditTipProps<T extends keyof TPatchTipForm>
	extends IPatchTipFormMainProps,
		Pick<IPatchTipFormProps<T>, 'propertyName'> {
	initialValue: TPatchTipForm[T];
	onCancel: () => void;
}

export default TextAreaEditTip;
