import { Button } from '@ui-kit';
import { useNotification, useTranslation, useDropzone } from '@hooks';
import { clsx } from '@utils';
import styles from './FilePicker.module.css';
import type { Accept } from 'react-dropzone';

const FilePicker = ({
	title,
	description,
	isLoading,
	onSelect,
	className,
	disabled,
	accept,
	invalid = false,
}: IFilePickerProps) => {
	const { showError } = useNotification();
	const { t } = useTranslation();

	const { getRootProps, isDragActive, getInputProps, open, isDragReject } = useDropzone({
		onDropRejected: () => showError({ title: 'Rejected', subtitle: 'Not allowed file type' }),
		onDrop: (acceptedFiles: File[]) => {
			if (acceptedFiles && acceptedFiles[0]) {
				onSelect(acceptedFiles[0]);
			}
		},
		noClick: true,
		multiple: false,
		accept,
	});

	return (
		<div
			className={clsx(
				styles.filePicker,
				isDragActive && styles.filePicker_dragActive,
				isDragReject && styles.filePicker_dragReject,
				invalid && styles.filePicker_invalid,
				className,
			)}
			{...getRootProps()}
		>
			<input {...getInputProps()} /> {/* hidden input to make file upload working */}
			<div className={styles.filePicker__infoBox}>
				<h4>{title}</h4>
				<span>{description}</span>
			</div>
			<Button disabled={disabled} loading={isLoading} title={t('Select A File')} type="blurred" onClick={open} />
		</div>
	);
};

export interface IFilePickerProps {
	title: string;
	description: string;
	accept: Accept;
	isLoading?: boolean;
	disabled?: boolean;
	invalid?: boolean;
	className?: string;
	onSelect: (file: File) => void;
}

export default FilePicker;
