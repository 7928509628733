import type { TDeal } from '@typings';
import { difference, isObject, forEach, uniq, toString } from '@utils';
import type { TFunction } from 'i18next';

type TField = {
	slug: string;
	type: string;
	label: string;
	value: string | string[];
};

const isArrayDifferent = (array1: string[], array2: string[]) => {
	const valueDifference = difference(array1, array2);
	return array1?.length !== array2?.length || valueDifference?.length;
};

function compareMultiSelectValues(label: string, value1: string[], value2: string[], changedFields: string[]) {
	if (isArrayDifferent(value1, value2)) {
		changedFields.push(label);
	}
}

function compareWebUrls(value1: string[], value2: string[], changedFields: string[], key: string) {
	const isLinkChanged =
		value1?.length !== value2?.length || value1?.some((link: string, index: number) => link !== value2[index]);
	if (isLinkChanged) {
		changedFields.push(key);
	}
}

function compareNestedObjects(value1: any, value2: any, changedFields: string[]) {
	if ((value1 as TField)?.type === 'multi-select' || (value2 as TField)?.type === 'multi-select') {
		compareMultiSelectValues(
			(value1 as TField)?.label,
			(value1 as TField)?.value as string[],
			(value2 as TField)?.value as string[],
			changedFields,
		);
	} else if ('value' in value1 && 'value' in value2) {
		if ((value1 as TField)?.value !== (value2 as TField)?.value) {
			changedFields.push((value1 as TField)?.label);
		}
	} else {
		compareFields(value1, value2, changedFields);
	}
}

function compareFields(field1: any, field2: any, changedFields: string[]) {
	forEach(field1, (value1, key) => {
		const value2 = field2[key as keyof TDeal];
		if (key === 'webUrls') {
			compareWebUrls(value1, value2, changedFields, key);
		} else if (isObject(value1) && isObject(value2)) {
			compareNestedObjects(value1, value2, changedFields);
		} else if (value1 !== value2) {
			// Compare the fields that are not nested with string values
			changedFields.push(key);
		}
	});
}

export const getChangedDealFields = (obj1: TDeal, obj2: TDeal) => {
	const changedFields: string[] = [];
	compareFields(getNecessaryFields(obj1), getNecessaryFields(obj2), changedFields);
	return uniq(changedFields);
};

export const DEAL_FIELD_TITLES = (t: TFunction) => ({
	name: t('Deal Title'),
	description: t('Deal Description'),
	minInvestment: t('Minimum Investment'),
	webUrls: t('Web Links'),
	videoUrl: t('Video URL'),
	closingDate: t('Closing Date'),
	currency: t('currency'),
	contactName: t('contact Name'),
	contactEmail: t('contact Email'),
	contactPhone: t('contact Phone'),
});

export const transformDealFieldName = (field: string) => {
	return toString(field)
		.split(/(?=[A-Z])/)
		.join(' ');
};

type TNecessaryFields = Pick<
	TDeal,
	| 'closingDate'
	| 'contactEmail'
	| 'contactName'
	| 'contactPhone'
	| 'currency'
	| 'data'
	| 'description'
	| 'minInvestment'
	| 'videoUrl'
	| 'webUrls'
>;

// Get only compared deal fields to optimize comparing.
const getNecessaryFields = (object: TDeal) => {
	const {
		name,
		closingDate,
		contactEmail,
		contactName,
		contactPhone,
		currency,
		data,
		description,
		minInvestment,
		videoUrl,
		webUrls,
	} = object;
	return {
		name,
		closingDate,
		contactEmail,
		contactName,
		contactPhone,
		currency,
		data,
		description,
		minInvestment,
		videoUrl,
		webUrls,
	} as TNecessaryFields;
};
