import { useQuery } from '@tanstack/react-query';
import { useService } from '@services';
import { useMe } from '@hooks';
import { ORM } from '@utils';
import { ProfileEntity } from '@ui-modules/profile/utils/ProfileEntity';
import type { TCommunity, TTipWithDetails } from '@typings';

export const useMeetingTipsQuery = (calendarItemId: TCommunity['id'] | undefined) => {
	const reactQuery = useService('ReactQueryService');
	const api = useService('ApiService');
	const { user } = useMe();

	return useQuery<TTipWithDetails[]>(
		reactQuery.queryKeys.meetingTips(calendarItemId),
		async () => {
			const tips = await api.tips.getTips({
				page: 1,
				calendarItems: calendarItemId ? [calendarItemId] : undefined,
				orderBy: 'reviewCreatedAt',
				orderDirection: 'desc',
			});
			const tipDetails = await api.tips.getTipsDetails({
				page: 1,
				calendarItems: calendarItemId ? [calendarItemId] : undefined,
				orderBy: 'reviewCreatedAt',
				orderDirection: 'desc',
				tips: tips.map((tip) => tip.id),
			});

			const tipWithDetails: TTipWithDetails[] = ORM.mapOneRelation(tips, tipDetails, {
				alias: 'details',
				parentColumn: 'id',
				childrenColumn: 'id',
			});

			return tipWithDetails;
		},
		{
			enabled: ProfileEntity.isFeatureEnabled(user, 'tips'),
		},
	);
};
