import { Navigate } from '@ui-kit';
import { useMe } from '@hooks';
import { ProfileEntity } from '@ui-modules/profile/utils/ProfileEntity';
import { ROUTES } from '@constants';
import type { TUserFeatures } from '@typings';
import type { PropsWithChildren } from 'react';

const CheckUserFeatureWrapper = ({
	children,
	featureName,
	goHomeIfDisabled = false,
}: PropsWithChildren<{ featureName: keyof TUserFeatures; goHomeIfDisabled?: boolean }>) => {
	const { user } = useMe();

	if (!ProfileEntity.isFeatureEnabled(user, featureName)) {
		if (goHomeIfDisabled) return <Navigate replace to={ROUTES.home()} />;
		else return null;
	}

	return <>{children}</>;
};

export default CheckUserFeatureWrapper;
