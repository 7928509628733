import { ChevronRightIcon } from '@ui-kit';
import styles from './ActionListItem.module.css';
import { noop } from '@utils';
import type { TTip } from '@typings';
import type { ReactNode } from 'react';

const ActionListItem = ({ title, note, Icon, onClick }: IActionListItemProps) => {
	return (
		<div className={styles.actionListItem} onClick={onClick} onKeyDown={noop}>
			<div className={styles.actionListItem__imageContainer}>{Icon}</div>
			<div className={styles.actionListItem__mainContainer}>
				<span className={styles.actionListItem__note}>{note}</span>
				<span className={styles.actionListItem__title}>{title}</span>
			</div>
			<div className={styles.actionListItem__chevronIconContainer}>
				<ChevronRightIcon fill="var(--color-primaryGrey)" height={16} width={10} onClick={onClick} />
			</div>
		</div>
	);
};
export type TTipMatch = Omit<TTip, 'owner'>;

export interface IActionListItemProps {
	title: string;
	note?: string;
	Icon: ReactNode;
	/** Callback to handle card press */
	onClick?: () => void;
}

export default ActionListItem;
