import { InternalShareEntity } from '@utils';
import { useFeedActivityQuery } from '@ui-modules/feed/hooks/useFeedActivityQuery';
import type { IInternalShare, TDeal } from '@typings';
import { useGetDealQuery } from '@ui-modules/deals/hooks/useGetDealQuery';
import { useGetMeetingQuery } from '@ui-modules/events/hooks/useGetMeetingQuery';
import { useGetEventQuery } from '@ui-modules/events/hooks/useGetEventQuery';
import { useNetworkConnectionQuery } from '@ui-modules/connections/hooks/useNetworkConnectionQuery';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';

export const useSharedContent = (
	sharedContent: string | null | undefined,
): { data: IInternalShare; isLoading: boolean; isError: boolean } => {
	const internalShareEntity = new InternalShareEntity();

	const orientedShare = internalShareEntity.internalSharedContentOrienteering(sharedContent);

	const {
		data: activity,
		isLoading: isActivityLoading,
		isError: isFeedActivityError,
	} = useFeedActivityQuery(orientedShare?.id, undefined, {
		enabled: orientedShare?.type === 'discussion',
	});
	const {
		data: community,
		isLoading: isCommunityLoading,
		isError: isCommunityError,
	} = useCommunityQuery(orientedShare?.id, {
		enabled:
			orientedShare?.type === 'chapter' ||
			orientedShare?.type === 'group' ||
			orientedShare?.type === 'network' ||
			orientedShare?.type === 'deal' ||
			orientedShare?.type === 'network_connection',
	});

	const {
		data: networkConnection,
		isLoading: isNetworkConnectionLoading,
		isError: isNetworkConnectionError,
	} = useNetworkConnectionQuery(orientedShare?.id as string, {
		enabled: orientedShare?.type === 'network_connection_post',
	});

	const {
		data: deal,
		isLoading: isDealLoading,
		isError: isDealError,
	} = useGetDealQuery(orientedShare?.id as TDeal['id'], {
		enabled: orientedShare?.type === 'deal_post',
	});

	const {
		data: meeting,
		isLoading: isMeetingLoading,
		isError: isMeetingError,
	} = useGetMeetingQuery(orientedShare?.id as string, undefined, {
		enabled: orientedShare?.type === 'meeting',
	});

	const {
		data: event,
		isLoading: isEventLoading,

		isError: isEventError,
	} = useGetEventQuery(orientedShare?.id, undefined, {
		enabled: orientedShare?.type === 'event' || orientedShare?.type === 'meeting',
	});
	const data = internalShareEntity.constructSharedContentData(
		{ event, meeting, deal, networkConnection, community, activity },
		orientedShare,
	);
	return {
		data,
		isLoading:
			isActivityLoading &&
			isCommunityLoading &&
			isDealLoading &&
			isNetworkConnectionLoading &&
			isMeetingLoading &&
			isEventLoading,
		isError:
			isFeedActivityError ||
			isCommunityError ||
			isNetworkConnectionError ||
			isDealError ||
			isMeetingError ||
			isEventError,
	};
};
