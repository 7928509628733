import { InputLabel, TagRows, InputErrorMessage } from '@ui-kit';
import { TipEntity } from '@ui-modules/tipJar/utils/TipEntity';
import { toString } from '@utils';
import type { TTipTag } from '@typings';
import { useMemo } from 'react';

const TipCategoriesInput = ({
	label,
	value = [],
	tipCategories = [],
	errorMessage,
	onChange,
}: ITipCategoriesInputProps) => {
	const selectedTags = useMemo(() => value.map((category) => category.title), [value]);
	const tags = useMemo(() => tipCategories.map((category) => category.title), [tipCategories]);

	const toggleCategory = (togglingTag: string) => {
		const isSelected = !!value.find((category) => category.title === togglingTag);
		if (isSelected) {
			onChange(value.filter((category) => category.title !== togglingTag));
		} else {
			const togglingCategory = tipCategories.find((category) => category.title === togglingTag);
			if (!togglingCategory) return;
			onChange([togglingCategory, ...value].slice(0, TipEntity.categoriesLimit));
		}
	};

	return (
		<div>
			{label ? <InputLabel text={label} /> : null}
			<TagRows mode="row" selectedTags={selectedTags} tags={tags} onSelect={toggleCategory} />
			<InputErrorMessage text={toString(errorMessage)} />
		</div>
	);
};

export interface ITipCategoriesInputProps {
	label?: string;
	value: TTipTag[];
	tipCategories: TTipTag[];
	errorMessage?: string | string[];
	onChange: (value: TTipTag[]) => void;
}

export default TipCategoriesInput;
