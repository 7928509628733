import { useService, useMutation, useNotification, useMe } from '@hooks';
import type { TTip } from '@typings';
import type { MutateOptions } from '@tanstack/react-query';
import type { TTipReviewWithSharingForm } from '@schemas';

export const useSendTipReviewMutation = (options?: MutateOptions<void, Error, TSendTipMutationPayload>) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { user } = useMe();
	const { showUnknownError } = useNotification();

	return useMutation<void, Error, TSendTipMutationPayload>(
		['sendTipReview'],
		async ({ tipId, ...tipReviewForm }) => {
			await api.tips.postTipReview(tipId, user.id, tipReviewForm);
		},
		{
			...options,
			onSuccess: (response, variables, context) => {
				reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.tipReviews(variables.tipId));
				reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.tip(variables.tipId));
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.allTips());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.myTips());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.groupsTips());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.communityTips());
				options?.onSuccess?.(response, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};

type TSendTipMutationPayload = TTipReviewWithSharingForm & { tipId: TTip['id'] };
