import { Page, TabWrapper } from '../../components';
import {
	useNavigate,
	useParams,
	useRouteSearchQueryState,
	useToggle,
	useTranslation,
	useState,
	useEffect,
	useLocation,
} from '@hooks';
import styles from './TipJarPage.module.css';
import { clsx } from '@utils';
import { Button, EditIcon, SearchableCompactScreenHeader, Tabs } from '@ui-kit';
import { ROUTES } from '@constants';
import type { TTipsTabName } from '@typings';
import TipJarIntroModal from '@ui-modules/tipJar/components/TipJarIntroModal';
import MyTipsPage from '../MyTipsPage';
import AllTipsPage from '../AllTipsPage';
import TipFiltersDropdown from '@ui-modules/tipJar/components/TipFiltersDropdown';
import TipSearchResultList from '@ui-modules/tipJar/components/TipSearchResultList';
import { useTipCategoriesSearchParams } from '@ui-modules/tipJar/hooks/useTipCategorySearchParams';
import GroupTipsPage from '../GroupTipsPage/GroupTipsPage';

const TipJarPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchQuery, setSearchQuery] = useRouteSearchQueryState();
	const [isSearchActive, setIsSearchActive] = useToggle(false);
	const [currentTabIndex, setCurrentTabIndex] = useState(0, 'TipJarPage:currentTabIndex');
	const { tipsTabName } = useParams();
	const tipTabs: TTipsTabName[] = ['all', 'my', 'group'];
	const currentTabFromRoute = tipTabs.findIndex((tab) => tab === tipsTabName) || 0;

	const { tipCategories, selectedTipCategories, handleApplyCategories } = useTipCategoriesSearchParams();
	const handleChangeTab = (index: number) => {
		setCurrentTabIndex(index);
		const nextFeedName = tipTabs[index];
		const queryParams = new URLSearchParams(location.search);
		queryParams.set('sort', 'reviewCreatedAt.desc');
		navigate(ROUTES.tipJarTab(nextFeedName, `?${queryParams.toString()}`));
	};

	useEffect(() => {
		setCurrentTabIndex(currentTabFromRoute);
	}, [currentTabFromRoute]);

	return (
		<Page title={t('Tips')}>
			<TipJarIntroModal />
			<div className={styles.tipJarPage}>
				<div className={clsx(styles.tipJarPage__header)}>
					<SearchableCompactScreenHeader
						customButtonsElement={
							<Button
								icon={<EditIcon height={14} width={14} />}
								title={t('New')}
								type="outline"
								variant="small"
								onClick={() => navigate(ROUTES.newTip())}
							/>
						}
						placeholder={t('Search tips')}
						searchQuery={searchQuery}
						searchTitle={t('Search')}
						title={t('Tips')}
						titleAs="h3"
						onChangeSearchQuery={setSearchQuery}
						onFocus={() => setIsSearchActive(true)}
						onSearchCanceled={() => {
							setSearchQuery('');
							setIsSearchActive(false);
						}}
					/>
				</div>
				{tipCategories ? (
					<div className={styles.tipJarPage__headerFilter}>
						<TipFiltersDropdown
							initialSelectedCategories={selectedTipCategories}
							tipCategories={tipCategories}
							onApply={handleApplyCategories}
						/>
					</div>
				) : null}
				{searchQuery.length ? (
					<div className={styles.tipJarPage__tipSearchResultListBox}>
						<TipSearchResultList searchQuery={searchQuery} tipFilters={{ categories: selectedTipCategories }} />
					</div>
				) : (
					<Tabs selectedIndex={currentTabIndex} onSelect={handleChangeTab}>
						<Tabs.List hidden={isSearchActive}>
							{tipTabs.map((tab) => {
								switch (tab) {
									case 'all':
										return <Tabs.Tab key="all">{t('All Tips')}</Tabs.Tab>;
									case 'my':
										return <Tabs.Tab key="my">{t('My Tips')}</Tabs.Tab>;
									case 'group':
										return <Tabs.Tab key="group">{t('Group Tips')}</Tabs.Tab>;
								}
							})}
						</Tabs.List>
						{tipTabs.map((tab) => {
							switch (tab) {
								case 'all':
									return (
										<Tabs.Panel key="all">
											<TabWrapper>
												<AllTipsPage selectedTipCategories={selectedTipCategories} sortHidden={isSearchActive} />
											</TabWrapper>
										</Tabs.Panel>
									);
								case 'my':
									return (
										<Tabs.Panel key="my">
											<TabWrapper>
												<MyTipsPage selectedTipCategories={selectedTipCategories} />
											</TabWrapper>
										</Tabs.Panel>
									);
								case 'group':
									return (
										<Tabs.Panel key="group">
											<TabWrapper>
												<GroupTipsPage selectedTipCategories={selectedTipCategories} />
											</TabWrapper>
										</Tabs.Panel>
									);
							}
						})}
					</Tabs>
				)}
			</div>
		</Page>
	);
};

export default TipJarPage;
