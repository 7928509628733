import { Page } from '../../components';
import { useRestoreVirtuosoInitialIndex, useTranslation } from '@hooks';
import { ActivityIndicator, Gap, When } from '@ui-kit';
import TipList from '@ui-modules/tipJar/components/TipList';
import { useAllTipsQuery } from '@ui-modules/tipJar/hooks/useAllTipsQuery';
import TipsSortWidget from '@ui-modules/tipJar/components/TipsSortWidget';
import { useTipCategoriesSearchParams } from '@ui-modules/tipJar/hooks/useTipCategorySearchParams';
import type { TTipTag } from '@typings';

const AllTipsPage = ({ selectedTipCategories, sortHidden = false }: IAllTipsPageParams) => {
	const { t } = useTranslation();
	const { selectedSortOption, sortOptions, setSelectedSortOption } = useTipCategoriesSearchParams();

	const {
		data: tips,
		isLoading: isTipsLoading,
		fetchMore,
		isFetchingNextPage,
	} = useAllTipsQuery({ categories: selectedTipCategories, sort: selectedSortOption });

	const RESTORE_KEY = `allTips-${JSON.stringify({ selectedSortOption, selectedTipCategories })}`;
	const { initialTopMostItemIndex, onRangeChanged } = useRestoreVirtuosoInitialIndex(RESTORE_KEY);

	return (
		<Page title={t('My Tips Page')}>
			<>
				<When condition={!sortHidden}>
					<Gap gap={16} />
					<TipsSortWidget
						selectedOption={selectedSortOption}
						sortOptions={sortOptions}
						title={t('Sort By')}
						onSelect={setSelectedSortOption}
					/>
					<Gap gap={16} />
				</When>
				{isTipsLoading ? (
					<ActivityIndicator size="medium" type="fit" />
				) : (
					<TipList
						data={tips}
						endReached={fetchMore}
						initialTopMostItemIndex={initialTopMostItemIndex}
						isFetchingNextPage={isFetchingNextPage}
						isLoading={isTipsLoading}
						rangeChanged={onRangeChanged}
					/>
				)}
			</>
		</Page>
	);
};

interface IAllTipsPageParams {
	selectedTipCategories: TTipTag[];
	sortHidden?: boolean;
}

export default AllTipsPage;
