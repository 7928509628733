import React from 'react';
import { useGroupTipsQuery } from '@ui-modules/tipJar/hooks/useGroupTipsQuery';
import { ActivityIndicator, Gap } from '@ui-kit';
import type { TTipTag } from '@typings';
import { useTranslation } from 'react-i18next';
import { useRestoreVirtuosoInitialIndex } from '@hooks';
import { Page } from '../../components';
import TipList from '@ui-modules/tipJar/components/TipList';

const GroupTipsPage = ({ selectedTipCategories }: IGroupTipsPage) => {
	const { t } = useTranslation();
	const RESTORE_KEY = `groupTips-${JSON.stringify({ selectedTipCategories })}`;
	const { initialTopMostItemIndex, onRangeChanged } = useRestoreVirtuosoInitialIndex(RESTORE_KEY);
	const {
		data: groupTipsData = [],
		isLoading,
		fetchMore,
		isFetchingNextPage,
	} = useGroupTipsQuery({ categories: selectedTipCategories });

	return (
		<Page title={t('Group Tips Page')}>
			<>
				<Gap gap={16} />
				{isLoading ? (
					<ActivityIndicator size="medium" type="fit" />
				) : (
					<TipList
						data={groupTipsData}
						endReached={fetchMore}
						initialTopMostItemIndex={initialTopMostItemIndex}
						isFetchingNextPage={isFetchingNextPage}
						isLoading={isLoading}
						rangeChanged={onRangeChanged}
						tipContext={{ contextMyCommunities: true }}
					/>
				)}
			</>
		</Page>
	);
};

interface IGroupTipsPage {
	selectedTipCategories: TTipTag[];
}

export default GroupTipsPage;
