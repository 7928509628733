import { useMemo, useLayoutEffect, useState } from '@hooks';
import type { TTip } from '@typings';

/** Determines proper cover tip image index for the image gallery and allows to store changed index */
export const useTipImageIndexState = (tip?: TTip) => {
	const initialCoverImageIndex = useMemo(() => {
		if (!tip) return null;

		const coverUrl = tip.coverUrl;
		if (!coverUrl) return 0;

		const coverImageIndex = tip?.galleryFiles.findIndex((image) => image.documentUrl === coverUrl);
		return Math.max(coverImageIndex, 0);
	}, [tip?.coverUrl]);

	const [coverImageIndex, setCoverImageIndex] = useState(initialCoverImageIndex);

	useLayoutEffect(() => {
		setCoverImageIndex(initialCoverImageIndex);
	}, [initialCoverImageIndex]);

	return [coverImageIndex, setCoverImageIndex, initialCoverImageIndex] as const;
};
