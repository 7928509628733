import { useQuery } from '@hooks';
import { dayjs } from '@utils';
import type { UseQueryOptions } from '@tanstack/react-query';

/** Performs request to external resource to acquire link data. Can be used to render preview of a link. */
export const useLinkPreviewQuery = (link: string, options?: UseQueryOptions<TLinkPreview, Error>) => {
	const baseUrl = location.hostname === 'localhost' ? 'https://dev.connect.tiger21.com' : '';

	return useQuery<TLinkPreview, Error>(
		['link-preview', link],
		async () => {
			const response = await fetch(`${baseUrl}/link-preview-v2/?url=${link}`);
			const linkData = await response.json();
			const domain = new URL(link).origin;
			const linkPreview: TLinkPreview = {
				...linkData,
				domain,
			};
			return linkPreview;
		},
		{
			staleTime: dayjs.duration(1, 'day').asMilliseconds(),
			...options,
		},
	);
};

export type TLinkPreview = TLinkPreviewSuccess | TLinkPreviewError;

type TLinkPreviewError = {
	error: string;
};

type TLinkPreviewSuccess = {
	title: string; // e.g. "siddhigate - Overview",
	description: string; // e.g. "Full stack developer. siddhigate has 49 repositories available. Follow their code on GitHub.",
	banner: string; // e.g.  "https://avatars.githubusercontent.com/u/80971056?v=4?s=400",
	error?: undefined;
	domain: string;
};
