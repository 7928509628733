import { Formik } from '@ui-kit';
import TipCategoriesInput from '@ui-modules/tipJar/components/TipCategoriesInput';
import TipFormsSubmitButton from '@ui-modules/tipJar/components/TipFormsSubmitButton';
import { useTranslation } from '@hooks';
import styles from './NewTipCategoriesForm.module.css';
import { toString } from '@utils';
import type { FormikProps } from 'formik';
import type { ReactNode } from 'react';
import { patchTipFormSchema, type TPatchTipForm } from '@schemas';
import type { TTipTag } from '@tiger21-llc/connect-shared/src/typings/entities/Tip.type';

const NewTipCategoriesForm = ({
	initialValues,
	categories = [],
	onSubmit,
	renderWrapper = ({ children }) => children,
}: INewTipCategoriesFormProps) => {
	const { t } = useTranslation();

	return (
		<Formik<TPatchTipForm>
			initialValues={initialValues as TPatchTipForm}
			validateOnBlur
			validateOnChange
			validateOnMount
			validationSchema={patchTipFormSchema}
			onSubmit={onSubmit}
		>
			{(formProps) => {
				const { values, setFieldValue, submitForm, handleBlur, errors, isSubmitting, isValid } = formProps;
				return renderWrapper({
					formProps,
					children: (
						<div className={styles.newTipCategoriesForm}>
							<TipCategoriesInput
								errorMessage={toString(errors.tags)}
								tipCategories={categories}
								value={values.tags ?? []}
								onChange={(newCategories) => {
									setFieldValue('tags', newCategories);
									handleBlur('tags');
								}}
							/>
							<TipFormsSubmitButton disabled={!isValid} loading={isSubmitting} title={t('Next')} onClick={submitForm} />
						</div>
					),
				});
			}}
		</Formik>
	);
};

export interface INewTipCategoriesFormProps {
	/** Initial form values. */
	initialValues: Partial<TPatchTipForm>;
	/** Form submit handler. */
	onSubmit: (values: TPatchTipForm) => Promise<unknown>;
	/** Categories to display in the form. */
	categories: TTipTag[];
	/** Optional renderer to wrap form content and pass formProps to a wrapper React component. */
	renderWrapper?: ({
		formProps,
		children,
	}: {
		formProps: FormikProps<TPatchTipForm>;
		children: ReactNode;
	}) => ReactNode;
}

export default NewTipCategoriesForm;
