import { useQuery, useService } from '@hooks';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TCommunity } from '@typings';
import { dayjs } from '@utils';

export const useCommunityQuery = (
	communityId: TCommunity['id'] | undefined,
	options?: UseQueryOptions<TCommunity, Error, TCommunity>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<TCommunity, Error, TCommunity>(
		reactQuery.queryKeys.getCommunity(String(communityId)),
		async () => api.community.getCommunity(communityId as TCommunity['id']),
		{
			enabled: !!communityId,
			staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
			...options,
		},
	);
};
