import { useService, useQuery, useMe } from '@hooks';
import { dayjs } from '@utils';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TTip } from '@tiger21-llc/connect-shared/src/typings/entities/Tip.type';
import type { TTipReview } from '@typings';

export const useMyTipReviewQuery = <T = TTipReview | null>(
	tipId: TTip['id'] | undefined,
	queryOptions?: UseQueryOptions<TTipReview | null, Error, T>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { user } = useMe();

	return useQuery<TTipReview | null, Error, T>(
		reactQuery.queryKeys.tipMyReview(tipId as TTip['id']),
		async () => {
			if (!tipId) throw new Error('Tip ID is required');
			const tipReviews = await api.tips.getTipReviews(tipId, { ownerId: user.id });
			const myTipReview = tipReviews[0] ?? null;
			return myTipReview;
		},
		{
			enabled: !!tipId,
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			...queryOptions,
		},
	);
};
