import { FormPageLayout, Page } from '../../components';
import { useTranslation } from '@hooks';
import styles from './EditContactPage.module.css';
import type { TEditContactCard } from '@schemas';
import { editContactCardSchema } from '@schemas';
import { Avatar, Formik } from '@ui-kit';
import { useUpdateMyContactCardMutation } from '@ui-modules/contacts/hooks/useUpdateMyContactCardMutation';
import UserNameFields from '@ui-modules/contacts/components/UserNameFields';
import PhonesForm from '@ui-modules/contacts/components/PhonesForm';
import EmailsForm from '@ui-modules/contacts/components/EmailsForm';
import AddressesForm from '@ui-modules/contacts/components/AddressesForm';
import { ROUTES } from '@constants';
import type { FormikProps } from 'formik';
import { scrollToFirstError } from '@utils';
// import { useValidateAddressComponents } from '@ui-modules/contacts/hooks/useAddressValidation';

const ERROR_FIELD_SELECTOR = `.fieldErrorMessage__active`;

const EditContactPageForm = ({ formProps, avatarUri, userTitle, avatarOutline }: IEditContactPageFormProps) => {
	const { t } = useTranslation();
	// const validateAddressComponents = useValidateAddressComponents(formProps);

	return (
		<FormPageLayout
			allowedNextPaths={[ROUTES.editContact()]}
			formProps={formProps}
			headerTitle={t('Contact Info')}
			saveTrackingName="contacts--edit-contacts"
			onSaveFailed={() => scrollToFirstError(ERROR_FIELD_SELECTOR)}
			// onSaveHandler={() => validateAddressComponents()}
		>
			<div className={styles.contact}>
				<div className={styles.contact__avatar}>
					<Avatar imageUri={avatarUri} outline={avatarOutline} size={80} title={userTitle} />
				</div>
				<UserNameFields formProps={formProps} />
				<PhonesForm formProps={formProps} />
				<EmailsForm formProps={formProps} />
				<AddressesForm formProps={formProps} />
			</div>
		</FormPageLayout>
	);
};

const EditContactPage = () => {
	const { t } = useTranslation();
	const { initialValues, user, submit } = useUpdateMyContactCardMutation();

	return (
		<Page title={t('Edit Contact')}>
			<Formik<TEditContactCard>
				enableReinitialize
				initialValues={editContactCardSchema.cast(initialValues) as TEditContactCard}
				validateOnBlur={false}
				validateOnChange={false}
				validateOnMount
				validationSchema={editContactCardSchema}
				onSubmit={(values) => submit(editContactCardSchema.cast(values) as TEditContactCard)}
			>
				{(formProps) => (
					<EditContactPageForm
						avatarOutline={user.roles.includes('ROLE_CHAIR')}
						avatarUri={user.avatar?.contentUrl}
						formProps={formProps}
						userTitle={user.firstName[0] + user.lastName[0]}
					/>
				)}
			</Formik>
		</Page>
	);
};

interface IEditContactPageFormProps {
	formProps: FormikProps<TEditContactCard>;
	userTitle: string;
	avatarUri?: string;
	avatarOutline: boolean;
}

export default EditContactPage;
