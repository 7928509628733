import { useQuery, useService } from '@hooks';
import { dayjs } from '@utils';
import type { AttachedMeetingsForTipCreation } from '@typings';
import type { TTipReview } from '@tiger21-llc/connect-shared/src/typings/entities/TipReview.type';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useAttachedMeetingsForTipCreationQuery = <T = AttachedMeetingsForTipCreation[]>(
	tipReviewId: TTipReview['id'],
	options?: UseQueryOptions<AttachedMeetingsForTipCreation[], Error, T>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<AttachedMeetingsForTipCreation[], Error, T>(
		reactQuery.queryKeys.attachedMeetingsForTipCreation(tipReviewId),
		async () => {
			return await api.tips.getAttachedMeetingsForTipCreation(tipReviewId);
		},
		{
			...options,
			enabled: Boolean(tipReviewId),
			staleTime: dayjs.duration(1, 'minute').asMilliseconds(),
		},
	);
};
