import { useService, usePaginatedInfiniteQueryV2, useMemo } from '@hooks';
import { dayjs, ORM } from '@utils';
import { TipReviewEntity } from '@ui-modules/tipJar/utils/TipReviewEntity';
import { useFetchMyGroupAndChapterCommunityIds } from '@ui-modules/account/hooks/useMemberMembershipQuery';
import type { TTip, TTipContext, TTipReviewWithReaction } from '@typings';
import { useOwnerTipReviewQuery } from './useOwnerTipReviewQuery';
import { some, compact } from '@utils';

export const useTipReviewsQuery = (
	tipId: TTip['id'],
	tipContext?: TTipContext,
	forceReturningCreatorReview?: boolean,
) => {
	const reactQuery = useService('ReactQueryService');
	const api = useService('ApiService');

	const creatorReviewShown = !some(tipContext, Boolean);
	const ownerTipReviewQuery = useOwnerTipReviewQuery(tipId, tipContext);
	const ownerTipReviewId = ownerTipReviewQuery.data?.id;
	const fetchMyGroupAndChapterCommunityIds = useFetchMyGroupAndChapterCommunityIds();

	const query = usePaginatedInfiniteQueryV2<TTipReviewWithReaction>(
		reactQuery.queryKeys.tipReviews(tipId, tipContext),
		async ({ pageParam = 1 }) => {
			const tipReview = await api.tips.getTipReviews(tipId, {
				page: pageParam,
				communities: compact([
					tipContext?.contextCommunityId,
					...(tipContext?.contextMyCommunities ? await fetchMyGroupAndChapterCommunityIds() : []),
				]),
				calendarItems: tipContext?.contextCalendarItemId ? [tipContext.contextCalendarItemId] : undefined,
				orderDirection: 'desc',
			});
			const tipReviewIds = tipReview.map((review) => review.id);
			const tipReviewReactions = await api.tips.getTipReviewsAggregateReactions(tipReviewIds).catch(() => []);
			const tipReviewWithReaction: TTipReviewWithReaction[] = ORM.mapOneRelation(tipReview, tipReviewReactions, {
				alias: 'reactions',
				parentColumn: 'id',
				childrenColumn: 'targetId',
				getDefaultValue: (tipReview) => TipReviewEntity.getEmptyTipReviewReaction(tipReview.id),
			});
			return tipReviewWithReaction;
		},
		{
			staleTime: dayjs.duration(5, 'minutes').asMilliseconds(),
			cacheTime: dayjs.duration(5, 'minutes').asMilliseconds(),
		},
	);

	const data = useMemo(() => {
		if (!creatorReviewShown || forceReturningCreatorReview) return query?.data;
		if (!ownerTipReviewId || !query?.data) return [];
		return query.data.filter((tipReview) => tipReview.id !== ownerTipReviewId);
	}, [query?.data, ownerTipReviewId, creatorReviewShown]);

	return { ...query, data };
};
