import { memo } from 'react';
import { Formik, StarsRating, CheckboxInput, InputLabel, TextArea, FieldArray, When, QuestionTooltip } from '@ui-kit';
import TipFormsSubmitButton from '@ui-modules/tipJar/components/TipFormsSubmitButton';
import { useTranslation } from '@hooks';
import { tipReviewWithSharingFormSchema } from '@schemas';
import { TipReviewEntity } from '@ui-modules/tipJar/utils/TipReviewEntity';
import { dayjs, clsx } from '@utils';
import styles from './TipReviewForm.module.css';
import type { TTipReviewWithSharingForm } from '@schemas';
import type { FormikProps } from 'formik';
import type { ReactNode } from 'react';
import type { TGroupMeetingsForTipCreation } from '@typings';

const TipReviewForm = ({
	title,
	submitLabel,
	initialValues,
	targetMeetings = [],
	submitButtonDisabled,
	secondaryFieldLabelsBold,
	ignoreDirty = false,
	onSubmit,
	editing,
	renderWrapper = ({ children }) => children,
}: ITipReviewFormProps) => {
	return (
		<Formik<TTipReviewWithSharingForm>
			initialValues={tipReviewWithSharingFormSchema.cast(initialValues) as TTipReviewWithSharingForm}
			validateOnBlur
			validateOnChange={false}
			validationSchema={tipReviewWithSharingFormSchema}
			onSubmit={onSubmit}
		>
			{(formProps) => {
				const { isSubmitting, handleSubmit } = formProps;
				const isValid = tipReviewWithSharingFormSchema.isValidSync(formProps.values);

				return renderWrapper({
					formProps,
					children: (
						<div className={styles.tipReviewForm}>
							<TipReviewFormInputs
								editing={editing}
								formikProps={formProps}
								secondaryFieldLabelsBold={secondaryFieldLabelsBold}
								targetMeetings={targetMeetings}
								title={title}
							/>

							<TipFormsSubmitButton
								disabled={!isValid || (!ignoreDirty && !formProps.dirty) || submitButtonDisabled}
								loading={isSubmitting}
								title={submitLabel}
								onClick={() => handleSubmit()}
							/>
						</div>
					),
				});
			}}
		</Formik>
	);
};

export const TipReviewFormInputs = ({
	editing,
	title,
	formikProps,
	targetMeetings,
	secondaryFieldLabelsBold,
}: ITipReviewFormInputsProps) => {
	const { values, handleChange, setFieldValue, handleBlur } = formikProps;
	const { t } = useTranslation();

	return (
		<div className={styles.tipReviewForm}>
			<h3>{title}</h3>

			<div>
				<span>{t('Rate this Tip')}</span>
				<StarsRating
					iconSize={32}
					rating={values.rate}
					onClick={(newRating) => {
						setFieldValue('rate', newRating);
						handleBlur('rate');
					}}
				/>
			</div>

			<div>
				<TextArea
					charactersLimit={TipReviewEntity.maxReviewLength}
					errorMessage={formikProps.errors.description}
					label={t('Add your Review')}
					maxLength={TipReviewEntity.maxReviewLength}
					maxRows={8}
					minRows={5}
					prePopulatedText={
						editing ? t('You can edit your previous review on this tip, but you can only have one review per tip.') : ''
					}
					showCounter
					value={values.description}
					onBlur={handleBlur('description')}
					onChange={handleChange('description')}
				/>
			</div>

			<div className={styles.tipReviewForm__inputField}>
				<div className={styles.tipReviewForm__questionFieldWrapper}>
					<InputLabel
						className={clsx(secondaryFieldLabelsBold && styles.tipReviewForm__inputFieldLabel_bold)}
						text={t('Do you have a vested interest?')}
					/>
					<QuestionTooltip content={t('Is this something that you have invested in or are an owner of?')} />
				</div>
				<CheckboxInput
					label={t('Yes')}
					value={values.vestedInterest}
					onChange={() => {
						setFieldValue('vestedInterest', !values.vestedInterest);
						handleBlur('vestedInterest');
					}}
				/>
			</div>

			{targetMeetings === null ? null : (
				<div className={styles.tipReviewForm__inputField}>
					<InputLabel
						className={clsx(secondaryFieldLabelsBold && styles.tipReviewForm__inputFieldLabel_bold)}
						text={t('Post to...')}
					/>
					<div className={styles.tipReviewForm__inputFieldDescription}>
						{t('(Tips posted to Group Meetings are automatically made visible to all Members after the meeting.)')}
					</div>
					<When condition={!!targetMeetings.length}>
						<FieldArray name="calendarItemsId">
							{() =>
								targetMeetings.map((meeting) => {
									const meetingId = meeting.id;
									const date = dayjs(meeting.startDate).format('D MMM');
									const toggleMeeting = () => {
										const isAlreadySelected = values.calendarItemsId.includes(meetingId);
										setFieldValue(
											'calendarItemsId',
											isAlreadySelected
												? values.calendarItemsId.filter((id) => id !== meetingId)
												: [...values.calendarItemsId, meetingId],
										);
										handleBlur('calendarItemsId');
									};
									return (
										<CheckboxInput
											key={meeting.id}
											label={
												<>
													{t('Group meeting')}{' '}
													<b>
														{meeting?.membershipCommunityShortName?.join(', ') ||
															meeting?.userAttendanceCommunityShortName?.join(', ')}
													</b>{' '}
													{date}
												</>
											}
											value={values.calendarItemsId.includes(meeting.id)}
											onChange={toggleMeeting}
										/>
									);
								})
							}
						</FieldArray>
						<div className={styles.tipReviewForm__orSeparator}>
							<span className={styles.tipReviewForm__orSeparatorLabel}>{t('or')}</span>
						</div>
					</When>
					<CheckboxInput
						label={t('All Tips (all members)')}
						value={values.calendarItemsId.length === 0}
						onChange={() => {
							setFieldValue('calendarItemsId', []);
							handleBlur('calendarItemsId');
						}}
					/>
				</div>
			)}
		</div>
	);
};

export interface ITipReviewFormInputsProps
	extends Pick<ITipReviewFormProps, 'title' | 'targetMeetings' | 'editing' | 'secondaryFieldLabelsBold'> {
	formikProps: FormikProps<TTipReviewWithSharingForm>;
}

export interface ITipReviewFormProps {
	title: string;
	submitLabel: string;
	initialValues?: Partial<TTipReviewWithSharingForm>;
	targetMeetings: TGroupMeetingsForTipCreation[] | null;
	submitButtonDisabled?: boolean;
	editing?: boolean;
	ignoreDirty?: boolean;
	secondaryFieldLabelsBold?: boolean;
	children?: (formProps: FormikProps<TTipReviewWithSharingForm>) => JSX.Element;
	onSubmit: (values: TTipReviewWithSharingForm) => void | Promise<unknown>;
	renderWrapper?: ({
		formProps,
		children,
	}: {
		formProps: FormikProps<TTipReviewWithSharingForm>;
		children: ReactNode;
	}) => ReactNode;
}

export default memo(TipReviewForm);
