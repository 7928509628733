import styles from './ShareOption.module.css';
import { ArrowRightIcon } from '@ui-kit';
import type { ReactNode } from 'react';

const ShareOption = ({ onClick, title, icon }: IShareOptionProps) => {
	return (
		<button className={styles.shareOption__container} onClick={onClick}>
			<div className={styles.shareOption__wrapper}>
				<div className={styles.shareOption__informationWrapper}>
					{icon}
					<p className={styles.shareOption__title}>{title}</p>
				</div>
				<ArrowRightIcon fill={'#000'} height={16} width={14} />
			</div>
		</button>
	);
};

export interface IShareOptionProps {
	onClick: () => void;
	title: string;
	icon: ReactNode;
}

export default ShareOption;
