import { SimplePageLayout, Page, AppActivityIndicator } from '../../components';
import NewTipCategoriesForm from '@ui-modules/tipJar/components/NewTipCategoriesForm';
import { EmptyStateMessage } from '@ui-kit';
import { useTranslation, useNavigate } from '@hooks';
import { useTipCategoriesQuery } from '@ui-modules/tipJar/hooks/useTipCategoriesQuery';
import { useGuessTipInputQuery } from '@ui-modules/tipJar/hooks/useGuessTipInputQuery';
import { useTipFormSearchParams } from '@ui-modules/tipJar/hooks/useTipFormSearchParams';
import { TipEntity } from '@ui-modules/tipJar/utils/TipEntity';
import styles from './NewTipCategoriesPage.module.css';
import { ROUTES } from '@constants';
import type { TPatchTipForm } from '@schemas';

const NewTipCategoriesPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [tipFormParamsValidationError, tipFormValues, tipFormWasEdited] = useTipFormSearchParams();
	const tipCategoriesQuery = useTipCategoriesQuery();
	const guessTipQuery = useGuessTipInputQuery(tipFormValues, {
		enabled: !tipFormParamsValidationError && !tipFormWasEdited,
		select: (guessedPatchTipForm) =>
			TipEntity.mergeTipGuessCategoriesWithUserInputPatchTipForms(guessedPatchTipForm, tipFormValues),
	});
	const guessedTip = guessTipQuery.data ?? ({} as TPatchTipForm);

	return (
		<Page title={t('New Tip categories')}>
			<SimplePageLayout headerTitle={t('Select Type(s)')}>
				<div className={styles.newTipCategoriesPage__pageContent}>
					{tipCategoriesQuery.isLoading || guessTipQuery.isInitialLoading ? (
						<AppActivityIndicator title={t('AI engine is working, please wait...')} variant="absolute" />
					) : tipCategoriesQuery.isError || tipFormParamsValidationError ? (
						<EmptyStateMessage
							text={
								tipCategoriesQuery.error?.message ||
								guessTipQuery.error?.message ||
								tipFormParamsValidationError?.message ||
								t('Something went wrong')
							}
						/>
					) : (
						<>
							<h2>{t('What type of tip is this?')}</h2>

							<p className={styles.newTipCategoriesPage__description}>
								{t('We selected a type for you, but you can change it if necessary below. At least one type required.')}
							</p>
							<NewTipCategoriesForm
								categories={tipCategoriesQuery.data}
								initialValues={
									tipFormWasEdited
										? tipFormValues
										: TipEntity.mergeTipGuessCategoriesWithUserInputPatchTipForms(guessedTip, tipFormValues)
								}
								onSubmit={async (values) => {
									navigate(ROUTES.newTipCategories({ ...values, edited: true }), { replace: true }); // to keep initialValues in the URL if a user go back
									navigate(ROUTES.newTipMatches(values));
								}}
							/>
						</>
					)}
				</div>
			</SimplePageLayout>
		</Page>
	);
};

export interface INewTipCategoriesPageParams extends Record<string, string | undefined> {
	id: string;
}

export default NewTipCategoriesPage;
