import LightBox from 'react-image-lightbox';
import { useLongPress } from '@hooks';
import type { ReactNode } from 'react';
import React, { memo, useCallback, useLayoutEffect } from 'react';
import { noop } from '@utils';
import { useState } from '@hooks';
import type { CarouselProps } from 'react-responsive-carousel';
import { Carousel } from 'react-responsive-carousel';
import clsx from 'clsx';
import { Modal, RightSliderIcon } from '@ui-kit';
import styles from './TipsImageCarousel.module.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './TipsImageCarousel.overwrite.css';
import 'react-image-lightbox/style.css';
import CarouselCloseButton from '@ui-modules/tipJar/components/TipsImageCarousel/CarouselCloseButton';
import CarouselItem from '@ui-modules/tipJar/components/TipsImageCarousel/CarouselItem';
import { useTranslation } from 'react-i18next';

const TipsImageCarousel = ({
	items,
	isTipEditable,
	confirmImageItemRemoving,
	errorMessage,
	initialDisplayedGalleryItemIndex = 0,
	onItemRemove,
	onDisplayedGalleryItemChanged,
}: ITipsImageCarouselProps) => {
	const { t } = useTranslation();
	const [index, setIndex] = useState<number>(initialDisplayedGalleryItemIndex);
	const [fullscreenViewShown, setFullscreenViewShown] = useState<boolean>(false);
	const [itemForRemove, setItemForRemove] = useState<TCarouselItem | boolean>(false);

	const handleRemoveImage = (item: TCarouselItem) => {
		if (confirmImageItemRemoving) {
			setItemForRemove(item);
		} else {
			onItemRemove?.(item);
		}
	};

	useLayoutEffect(() => {
		setFullscreenViewShown(false);
	}, [items]);

	useLayoutEffect(
		function syncInitialDisplayedGalleryItemIndexWithLocalState() {
			setIndex(initialDisplayedGalleryItemIndex);
		},
		[initialDisplayedGalleryItemIndex],
	);

	const currentItem = items[index];
	const nextIndex = (index + 1) % items.length;
	const prevIndex = (index + items.length - 1) % items.length;
	const filteredImages = items.filter((item) => item.type === 'image');

	useLayoutEffect(() => {
		onDisplayedGalleryItemChanged?.(index, currentItem);
	}, [currentItem]);

	const longPressProps = useLongPress({
		onClick: (event) => {
			const imgElement = event.currentTarget as HTMLImageElement;
			const index = Number(imgElement.dataset.galleryImageIndex) || 0;
			setIndex(index);
		},
		onLongPress: noop,
	});

	const renderIndicator = useCallback<CarouselProps['renderIndicator']>((clickHandler, isSelected, index) => {
		if (items.length <= 1) {
			return null;
		}
		return (
			<li className={styles.tipsImageCarousel__dotItem} key={index} value={index}>
				<button
					aria-label={`slide item ${index}`}
					className={clsx(styles.tipsImageCarousel__dot, isSelected && styles.tipsImageCarousel__dot_selected)}
					onClick={clickHandler}
				/>
			</li>
		);
	}, []);

	const renderArrow = useCallback<CarouselProps['renderArrowPrev' | 'renderArrowNext']>(
		(clickHandler, hasPrevOrNext, label) => {
			const isPrev = label === 'previous';
			return hasPrevOrNext ? (
				<button
					aria-label={isPrev ? 'Previous Slide' : 'Next Slide'}
					className={clsx(styles.carouselArrow, isPrev ? styles.carouselArrowPrev : styles.carouselArrowNext)}
					type="button"
					onClick={clickHandler}
				>
					{isPrev ? (
						<img alt="arrow" className={styles.arrowIconRight} src={RightSliderIcon} />
					) : (
						<img alt="arrowRight" className={styles.arrowIconLeft} src={RightSliderIcon} />
					)}
				</button>
			) : null;
		},
		[],
	);

	return (
		<div
			className={styles.tipsImageCarousel}
			onClick={(event) => event.stopPropagation()}
			onKeyDown={(event) => event.stopPropagation()}
		>
			<>
				<Carousel
					axis={'horizontal'}
					interval={3000}
					renderArrowNext={(clickHandler, hasNext) => renderArrow(clickHandler, hasNext, 'next')}
					renderArrowPrev={(clickHandler, hasPrev) => renderArrow(clickHandler, hasPrev, 'previous')}
					renderIndicator={renderIndicator}
					renderItem={(item) => <div className={styles.tipsImageCarousel__itemImage}>{item}</div>}
					selectedItem={index}
					showStatus={false}
					showThumbs={false}
					onChange={(index) => {
						setIndex(index);
					}}
					onClickItem={(index) => {
						setIndex(index);
						setFullscreenViewShown(true);
					}}
				>
					{items.map((item, idx) => (
						<CarouselItem item={item} key={idx + item.type} longPressProps={longPressProps} />
					))}
				</Carousel>
				{errorMessage && <div className={styles.tipsImageCarousel__errorMessage}>{errorMessage}</div>}
				{currentItem ? (
					<CarouselCloseButton
						hidden={!('removable' in currentItem && currentItem.removable) || !isTipEditable}
						onClick={() => handleRemoveImage(currentItem)}
					/>
				) : null}
				{/* Fullscreen image viewer. */}
				{fullscreenViewShown && !!currentItem && currentItem.type === 'image' && currentItem.source && (
					<LightBox
						enableZoom={false}
						mainSrc={filteredImages[index].source}
						nextSrc={filteredImages[nextIndex]?.source}
						prevSrc={filteredImages[prevIndex]?.source}
						reactModalStyle={{
							overlay: { zIndex: 1050 },
						}}
						onCloseRequest={() => setFullscreenViewShown(false)}
						onMoveNextRequest={() => setIndex(nextIndex)}
						onMovePrevRequest={() => setIndex(prevIndex)}
					/>
				)}
				<Modal
					buttonsClassName={styles.tipsImageCarousel__modalButtons}
					cancelTitle={t('Cancel')}
					confirmTitle={t('Delete image')}
					contentClassName={styles.tipsImageCarousel__modalContent}
					subTitle={t(
						'Depending on your photo library access settings, this image may not be saved to your device and cannot be recovered.',
					)}
					title={t('Are You Sure?')}
					titleCentered
					variant="small"
					visible={itemForRemove as boolean}
					onCancel={() => {
						setItemForRemove(false);
					}}
					onConfirm={async () => {
						setItemForRemove(false);
						onItemRemove?.(itemForRemove as TCarouselItem);
					}}
				/>
			</>
		</div>
	);
};
/** Image source URL. */
export type TCarouselItem =
	| {
			type: 'image';
			source: string;
			removable?: boolean;
	  }
	| {
			type: 'view';
			Component: ReactNode;
	  };

export type TLightBoxItem = {
	type: 'image';
	source: string;
	removable?: boolean;
};

export interface ITipsImageCarouselProps {
	items: TCarouselItem[];
	isTipEditable?: boolean;
	errorMessage?: string;
	confirmImageItemRemoving: boolean;
	onItemRemove?: (item: TCarouselItem) => void;
	initialDisplayedGalleryItemIndex?: number;
	onDisplayedGalleryItemChanged?: (index: number, currentItem: TCarouselItem | undefined) => void;
}

export default memo(TipsImageCarousel);
