import { When } from '@ui-kit';
import TipsImageCarousel from '../TipsImageCarousel';
import useTipCarouselItems from '@ui-modules/tipJar/hooks/useTipCarouselItems';
import useRemoveTipUserImageMutation from '@ui-modules/tipJar/hooks/useRemoveTipUserImageMutation';
import type { TPatchTipImage } from '@tiger21-llc/connect-shared/src/schemas/CreateTipForm.schema';
import type { TTip, TTipImage } from '@tiger21-llc/connect-shared/src/typings/entities/Tip.type';
import type { ReactNode } from 'react';
import type { IPatchTipFormMainProps } from '../PatchTipForm';
import type { ITipsImageCarouselProps } from '../TipsImageCarousel/TipsImageCarousel';

export const TipDetailsImageCarousel = ({
	tipId,
	tipImages = [],
	isTipEditable,
	confirmImageItemRemoving,
	initialDisplayedGalleryItemIndex,
	onTipPatched,
	onDisplayedGalleryItemChanged,
	ImageUploadInput,
}: ITipDetailsImageCarouselProps) => {
	const tipCarouselItems = useTipCarouselItems({
		tipId,
		galleryFiles: tipImages,
		isTipEditable,
		onTipPatched,
		ImageUploadInput,
	});

	const { mutate: removeTipUserImage } = useRemoveTipUserImageMutation({
		galleryFiles: tipImages,
		tipId,
		onTipPatched,
	});

	return (
		<TipsImageCarousel
			confirmImageItemRemoving={confirmImageItemRemoving}
			initialDisplayedGalleryItemIndex={initialDisplayedGalleryItemIndex}
			isTipEditable={isTipEditable}
			items={tipCarouselItems}
			onDisplayedGalleryItemChanged={onDisplayedGalleryItemChanged}
			onItemRemove={() => removeTipUserImage()}
		/>
	);
};

export interface ITipDetailsImageCarouselProps
	extends Pick<IPatchTipFormMainProps, 'onTipPatched'>,
		Pick<ITipsImageCarouselProps, 'initialDisplayedGalleryItemIndex' | 'onDisplayedGalleryItemChanged'> {
	tipId: TTip['id'] | undefined;
	tipImages: TPatchTipImage[] | TTipImage[];
	isTipEditable: boolean;
	confirmImageItemRemoving: boolean;
	ImageUploadInput?: ReactNode;
}

export default TipDetailsImageCarousel;
