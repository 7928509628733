import { useMemo, type ReactNode } from 'react';
import ImageEditTip from '../components/ImageEditTip';
import type { TCreateTipForm } from '@tiger21-llc/connect-shared/src/schemas/CreateTipForm.schema';
import type { IPatchTipFormMainProps } from '@ui-modules/tipJar/components/PatchTipForm';
import type { TCarouselItem } from '../components/TipsImageCarousel/TipsImageCarousel';

const useTipCarouselItems = ({
	tipId,
	galleryFiles,
	isTipEditable,
	ImageUploadInput,
	onTipPatched,
}: ITipCarouselItems): TCarouselItem[] => {
	return useMemo(() => {
		const carouselItems: TCarouselItem[] = galleryFiles.map((image) => {
			if (image.isAiGenerated) {
				return {
					type: 'image',
					removable: false,
					source: image.documentUrl,
				};
			} else {
				return {
					type: 'image',
					removable: isTipEditable,
					source: image.documentUrl,
				};
			}
		});
		if (isTipEditable && !galleryFiles.find((image) => !image.isAiGenerated)) {
			carouselItems.push({
				type: 'view',
				Component: ImageUploadInput ?? (
					<ImageEditTip initialValue={galleryFiles} tipId={tipId} onTipPatched={onTipPatched} />
				),
			});
		}

		return carouselItems;
	}, [galleryFiles, isTipEditable]);
};

interface ITipCarouselItems extends IPatchTipFormMainProps {
	galleryFiles: TCreateTipForm['galleryFiles'];
	isTipEditable: boolean;
	ImageUploadInput?: ReactNode;
}

export default useTipCarouselItems;
