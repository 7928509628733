import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';
import { TipReviewEntity } from '@ui-modules/tipJar/utils/TipReviewEntity';
import type { TTipReview, TTipReviewWithReaction, TTip } from '@typings';

export const useTipReviewQuery = (tipId: TTip['id'], tipReviewId: TTipReview['id'] | undefined | null) => {
	const reactQuery = useService('ReactQueryService');
	const api = useService('ApiService');

	return useQuery<TTipReviewWithReaction, Error>(
		reactQuery.queryKeys.tipReview(tipId, String(tipReviewId)),
		async () => {
			const tipReview = await api.tips.getTipReview(String(tipReviewId));
			const tipReviewReaction = await api.tips.getTipReviewsAggregateReactions([tipReview.id]).catch(() => []);
			const tipReviewWithReaction: TTipReviewWithReaction = {
				...tipReview,
				reactions: tipReviewReaction[0] ?? TipReviewEntity.getEmptyTipReviewReaction(tipReview.id),
			};
			return tipReviewWithReaction;
		},
		{
			enabled: !!tipReviewId,
			staleTime: dayjs.duration(15, 'minute').asMilliseconds(),
			cacheTime: dayjs.duration(15, 'minute').asMilliseconds(),
		},
	);
};
