import { useMe, useNavigate, useTranslation } from '@hooks';
import { compact } from '@utils';
import { ROUTES } from '@constants';
import type { ActivityEntity } from '@utils';
import type { TMeatballMenuOption } from '@ui-kit';

/** Prepared meatball menu options following permission restrictions.
 *  Depends on the chosen option performs navigation by itself or calls external onDelete callback.
 */
export const useMutatePostMeatballMenuOptions = (
	activityEntity: ActivityEntity,
	onDelete: () => void,
	hidden?: boolean,
	feedUserId?: string,
): TMeatballMenuOption[] | undefined => {
	const { t } = useTranslation();
	const { user } = useMe();
	const navigate = useNavigate();
	// @uncomment in v5.1 when internal share will be implemented
	// const { setInternalShare } = useInternalShareContext();

	const onEditPressHandler = () => {
		if (activityEntity) {
			navigate(ROUTES.editPost(activityEntity.id));
		}
	};

	// @uncomment in v5.1 when internal share will be implemented
	// const onSharePressed = () => {
	// 	setInternalShare({
	// 		type: 'discussion',
	// 		id: activityEntity?.id as string,
	// 	});
	// };

	const meatballMenuOptions: TMeatballMenuOption[] = hidden
		? []
		: compact([
				activityEntity.canEdit(user)
					? {
							text: t('Edit post'),
							onClick: onEditPressHandler,
						}
					: null,
				activityEntity.canDelete(user)
					? {
							text: t('Delete post'),
							onClick: onDelete,
							type: 'destructive',
						}
					: null,
				activityEntity.canReportContent(user)
					? {
							text: t('Report this content'),
							onClick: () => navigate(ROUTES.reportContent('activity', activityEntity.id, feedUserId)),
							type: 'destructive',
						}
					: null,
				// @uncomment in v5.1 when internal share will be implemented
				// {
				// 	text: t('Share'),
				// 	onClick: () => onSharePressed(),
				// },
			]);

	return meatballMenuOptions.length ? meatballMenuOptions : undefined;
};
