import ContentLoader from 'react-content-loader';
import type { IContentLoaderProps } from 'react-content-loader';
import { Avatar, ChevronRightIcon, HighlightedText } from '@ui-kit';
import { dayjs } from '@utils';
import type { TTip, TTipMatch, TTipWithDetails } from '@typings';
import styles from './TipCard.module.css';
import RibbonFlag from '@ui-kit/components/RibbonFlag';
import StarsRating from '@ui-kit/components/StarsRating';
import { useTranslation } from 'react-i18next';
import { useMemo, type MouseEventHandler } from 'react';
import { noop } from '@utils';
import clsx from 'clsx';
import type { TPatchTipForm } from '@schemas';
import { TipEntity } from '@tiger21-llc/connect-shared/src/utils/TipEntity';

const TipCard = ({ tip, highlightedText, onClick, onProfileClick, className }: ITipCardProps) => {
	const { t } = useTranslation();

	const tipCoverImageUrl = useMemo(() => TipEntity.getCoverImageUrl(tip), [tip]);
	const tipCategories = useMemo(() => TipEntity.stringifyCategories(tip), [tip]);

	return (
		<div className={clsx(styles.tipCard, className)} onClick={onClick} onKeyDown={noop}>
			<div className={styles.tipCard__imageContainer}>
				{tipCoverImageUrl ? <img alt={''} className={styles.tipCard__image} src={tipCoverImageUrl} /> : null}
			</div>
			<div className={styles.tipCard__mainContainer}>
				<RibbonFlag
					className={styles.tipCard__category}
					color="var(--color-lighterGrey)"
					size="small"
					title={tipCategories}
					titleClassName={styles.ribbon__title}
				/>
				<HighlightedText className={styles.tipCard__title} highlightedText={highlightedText}>
					{String(tip.title)}
				</HighlightedText>

				{'details' in tip && tip.details.rate ? (
					<StarsRating
						containerStyle={styles.tipCard__rating}
						label={t('{{count}} review', {
							count: tip.details.reviewsCount,
							defaultValue_plural: '{{count}} reviews',
						})}
						rating={tip.details.rate}
					/>
				) : null}
				{TipEntity.isTipWithDetails(tip) && tip.details.review ? (
					<div
						className={styles.tipCard__userContainer}
						onClick={(e) => {
							onProfileClick?.(e);
							e.stopPropagation();
						}}
						onKeyDown={noop}
					>
						<Avatar
							className={styles.tipCard__userAvatar}
							outline={tip.details.review.owner.roles.includes('ROLE_CHAIR')}
							size={22}
							source={tip.details.review.owner.avatarUrl}
							title={tip.details.review.owner.fullName}
						/>
						<div className={styles.tipCard__userInfo}>
							<span className={styles.tipCard__userName}>{tip.details.review.owner.fullName}</span>
							<span className={styles.tipCard__userReviewDate}>
								{dayjs(tip.details.review.createdAt).format('D MMM YYYY')}
							</span>
						</div>
					</div>
				) : null}
			</div>
			<div className={styles.tipCard__iconContainer}>
				<ChevronRightIcon fill="var(--color-primaryGrey)" height={16} width={10} onClick={onClick} />
			</div>
		</div>
	);
};

export interface ITipCardProps {
	/** Tip data to display */
	tip: TTip | TTipWithDetails | TTipMatch | TPatchTipForm;
	/** Text to highlight in the title */
	highlightedText?: string;
	/** Callback to handle card press */
	onClick?: () => void;
	/** Callback to handle profile press */
	onProfileClick?: MouseEventHandler<HTMLDivElement> | undefined;
	className?: string;
}

TipCard.Skeleton = function TipCardSkeleton(props: IContentLoaderProps) {
	return (
		<div className={styles.tipCard__skeleton}>
			<ContentLoader
				backgroundColor="#e6e6e6"
				foregroundColor="#e4f3f4"
				height={92}
				speed={2}
				viewBox="0 0 343 92"
				width={343}
				{...props}
			>
				<rect height="92" rx="0" ry="0" width="103" x="0" y="0" />
				<rect height="16" rx="0" ry="0" width="50" x="119" y="16" />
				<rect height="18" rx="0" ry="0" width="202" x="119" y="36" />
				<rect height="18" rx="0" ry="0" width="160" x="119" y="58" />
			</ContentLoader>
		</div>
	);
};

export default TipCard;
