import { Outlet } from 'react-router-dom';
import { ActivityIndicator, Backdrop } from '@ui-kit';
import { useGlobalSearchState } from '@ui-modules/globalSearch/hooks/useGlobalSearchState';
import NotificationFeedNotifier from '@ui-modules/notifications/components/NotificationFeedNotifier';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { TabBar, TabBarContextProvider } from '../../components/TabBar';
import styles from './MainShell.module.css';
import type { RefObject } from 'react';
import { Suspense } from 'react';
import { useEffect } from 'react';
import CollapsibleSidebar from '../../components/CollapsibleSidebar';
import NotificationsList from '@ui-modules/notifications/components/NotificationsList';
import { useNotificationToast } from '@ui-modules/notifications/hooks/useNotificationToast';
import { useRefreshTokenRefetchingWorker } from '@ui-modules/auth/hooks/useRefreshTokenRefetchingWorker';
import { useClickAway, useRef, useDebouncedCallback, useState } from '@hooks';
import InternalShareModal from '@ui-modules/share/components/InternalShareModal';

const MainShell = () => {
	useRefreshTokenRefetchingWorker();

	const { isSearchActive } = useGlobalSearchState();
	const [isNotificationsShown, setNotificationsShown] = useState(false);

	const toggleNotifications = useDebouncedCallback(() => {
		setNotificationsShown((opened) => !opened);
	}, 100);
	const { allowShowToast } = useNotificationToast();
	const notificationsButtonRef = useRef() as RefObject<HTMLAnchorElement>;

	const ref = useRef() as RefObject<HTMLElement>;
	useClickAway(ref, (event) => {
		if (isNotificationsShown && !notificationsButtonRef.current?.contains(event.target as Node)) {
			toggleNotifications();
		}
	});

	useEffect(() => {
		allowShowToast(!isNotificationsShown);
	}, [isNotificationsShown, allowShowToast]);

	useEffect(() => {
		if (isNotificationsShown || isSearchActive) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [isNotificationsShown, isSearchActive]);

	return (
		<ErrorBoundary>
			<TabBarContextProvider>
				<TabBar
					isNotificationsShown={isNotificationsShown}
					notificationsButtonRef={notificationsButtonRef}
					toggleNotificationsSection={toggleNotifications}
				/>
				<CollapsibleSidebar isOpened={isNotificationsShown}>
					<NotificationsList
						innerRef={ref}
						Notifier={NotificationFeedNotifier}
						visible={isNotificationsShown}
						onNotificationClick={toggleNotifications}
					/>
				</CollapsibleSidebar>
				<div className={styles.mainShell}>
					<Suspense fallback={<ActivityIndicator size="medium" type="fit" />}>
						<Outlet />
					</Suspense>
					<Backdrop hidden={!isSearchActive && !isNotificationsShown} />
				</div>
			</TabBarContextProvider>
			<InternalShareModal />
		</ErrorBoundary>
	);
};

export default MainShell;
