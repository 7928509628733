import { useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTipCategoriesQuery } from '@ui-modules/tipJar/hooks/useTipCategoriesQuery';
import { TipEntity } from '@ui-modules/tipJar/utils/TipEntity';
import { useTranslation } from '@hooks';
import { useTipsSortSearchParams } from '@ui-modules/tipJar/hooks/useTipsSortSearchParams';
import type { TTipTag } from '@typings';

export const useTipCategoriesSearchParams = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { t } = useTranslation();

	const [selectedSortOption, setSelectedSortOption] = useTipsSortSearchParams(TipEntity.getDefaultSortValueV2());
	const sortOptions = useRef(TipEntity.getTipSortOptions(t)()).current;
	const { data: tipCategories = [] } = useTipCategoriesQuery();

	const selectedTipCategories = useMemo(() => {
		const categoriesQuery = searchParams.get('categories');
		if (!tipCategories.length || !categoriesQuery) return [];

		const categoriesFromQuery = categoriesQuery.split(',');
		const selectedCategories: TTipTag[] = tipCategories.filter((tipCategory) =>
			categoriesFromQuery.includes(tipCategory.title),
		);
		return selectedCategories;
	}, [searchParams, tipCategories]);

	const handleApplyCategories = (categories: TTipTag[]) => {
		if (categories.length > 0) {
			searchParams.set('categories', categories.map((category) => category.title).join(','));
		} else {
			searchParams.delete('categories');
		}
		setSearchParams(searchParams);
	};

	return {
		selectedSortOption,
		setSelectedSortOption,
		sortOptions,
		tipCategories,
		selectedTipCategories,
		handleApplyCategories,
	};
};
