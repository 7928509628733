import { useService, usePaginatedInfiniteQueryV2 } from '@hooks';
import { dayjs, ORM } from '@utils';
import type { TTip, TTipsFilter, TTipWithDetails } from '@typings';

export const useMyTipsQuery = ({ categories }: TTipsFilter) => {
	const reactQuery = useService('ReactQueryService');
	const api = useService('ApiService');

	return usePaginatedInfiniteQueryV2<TTip>(
		reactQuery.queryKeys.myTips({ categories }),
		async ({ pageParam = 1 }) => {
			const tags = categories.map((category) => category.id);
			const tips = await api.tips.getMyTips({
				page: pageParam,
				tags: tags,
				orderBy: 'reviewCreatedAt',
				orderDirection: 'desc',
			});
			const tipDetails = await api.tips.getTipsDetails({
				page: 1,
				tips: tips.map((tip) => tip.id),
				tags: tags,
				orderBy: 'reviewCreatedAt',
				orderDirection: 'desc',
			});
			const tipWithDetails: TTipWithDetails[] = ORM.mapOneRelation(tips, tipDetails, {
				alias: 'details',
				parentColumn: 'id',
				childrenColumn: 'id',
			});

			return tipWithDetails;
		},
		{
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			cacheTime: dayjs.duration(15, 'minutes').asMilliseconds(),
		},
	);
};
