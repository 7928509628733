import { useService, useMutation, useNotification, useMe } from '@hooks';
import { useUploadTipFileMutation } from './useUploadTipFileMutation';
import type { TCreateTipForm } from '@schemas';
import type { MutateOptions } from '@tanstack/react-query';
import type { TTip, TUser } from '@typings';

export const useCreateTipMutation = (options?: MutateOptions<TTip, Error, TUseCreateTipMutation>) => {
	const api = useService('ApiService');
	const { user } = useMe();
	const { showUnknownError } = useNotification();

	const uploadTipFileMutation = useUploadTipFileMutation();

	return useMutation<TTip, Error, TUseCreateTipMutation>(
		['createTip'],
		async ({ coverImageIndex, ...tipData }) => {
			const createdTip = await api.tips.postTip(getPutTipDto(tipData, null, user));

			const imageUploadResults = [];
			for (const patchTipImage of tipData.galleryFiles ?? []) {
				try {
					const result = await uploadTipFileMutation.mutateAsync(
						{
							tipId: createdTip.id,
							patchTipImage,
						},
						{},
					);
					imageUploadResults.push({ status: 'fulfilled', value: result });
				} catch (error) {
					imageUploadResults.push({ status: 'rejected', reason: error });
				}
			}

			if (tipData?.galleryFiles?.length) {
				const coverImageUploadResult = imageUploadResults[coverImageIndex ?? 0];
				const coverId = coverImageUploadResult.status === 'fulfilled' ? coverImageUploadResult.value?.id : null;
				if (coverId) {
					await api.tips.putTip(createdTip.id, getPutTipDto(tipData, coverId, user));
				}
			}

			return createdTip;
		},
		{
			...options,
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};

function getPutTipDto(tipData: TCreateTipForm, coverId: string | null, user: TUser) {
	return {
		...tipData,
		links: (tipData.links ?? []).map((link) => ({
			...link,
			title: link.title || null,
			description: null,
		})),
		tagsIds: tipData.tags.map((tag) => tag.id),
		shareable: true,
		ownerId: user.id,
		coverId,
	};
}

type TUseCreateTipMutation = TCreateTipForm & { coverImageIndex?: number | null };
