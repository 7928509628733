import { useDropzone, useNotification, useTranslation } from '@hooks';
import { COMMUNITY_DOCUMENTS } from '@constants';
import { isHeic, heicTo } from 'heic-to';

/** Opens native file picker screen to choose one file from a user computer. */
export const usePickUpFile = (uploadFile: (file: File) => void, accept = COMMUNITY_DOCUMENTS.availableMimeTypes) => {
	const { t } = useTranslation();
	const { showError } = useNotification();

	const { open, getInputProps } = useDropzone({
		multiple: false,
		onDropRejected: () => showError({ title: t('Rejected'), subtitle: t('Not allowed file type') }),
		onDrop: (acceptedFiles: File[]) => {
			if (acceptedFiles && acceptedFiles[0]) {
				const file = acceptedFiles[0];
				isHeic(file).then((fileIsHeic) => {
					if (fileIsHeic) {
						heicTo({ blob: file, type: 'image/jpeg', quality: 0.75 }).then((convertedBlob) => {
							const convertedFile = new File([convertedBlob], file.name, {
								lastModified: new Date().getTime(),
								type: 'image/jpeg',
							});
							uploadFile(convertedFile);
						});
					} else {
						uploadFile(file);
					}
				});
			}
		},
		accept: accept.reduce((acc, type) => {
			return { ...acc, [type]: [] };
		}, {}),
	});

	return { openFilePicker: open, getInputProps };
};
