import TabWrapper from '../../components/TabWrapper';
import { Gap, Tabs } from '@ui-kit';
import ChapterMemberSectionList from '@ui-modules/communities/components/ChapterMemberSectionList';
import CommunityCalendarItemsSectionList from '@ui-modules/communities/components/CommunityCalendarItemsSectionList';
import CommunityFilesWidget from '@ui-modules/files/components/CommunityFilesWidget';
import { useTranslation, useParams, useMe, useNavigate, useEvent, useRouteSearchQueryState } from '@hooks';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useChapterTabsHandler } from '@ui-modules/groups/hooks/useChapterTabsHandler';
import { getChapterTabsPermissions } from '@ui-modules/groups/utils/getChapterTabsPermissions';
import { ROUTES } from '@constants';
import type { TEvent, TGroupTabName, TUserProfileRecord } from '@typings';
import ChapterDiscussions from '@ui-modules/communities/components/ChapterDiscussions';
import CommunityTipsPage from '../CommunityTipsPage';

const ChapterTabsPage = () => {
	// Dependencies.
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useMe();
	const [routeSearchQuery, setRouteSearchQuery] = useRouteSearchQueryState();

	// Input group data.
	const chapterId = useParams<IGroupTabsPageParams>().chapterId as IGroupTabsPageParams['chapterId'];
	const { data: chapter } = useCommunityQuery(chapterId);

	// Tabs logic.
	const chapterTab = useParams<IGroupTabsPageParams>().chapterTab as IGroupTabsPageParams['chapterTab'];
	const { tabs } = getChapterTabsPermissions(chapter, user);
	const { currentTabIndex, onChangeTab } = useChapterTabsHandler(chapter?.id, tabs, String(chapterTab));

	// User actions.
	const openMemberProfile = useEvent(({ profile }: TUserProfileRecord) => navigate(ROUTES.memberProfile(profile.id)));
	const openCalendarItem = useEvent((calendarItem: TEvent) => {
		if (calendarItem['@type'] === 'Meeting') navigate(ROUTES.viewMeeting(calendarItem.id));
		else if (calendarItem['@type'] === 'Event') navigate(ROUTES.viewEvent(calendarItem.id));
	});

	const heroImageUrl =
		typeof chapter?.backgroundUrl === 'string' ? chapter?.backgroundUrl : chapter?.backgroundUrl?.contentUrl;

	return currentTabIndex === NOT_FOUND || !chapter ? null : (
		<>
			<Tabs selectedIndex={currentTabIndex} onSelect={onChangeTab}>
				<Tabs.List>
					{tabs.map((tab) => {
						switch (tab) {
							case 'events':
								return <Tabs.Tab>{t('Events')}</Tabs.Tab>;
							case 'members':
								return <Tabs.Tab key="members">{t('Members')}</Tabs.Tab>;
							case 'discussions':
								return <Tabs.Tab key="discussions"> {t('Discussions')}</Tabs.Tab>;
							case 'files':
								return <Tabs.Tab key="files">{t('Files')}</Tabs.Tab>;
							case 'tips':
								return <Tabs.Tab key="tips">{t('Tips')}</Tabs.Tab>;
						}
					})}
				</Tabs.List>
				{tabs.map((tab) => {
					switch (tab) {
						case 'events':
							return (
								<Tabs.Panel>
									<TabWrapper>
										<CommunityCalendarItemsSectionList
											communityId={chapterId}
											emptyMessage={t('No upcoming events or meetings')}
											heroImageUrl={heroImageUrl}
											onClick={openCalendarItem}
										/>
									</TabWrapper>
								</Tabs.Panel>
							);
						case 'members':
							return (
								<Tabs.Panel>
									<TabWrapper>
										<ChapterMemberSectionList chapterId={chapterId} onClick={openMemberProfile} />
									</TabWrapper>
								</Tabs.Panel>
							);
						case 'discussions':
							return (
								<Tabs.Panel>
									<TabWrapper>
										<ChapterDiscussions
											communityId={chapterId}
											initialSearchQuery={routeSearchQuery}
											onChangeSearchQuery={setRouteSearchQuery}
										/>
									</TabWrapper>
								</Tabs.Panel>
							);
						case 'files':
							return (
								<Tabs.Panel>
									<TabWrapper>
										<Gap gap={24} />
										<CommunityFilesWidget communityDefinition="chapter" communityId={chapterId} folderId={null} />
									</TabWrapper>
								</Tabs.Panel>
							);
						case 'tips':
							return (
								<Tabs.Panel>
									<TabWrapper>
										<CommunityTipsPage communityDefinition="chapter" communityId={chapterId} />
									</TabWrapper>
								</Tabs.Panel>
							);
					}
				})}
			</Tabs>
		</>
	);
};

const NOT_FOUND = -1; // Array.findIndex not found value.

export interface IGroupTabsPageParams extends Record<string, string | undefined> {
	chapterId: string;
	chapterTab: TGroupTabName;
}

export default ChapterTabsPage;
