import { useService, useMutation, useNotification, useTranslation } from '@hooks';
import type { MutateOptions } from '@tanstack/react-query';
import type { TTip } from '@typings';

export const useDeleteTipMutation = (options?: MutateOptions<unknown, Error, Pick<TTip, 'id'>>) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showSuccess, showUnknownError } = useNotification();
	const { t } = useTranslation();

	return useMutation<unknown, Error, Pick<TTip, 'id'>>(
		['deleteTip'],
		async ({ id }) => {
			await api.tips.deleteTip(id);
		},
		{
			...options,
			onSuccess: async (response, variables, context) => {
				const tipId = variables.id;

				await options?.onSuccess?.(response, variables, context);

				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.myTips());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.allTips());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.tipsGroup());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.communityTips());
				reactQuery.queryClient.removeQueries(reactQuery.queryKeys.tip(tipId));
				reactQuery.queryClient.removeQueries(reactQuery.queryKeys.tipReviews(tipId));
				reactQuery.queryClient.removeQueries(reactQuery.queryKeys.tipContributors(tipId));
				showSuccess({ title: t('Tip deleted') });
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
