import { useState } from '@hooks';
import { Modal } from '@ui-kit';
import { useInternalShareContext } from '@ui-modules/share/hooks/useInternalShare';
import ShareHeader from '../ShareHeader';
import styles from './InternalShareModal.module.css';

import InitialShareModalContent from '../InitialShareModalContent';
import ChatShareModalContent from '../ChatShareModalContent';
import NetworkShareModalContent from '../NetworkShareModalContent';
import ChaptersShareModalContent from '../ChaptersShareModalContent';

const InternalShareModal = () => {
	const { hasInternalShare } = useInternalShareContext();

	const [currentContent, setCurrentContent] = useState<'chat' | 'network' | 'chapters' | null>(null);

	const handleContentChange = (content: TContent) => {
		setCurrentContent(content);
	};

	const nestedScreensMap = {
		chat: <ChatShareModalContent handleContentChange={handleContentChange} />,
		network: <NetworkShareModalContent handleContentChange={handleContentChange} />,
		chapters: <ChaptersShareModalContent handleContentChange={handleContentChange} />,
	};

	const defaultContent = <InitialShareModalContent handleContentChange={handleContentChange} />;

	return (
		<Modal
			cancelMode="skip"
			contentClassName={styles.modalContent}
			customHeader={<ShareHeader currentContent={currentContent} handleContentChange={handleContentChange} />}
			modalContent={
				<div className={styles.modalContent__wrapper}>
					{nestedScreensMap[currentContent as keyof typeof nestedScreensMap] || defaultContent}
				</div>
			}
			visible={hasInternalShare}
		/>
	);
};

export type TContent = 'chat' | 'network' | 'chapters' | null;

export default InternalShareModal;
