import { useService, useQuery } from '@hooks';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TEvent } from '@typings';

export const useGetMeetingQuery = (
	meetingId?: TEvent['id'],
	callback?: (data: TEvent | null | undefined, error: Error | null) => void,
	options: UseQueryOptions<TEvent, Error, TEvent> = {},
) => {
	const api = useService('ApiService');
	const { queryKeys } = useService('ReactQueryService');

	return useQuery<TEvent, Error>(
		queryKeys.getMeeting(String(meetingId)),
		async () => await api.meeting.getMeeting(meetingId as TEvent['id']),
		{
			onSettled: callback,
			retry: false,
			...options,
		},
	);
};
