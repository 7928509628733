import { useService, usePaginatedInfiniteQueryV2 } from '@hooks';
import { dayjs, ORM } from '@utils';
import { useFetchMyGroupAndChapterCommunityIds } from '@ui-modules/account/hooks/useMemberMembershipQuery';
import type { TTipsFilter, TTipWithDetails } from '@typings';

export const useGroupTipsQuery = ({ categories }: TTipsFilter) => {
	const reactQuery = useService('ReactQueryService');
	const api = useService('ApiService');

	const fetchMyGroupAndChapterCommunityIds = useFetchMyGroupAndChapterCommunityIds();

	return usePaginatedInfiniteQueryV2<TTipWithDetails>(
		reactQuery.queryKeys.groupsTips({ categories }),
		async ({ pageParam = 1 }) => {
			const tags = categories.map((category) => category.id);
			const tips = await api.tips.getGroupTips({
				page: pageParam,
				tags: tags,
				orderBy: 'reviewCreatedAt',
				orderDirection: 'desc',
			});
			const tipDetails = await api.tips.getTipsDetails({
				page: 1,
				tips: tips.map((tip) => tip.id),
				tags: tags,
				orderBy: 'reviewCreatedAt',
				orderDirection: 'desc',
				communities: await fetchMyGroupAndChapterCommunityIds(),
			});

			const tipWithDetails: TTipWithDetails[] = ORM.mapOneRelation(tips, tipDetails, {
				alias: 'details',
				parentColumn: 'id',
				childrenColumn: 'id',
			});

			return tipWithDetails;
		},
		{
			staleTime: dayjs.duration(5, 'minutes').asMilliseconds(),
			cacheTime: dayjs.duration(5, 'minutes').asMilliseconds(),
		},
	);
};
