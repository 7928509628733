import type { CustomMentionHandler } from 'stream-chat-react';
import { MessageList, useChannelActionContext } from 'stream-chat-react';
import { useEffect, useNavigate, useUserSessionStorageValue } from '@hooks';
import { ROUTES } from '@constants';

const actions = ['react'];

const ChatMessageList = ({ isMessageListReady }: IChatMessageListProps) => {
	const { jumpToFirstUnreadMessage, jumpToMessage } = useChannelActionContext();
	const { storageSavedValue: activeChatMessageId, setStorageSavedValue } =
		useUserSessionStorageValue('active-chat-message-id');

	const navigate = useNavigate();

	const handleMentionsClick: CustomMentionHandler = (event, mentionedUsers) => {
		const mention = event.target.closest('.str-chat__message_mention');
		if (!mention) return;

		const userTag = mention.innerText;
		if (!userTag) return;

		const user = mentionedUsers.find((user) => userTag.includes(user.name));
		if (user) {
			event.stopPropagation();
			navigate(ROUTES.memberProfile(user?.profileId as string));
		}
	};

	useEffect(
		function jumpToInitialMessage() {
			if (activeChatMessageId) {
				jumpToMessage(activeChatMessageId)
					.then(() => {
						setStorageSavedValue('');
					})
					.catch(() => {
						jumpToFirstUnreadMessage(); // if message not found (because it is a different channel or message was deleted)
					});
			} else {
				if (isMessageListReady) {
					jumpToFirstUnreadMessage();
				}
			}
		},
		[jumpToFirstUnreadMessage, jumpToMessage],
	);

	return (
		<MessageList
			hideDeletedMessages={false}
			messageActions={actions}
			noGroupByUser
			onMentionsClick={handleMentionsClick}
		/>
	);
};

export interface IChatMessageListProps {
	isMessageListReady: boolean;
}

export default ChatMessageList;
