import Tooltip from '../Tooltip';
import { QuestionStrokeIcon } from '../../icons';
import type { FC, SVGProps } from 'react';
import { useId } from 'react';
import { clsx } from '@utils';
import styles from './QuestionTooltip.module.css';
import type { ITooltipProps } from '../Tooltip';

const QuestionTooltip = ({ className, content, place = 'top', Icon = QuestionStrokeIcon }: IQuestionTooltipProps) => {
	const id = useId();
	return (
		<>
			<Icon
				className={clsx(styles.questionTooltip, className)}
				data-tooltip-content={content}
				data-tooltip-id={id}
				data-tooltip-place={place}
			/>

			<Tooltip className={styles.tooltip__container} id={id} place={place} />
		</>
	);
};
interface IQuestionTooltipProps extends ITooltipProps {
	className?: string;
	/** Custom icon instead of default one  */
	Icon?: FC<SVGProps<SVGSVGElement>>;
}
export default QuestionTooltip;
