import { useService, usePaginatedInfiniteQueryV2 } from '@hooks';
import { dayjs, ORM } from '@utils';
import type { TTipWithDetails, TTipsFilter, TCommunity } from '@typings';

export const useCommunityTipsQuery = (communityId: TCommunity['id'], filters?: TTipsFilter) => {
	const reactQuery = useService('ReactQueryService');
	const api = useService('ApiService');

	return usePaginatedInfiniteQueryV2<TTipWithDetails>(
		reactQuery.queryKeys.communityTips(communityId, filters),
		async ({ pageParam = 1 }) => {
			const tags = filters?.categories?.map?.((category) => category.id);
			const tips = await api.tips.getTips({
				page: pageParam,
				tags: tags,
				orderBy: filters?.sort?.field,
				orderDirection: filters?.sort?.order,
				communities: [communityId],
			});
			const tipDetails = await api.tips.getTipsDetails({
				page: 1,
				tips: tips.map((tip) => tip.id),
				tags: tags,
				orderBy: filters?.sort?.field,
				orderDirection: filters?.sort?.order,
				communities: [communityId],
			});

			const tipWithDetails: TTipWithDetails[] = ORM.mapOneRelation(tips, tipDetails, {
				alias: 'details',
				parentColumn: 'id',
				childrenColumn: 'id',
			});

			return tipWithDetails;
		},
		{
			staleTime: dayjs.duration(5, 'minutes').asMilliseconds(),
			cacheTime: dayjs.duration(5, 'minutes').asMilliseconds(),
		},
	);
};
