import { useTranslation } from '@hooks';
import { clsx } from '@utils';
import styles from './NetworkShareModalContent.module.css';
import type { TContent } from '@ui-modules/share/components/InternalShareModal/InternalShareModal';

const NetworkShareModalContent = ({ handleContentChange }: INetworkShareModalContentProps) => {
	const { t } = useTranslation();

	return <div className={styles.networkShare__container}>Network</div>;
};

export interface INetworkShareModalContentProps {
	handleContentChange: (content: TContent) => void;
}

export default NetworkShareModalContent;
