import { GLOBAL_SEARCH_DEFINITION } from '@constants';
import { useCallback, useNavigate } from '@hooks';
import { ROUTES } from '@constants';
import type { TSearchHitDocument } from '@tiger21-llc/connect-shared/src/typings';

export const useGlobalSearchNavigation = ({
	definition,
	objectID,
	communitySlug,
	streamFeedId,
	streamActivityId,
	fileOrFolder,
	folderID,
}: TUseGlobalSearchNavigationParams) => {
	const navigate = useNavigate();

	const getNavigationByResultDefinition = useCallback(() => {
		if (fileOrFolder) {
			switch (definition) {
				case GLOBAL_SEARCH_DEFINITION.network:
					return navigate(ROUTES.networkFolder(objectID, folderID));
				case GLOBAL_SEARCH_DEFINITION.group:
					return navigate(ROUTES.groupFolder(objectID, folderID));
				case GLOBAL_SEARCH_DEFINITION.chapter:
					return navigate(ROUTES.chapterFolder(objectID, folderID));
				case GLOBAL_SEARCH_DEFINITION.deal:
					if (folderID) {
						return navigate(ROUTES.dealFolder(objectID, folderID));
					} else {
						return navigate(ROUTES.dealTabs(objectID, 'details'));
					}
				case GLOBAL_SEARCH_DEFINITION.networkConnection:
					if (folderID) {
						return navigate(ROUTES.networkConnectionFolder(objectID, folderID));
					} else {
						return navigate(ROUTES.networkConnection(objectID, 'details'));
					}
			}
		}
		switch (definition) {
			case GLOBAL_SEARCH_DEFINITION.member:
				return navigate(ROUTES.memberProfile(objectID));
			case GLOBAL_SEARCH_DEFINITION.network:
				return navigate(ROUTES.network(objectID));
			case GLOBAL_SEARCH_DEFINITION.group:
				return navigate(ROUTES.group(objectID));
			case GLOBAL_SEARCH_DEFINITION.chapter:
				return navigate(ROUTES.chapter(objectID));
			case GLOBAL_SEARCH_DEFINITION.event:
				return navigate(ROUTES.viewEvent(objectID));
			case GLOBAL_SEARCH_DEFINITION.meeting:
				return navigate(ROUTES.viewMeeting(objectID));
			case GLOBAL_SEARCH_DEFINITION.discussion:
				return navigate(ROUTES.post(streamActivityId, streamFeedId));
			case GLOBAL_SEARCH_DEFINITION.learn:
				return navigate(ROUTES.post(streamActivityId, streamFeedId));
			case GLOBAL_SEARCH_DEFINITION.deal:
				return navigate(ROUTES.dealTabs(objectID));
			case GLOBAL_SEARCH_DEFINITION.networkConnection:
				return navigate(ROUTES.networkConnection(objectID));
			case GLOBAL_SEARCH_DEFINITION.tip:
				return navigate(ROUTES.tipPage(objectID));
		}
	}, []);

	return getNavigationByResultDefinition;
};

type TUseGlobalSearchNavigationParams = {
	definition: string;
	objectID: string;
	communitySlug?: string;
	streamFeedId?: string;
	streamActivityId?: string;
	fileOrFolder?: TSearchHitDocument['fileOrFolder'];
	folderID?: TSearchHitDocument['folderID'];
};
