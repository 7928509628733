import { useService, useQuery, useIsRemoteUrlImage } from '@hooks';
import { useFetchAiToken } from '@ui-modules/tipJar/hooks/useFetchAiToken';
import { compact, dayjs } from '@utils';
import { TipEntity } from '@ui-modules/tipJar/utils/TipEntity';
import type { FetchQueryOptions, UseQueryOptions } from '@tanstack/react-query';
import type { TPatchTipForm } from '@schemas';

const useGetTipsAiSuggestionsQueryOptions = (): ((
	userInputPatchTipForm: TPatchTipForm,
	guessedPatchTipForm: TPatchTipForm,
) => FetchQueryOptions<TPatchTipForm[], Error>) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const isRemoteUrlImage = useIsRemoteUrlImage();
	const fetchAiToken = useFetchAiToken();

	return function getTipsAiSuggestionsQueryOptions(
		userInputPatchTipForm: TPatchTipForm,
		guessedPatchTipForm: TPatchTipForm,
	) {
		const userInputPatchTipFormWithCategories = TipEntity.mergeTipGuessCategoriesWithUserInputPatchTipForms(
			guessedPatchTipForm,
			userInputPatchTipForm,
		);

		return {
			queryKey: reactQuery.queryKeys.tipAiSuggestions(userInputPatchTipFormWithCategories),
			queryFn: async () => {
				const aiToken = await fetchAiToken();

				const mergedTipGuessAndUserInputTipData = TipEntity.mergeTipGuessWithUserInputPatchTipForms(
					guessedPatchTipForm,
					userInputPatchTipForm,
				);
				if (!mergedTipGuessAndUserInputTipData.description) return [];
				const aiOutput = await api.ai.getTipsAiSuggestions(aiToken, mergedTipGuessAndUserInputTipData);

				const patchTipFormMappingTasks = aiOutput
					.map((aiItem) =>
						TipEntity.mapTipAiSuggestionToPatchTipForm(
							aiItem,
							TipEntity.mergeTipGuessWithUserInputPatchTipForms(guessedPatchTipForm, userInputPatchTipForm),
						),
					)
					.filter((maybePatchTipForm) => !!maybePatchTipForm)
					.map(async (patchTipForm) => {
						const aiImage = patchTipForm?.galleryFiles?.find?.((tipImage) => tipImage.isAiGenerated);
						const aiImageInvalid = aiImage && !(await isRemoteUrlImage(aiImage.documentUrl));
						if (aiImageInvalid) {
							const patchTipFormWithoutInvalidAiImage: TPatchTipForm = {
								...patchTipForm,
								galleryFiles: patchTipForm?.galleryFiles?.filter?.((tipImage) => tipImage.isAiGenerated),
							};
							return patchTipFormWithoutInvalidAiImage;
						}
						return patchTipForm;
					});

				const patchTipForms = await Promise.all(compact(patchTipFormMappingTasks));
				return patchTipForms;
			},
			staleTime: dayjs.duration(30, 'minutes').asMilliseconds(),
			cacheTime: dayjs.duration(30, 'minutes').asMilliseconds(),
		};
	};
};

export const useTipsAiSuggestionsQuery = (
	patchTipForm: TPatchTipForm,
	guessedPatchTipForm: TPatchTipForm,
	options?: UseQueryOptions<TPatchTipForm[], Error>,
) => {
	const getTipsAiSuggestionsQueryOptions = useGetTipsAiSuggestionsQueryOptions();
	return useQuery<TPatchTipForm[], Error>({
		...getTipsAiSuggestionsQueryOptions(patchTipForm, guessedPatchTipForm),
		...options,
	});
};

export const usePrefetchTipsAiSuggestionsQuery = () => {
	const reactQuery = useService('ReactQueryService');
	const getTipsAiSuggestionsQueryOptions = useGetTipsAiSuggestionsQueryOptions();

	return async function prefetchTipsAiSuggestionsQuery(
		patchTipForm: TPatchTipForm,
		guessedPatchTipForm: TPatchTipForm,
	) {
		await reactQuery.queryClient
			.prefetchQuery(getTipsAiSuggestionsQueryOptions(patchTipForm, guessedPatchTipForm))
			.catch();
	};
};
