import { CloseIcon, SearchIcon } from '@ui-kit/icons';
import { clsx } from '@utils';
import Button from '../Button';
import styles from './HeaderSearchInput.module.css';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

const HeaderSearchInput = (
	{
		value,
		onSearchCleared,
		onSearchCanceled,
		placeholder = 'Search',
		type = 'default',
		showRightSearchIcon,
		cancelButtonClassName,
		...props
	}: IHeaderSearchInputProps,
	ref: ForwardedRef<HTMLInputElement>,
) => {
	return (
		<div className={styles.input__wrapper}>
			<div className={styles.input__container}>
				{!showRightSearchIcon ? (
					<span className={clsx(styles.input__icon, styles.input__icon_left)}>
						<SearchIcon className={styles.input__iconSvg} height={12} width={12} />
					</span>
				) : null}

				<input
					className={
						showRightSearchIcon
							? clsx(styles.input, styles.input__left)
							: clsx(styles.input, onSearchCleared && styles.input_clear)
					}
					placeholder={placeholder}
					ref={ref}
					value={value}
					onBlur={(e) => e.preventDefault()}
					{...props}
				/>
				{onSearchCleared && value?.length ? (
					<span className={clsx(styles.input__icon, styles.input__icon_right)}>
						<button
							className={styles.input__clearButton}
							onClick={onSearchCleared}
							onMouseDown={(e) => e.preventDefault()}
						>
							<CloseIcon className={styles.input__iconSvg} height={8} width={8} />
						</button>
					</span>
				) : null}
				{showRightSearchIcon ? (
					<span className={clsx(styles.input__icon_end)}>
						<SearchIcon className={styles.input__iconSvg} height={12} width={12} />
					</span>
				) : null}
			</div>
			{type === 'cleared' ? (
				<Button
					className={cancelButtonClassName}
					title="Cancel"
					type="clear"
					variant="small"
					onClick={() => onSearchCanceled?.()}
				/>
			) : null}
		</div>
	);
};

export interface IHeaderSearchInputProps
	extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	value?: string;
	placeholder?: string;
	disabled?: boolean;
	showRightSearchIcon?: boolean;
	onSearchCleared?: () => void;
	onSearchCanceled?: () => void;
	type?: 'default' | 'cleared';
	cancelButtonClassName?: string;
}

export default forwardRef(HeaderSearchInput);
