import { NavLink } from '@ui-kit';
import { clsx } from '@utils';
import type { ForwardedRef, MouseEventHandler, ReactNode } from 'react';
import { forwardRef } from 'react';
import styles from './IconButton.module.css';

const IconButton = (
	{
		linkTo,
		icon,
		iconActive,
		label,
		extraMatch = false,
		forcedActive = false,
		forcedInactive = false,
		disableHighlight = false,
		badge,
		onClick,
	}: IIconButtonProps,
	ref: ForwardedRef<HTMLAnchorElement>,
) => {
	const isFinalActive = (isActive: boolean) => (isActive || forcedActive) && !forcedInactive;

	return (
		<NavLink
			className={({ isActive }) =>
				clsx(styles.iconButton__link, isFinalActive(isActive) && !disableHighlight && styles.iconButton__link_active)
			}
			end={extraMatch}
			ref={ref}
			to={linkTo}
			onClick={onClick}
		>
			{({ isActive }) => {
				return (
					<div className={styles.iconButton__container}>
						<span
							className={clsx(
								badge?.type === 'text'
									? styles.iconButton__iconContainer_textBadge
									: styles.iconButton__iconContainer_dotBadge,
							)}
						>
							{isFinalActive(isActive) && iconActive ? iconActive : icon}
							{badge?.type === 'dot' ? <span className={styles.iconButton__dotBadge} /> : null}
							{badge?.type === 'text' ? <span className={styles.iconButton__textBadge}>{badge.text}</span> : null}
						</span>
						<p
							className={clsx(
								styles.iconButton__label,
								isFinalActive(isActive) && !disableHighlight && styles.iconButton__label_active,
							)}
							title={label}
						>
							{label}
						</p>
					</div>
				);
			}}
		</NavLink>
	);
};

type TBadge =
	| {
			type: 'dot';
	  }
	| {
			type: 'text';
			text: string;
	  };

export interface IIconButtonProps {
	linkTo: string;
	label: string;
	icon: ReactNode;
	iconActive?: ReactNode;
	extraMatch?: boolean;
	forcedActive?: boolean;
	forcedInactive?: boolean;
	disableHighlight?: boolean;
	badge?: TBadge;
	onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export default forwardRef(IconButton);
