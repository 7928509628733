import { useService, useQuery } from '@hooks';
import { TipEntity } from '@ui-modules/tipJar/utils/TipEntity';
import { useFetchAiToken } from '@ui-modules/tipJar/hooks/useFetchAiToken';
import Compressor from 'compressorjs';
import { dayjs } from '@utils';
import { usePrefetchTipsAiSuggestionsQuery } from './useTipsAiSuggestionsQuery';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TPatchTipForm } from '@schemas';
import { useTipCategoriesQuery } from './useTipCategoriesQuery';

export const useGuessTipInputQuery = (
	patchTipForm: TPatchTipForm,
	queryOptions?: UseQueryOptions<TPatchTipForm, Error>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	const fetchAiToken = useFetchAiToken();
	const prefetchTipsAiSuggestionsQuery = usePrefetchTipsAiSuggestionsQuery();
	const tipCategoriesQuery = useTipCategoriesQuery();

	return useQuery<TPatchTipForm, Error>(
		reactQuery.queryKeys.guessTip(patchTipForm),
		async () => {
			const aiToken = await fetchAiToken();
			const tipGuess = await api.ai.guessTipInput(aiToken, patchTipForm, {
				platform: 'web',
				prepareFile: compressFile,
			});
			if (!tipGuess) return patchTipForm;

			const guessedPatchTipForm = TipEntity.mapTipGuessToPatchTipForm(tipGuess, tipCategoriesQuery.data);
			prefetchTipsAiSuggestionsQuery(patchTipForm, guessedPatchTipForm);

			return guessedPatchTipForm;
		},
		{
			staleTime: dayjs.duration(30, 'minutes').asMilliseconds(),
			cacheTime: dayjs.duration(30, 'minutes').asMilliseconds(),
			retry: 1,
			...queryOptions,
			enabled: tipCategoriesQuery.isSuccess,
			onSuccess(guessedPatchTipForm) {
				queryOptions?.onSuccess?.(guessedPatchTipForm);
			},
			onError: (error) => {
				queryOptions?.onError?.(error);
			},
		},
	);
};

/** Before sending to AI we optimize the size of the image to make AI work faster (T21C-8326).  */
function compressFile(file: File | Blob): Promise<File | Blob> {
	return new Promise((resolve, reject) => {
		new Compressor(file, {
			convertSize: 100_000, // 100Kb
			mimeType: 'image/jpeg',
			retainExif: false,
			maxHeight: 1280,
			maxWidth: 1280,
			resize: 'contain',
			strict: false,
			success(compressedFile) {
				resolve(compressedFile);
			},
			error(error) {
				reject(error);
			},
		});
	});
}
