import { DeleteIcon, EditIcon, ReportIcon } from '@ui-kit/icons';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, useMe } from '@hooks';
import { compact } from '@utils';
import { TipEntity } from '@ui-modules/tipJar/utils/TipEntity';
import type { TTip, TTipWithDetails } from '@typings';
import type { TMeatballMenuOption } from '@ui-kit';
import { Modal } from '@ui-kit';
import { useDeleteTipMutation } from '@ui-modules/tipJar/hooks/useDeleteTipMutation';
import { ROUTES } from '@constants';

export const useGetTipMeatballMenuOptions = (callbacks?: { onDeleted?: () => void }) => {
	const { user } = useMe();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { mutate: deleteTip } = useDeleteTipMutation({
		onSuccess: () => callbacks?.onDeleted?.(),
	});
	const [tipToDelete, setTipToDelete] = useState<TTip | null>(null);

	const meatballMenuOptions = useCallback(
		(tip: TTipWithDetails): TMeatballMenuOption[] => {
			return compact([
				TipEntity.canEditTip(tip, user) && {
					text: t('Edit Tip'),
					onClick: () => navigate(ROUTES.editTip(tip.id)),
					icon: <EditIcon />,
				},
				TipEntity.canDeleteTip(tip, user) && {
					text: t('Delete Tip'),
					type: 'destructive',
					onClick: () => setTipToDelete(tip),
					icon: <DeleteIcon />,
				},
				TipEntity.canReportTip(tip, user) && {
					text: t('Report Tip'),
					onClick: () => navigate(ROUTES.reportContent('tip', tip.id)),
					icon: <ReportIcon fill="black" height={16} width={16} />,
				},
			]);
		},
		[user],
	);

	const ConfirmationModals = (
		<>
			<Modal
				cancelTitle={t('Cancel')}
				confirmTitle={t('Delete')}
				subTitle={t(`Are you sure you want to permanently delete the tip?`)}
				title={t('Delete Tip')}
				titleCentered
				variant="medium"
				visible={!!tipToDelete}
				onCancel={() => setTipToDelete(null)}
				onConfirm={async () => {
					if (tipToDelete) await deleteTip(tipToDelete);
					setTipToDelete(null);
				}}
			/>
		</>
	);
	return [meatballMenuOptions, ConfirmationModals] as const;
};
