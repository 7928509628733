import styles from './PatchTipForm.module.css';
import type { FormikProps } from 'formik';
import type { ReactNode, Ref } from 'react';
import { Formik, Button } from '@ui-kit';
import { toString } from '@utils';
import type { TTip } from '@typings';
import { useEditOwnerTipMutation } from '@ui-modules/tipJar/hooks/useEditOwnerTipMutation';
import { createTipFormSchema } from '@schemas';
import { useTranslation } from '@hooks';
import type { TPatchTipForm } from '@schemas';

const PatchTipForm = <T extends keyof TPatchTipForm>({
	initialValues,
	onCancel,
	children,
	tipId,
	propertyName,
	onTipPatched,
	formikRef,
}: IPatchTipFormProps<T>) => {
	const { t } = useTranslation();

	const { mutateAsync: editTip } = useEditOwnerTipMutation(toString(tipId), {
		onSuccess: (data, variables) => onTipPatched?.(variables),
	});
	const schema = createTipFormSchema.pick([propertyName]);

	return (
		<Formik<Pick<TPatchTipForm, T>>
			initialValues={initialValues}
			innerRef={formikRef}
			validationSchema={schema}
			onSubmit={(values) => (tipId ? editTip(values) : onTipPatched?.(values))}
		>
			{(formProps) => {
				return (
					<div>
						{children(formProps)}
						<div className={styles.tipEditableInput__buttonsContainer}>
							{propertyName === 'galleryFiles' ? null : (
								<>
									<Button
										className={styles.tipEditableInput_cancelButton}
										title={t('Cancel')}
										type={'transparent'}
										variant={'small'}
										onClick={onCancel}
									/>

									<Button
										disabled={!formProps.isValid}
										title={t('Save')}
										variant={'small'}
										onClick={formProps.submitForm}
									/>
								</>
							)}
						</div>
					</div>
				);
			}}
		</Formik>
	);
};

export interface IPatchTipFormMainProps {
	tipId: TTip['id'] | undefined;
	onTipPatched?: (formValues: Partial<TPatchTipForm>) => void;
}

export interface IPatchTipFormProps<T extends keyof TPatchTipForm> extends IPatchTipFormMainProps {
	onCancel: () => void;
	children: (formikProps: FormikProps<Pick<TPatchTipForm, T>>) => ReactNode;
	propertyName: T;
	initialValues: Pick<TPatchTipForm, T>;
	formikRef?: Ref<FormikProps<Pick<TPatchTipForm, T>>>;
}

export default PatchTipForm;
