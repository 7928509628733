import { ActivityIndicator } from '@ui-kit';
import { useTranslation } from '@hooks';
import { usePickUpFile } from '@ui-modules/files/hooks/usePickUpFile';
import { clsx } from '@utils';
import styles from './TipImageUploadInput.module.css';

export const TipImageUploadInput = ({
	uploading = false,
	disabled = false,
	onUploadFile,
}: ITipImageUploadInputProps) => {
	const { t } = useTranslation();
	const { getInputProps, openFilePicker } = usePickUpFile(onUploadFile);

	return (
		<div className={styles.tipImageUploadInput}>
			{uploading ? (
				<div className={styles.tipImageUploadInput__title}>
					<ActivityIndicator color="#000" size="extraSmall" />
					<span>{`${t('Uploading')} ...`}</span>
				</div>
			) : (
				<div className={styles.tipImageUploadInput__title}>
					<span>{t('Attach')}</span>
					<span>{t('Image')}</span>
				</div>
			)}

			<label
				className={clsx(styles.tipImageUploadInput__label, uploading && styles.tipImageUploadInput__labelDisabled)}
				htmlFor="fileInput"
			>
				<input
					className={styles.tipImageUploadInput__input}
					disabled={disabled || uploading}
					id="fileInput"
					type="file"
					{...getInputProps()}
					onClick={openFilePicker}
				/>
				<span className={styles.tipImageUploadInput__selectFileText}>{t('Select Image')}</span>
			</label>
		</div>
	);
};

export interface ITipImageUploadInputProps {
	uploading: boolean;
	disabled?: boolean;
	onUploadFile: (file: File) => void;
}

export default TipImageUploadInput;
