import { memo } from 'react';
import styles from './StarsRating.module.css';
import { StarFilledIcon, StarIcon } from '@ui-kit/icons';
import clsx from 'clsx';
import { HashLink } from 'react-router-hash-link';

const StarsRating: React.FC<IStarsRatingProps> = ({
	rating,
	iconSize = 16,
	label,
	onClick,
	name,
	labelHref,
	containerStyle,
}) => {
	return (
		<div className={clsx(styles.starsRating, containerStyle)}>
			<div aria-label={label} className={styles.starsRating__stars} role="radiogroup" tabIndex={0}>
				{Array.from({ length: 5 })
					.map((_, index) => {
						const filled = index < rating;
						return (
							<div
								aria-checked={filled}
								aria-disabled={!onClick}
								aria-labelledby={`star-${index}`}
								className={styles.starsRating__button}
								data-index={index + 1}
								key={index}
								role="radio"
								style={{ cursor: onClick ? 'pointer' : 'default' }}
								tabIndex={0}
								onClick={() => onClick?.(index + 1)}
								onKeyDown={(e) => {
									if (e.key === 'Enter' || e.key === ' ') {
										onClick?.(index + 1);
									}
								}}
							>
								{filled ? (
									<StarFilledIcon height={iconSize} width={iconSize} />
								) : (
									<StarIcon height={iconSize} width={iconSize} />
								)}
							</div>
						);
					})
					/* Reverse the order of the stars to handle starts hover effect by pure CSS */
					.reverse()}
				{name && <input name={name} type="hidden" value={rating} />}
			</div>
			{label && <StarsLabel label={label} labelHref={labelHref} />}
		</div>
	);
};

const StarsLabel = ({ label, labelHref }: IStarsLabelProps) => {
	return (
		<div className={styles.starsRating__labelContainer}>
			{labelHref ? (
				<HashLink className={styles.starsRating__label_link} smooth to={labelHref}>
					{label}
				</HashLink>
			) : (
				<span className={styles.starsRating__label}>{label}</span>
			)}
		</div>
	);
};

export interface IStarsRatingProps {
	rating: number;
	containerStyle?: string;
	label?: string;
	iconSize?: number;
	onClick?: (newRating: number) => void;
	name?: string;
	labelHref?: string;
}

interface IStarsLabelProps {
	label: string;
	labelHref?: string;
}

export default memo(StarsRating);
