import { useService, useMutation } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import type { TCreateTipDto, TTip, TTipImage } from '@typings';
import type { TPatchTipForm, TPatchTipImage } from '@schemas';
import { useUploadTipFileMutation } from './useUploadTipFileMutation';
import useRemoveTipUserImageMutation from './useRemoveTipUserImageMutation';

export const useEditOwnerTipMutation = (
	tipId: TTip['id'],
	mutationOptions?: MutationOptions<TTip, Error, TUseEditOwnerTipMutationPayload>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	const uploadTipFileMutation = useUploadTipFileMutation();
	const removeTipUserImageMutation = useRemoveTipUserImageMutation({ tipId, galleryFiles: [] });

	return useMutation<TTip, Error, TUseEditOwnerTipMutationPayload>(
		async ({ coverImageIndex, ...patchedTipData }) => {
			let tip = await api.tips.getTip(tipId);

			if ('galleryFiles' in patchedTipData) {
				const existingUserTipImage = tip.galleryFiles?.find((image) => !image.isAiGenerated);
				const patchedUserTipImage = patchedTipData.galleryFiles?.find((image) => !image.isAiGenerated);
				const oldImageUrl = existingUserTipImage?.documentUrl;
				const newImageUrl = patchedUserTipImage?.documentUrl;
				const imageRemoved = !!oldImageUrl && (!newImageUrl || newImageUrl !== oldImageUrl);
				const imageAdded = !!newImageUrl && newImageUrl !== oldImageUrl;

				if (imageAdded) {
					await uploadTipFileMutation.mutateAsync({
						tipId,
						patchTipImage: patchedUserTipImage as TPatchTipImage,
					});
					tip = await api.tips.getTip(tipId);
				}

				const updatedTip = await api.tips.putTip(
					tipId,
					makeCreateTipDto(tip, patchedTipData, coverImageIndex, imageRemoved ? existingUserTipImage : undefined),
				);

				if (imageRemoved) {
					await removeTipUserImageMutation.mutateAsync();
				}

				return updatedTip;
			} else {
				const updatedTip = await api.tips.putTip(
					tipId,
					makeCreateTipDto(tip, patchedTipData, coverImageIndex, undefined),
				);
				return updatedTip;
			}
		},
		{
			...mutationOptions,
			onSuccess: async (data, variables, context) => {
				if (tipId) {
					reactQuery.queryClient.setQueryData<TTip>(reactQuery.queryKeys.tip(tipId), (existingTip) => {
						if (!existingTip) return existingTip;
						return { ...existingTip, ...data } as TTip;
					});
				}
				await mutationOptions?.onSuccess?.(data, variables, context);

				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.myTips());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.allTips());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.groupsTips());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.communityTips());
			},
		},
	);
};

function makeCreateTipDto(
	tip: TTip,
	patchedTipData: Partial<TPatchTipForm>,
	coverImageIndex: number | undefined | null,
	removedImage?: TTipImage,
): TCreateTipDto {
	const getDefaultCoverId = (galleryFiles: TTip['galleryFiles']) => {
		const coverImage = galleryFiles.find((image) => image.documentUrl === tip.coverUrl);
		return coverImage ?? galleryFiles.find((image) => image.isAiGenerated) ?? null;
	};
	const findCoverImageByAiGeneratedValue = (galleryFiles: TTip['galleryFiles'], aiGenerated: boolean) => {
		return galleryFiles.find((image) => image.isAiGenerated === aiGenerated);
	};
	const filterRemovedImageFromGalleryFiles = (galleryFiles: TTip['galleryFiles'], removedImage?: TTipImage) => {
		if (!removedImage?.id) return galleryFiles;
		return galleryFiles.filter((image) => image.id !== removedImage.id);
	};

	const coverIsAiGenerated: boolean | null =
		typeof coverImageIndex === 'number'
			? (patchedTipData?.galleryFiles?.[coverImageIndex]?.isAiGenerated ?? null)
			: null;
	const onlyExistingGalleryFiles = filterRemovedImageFromGalleryFiles(tip.galleryFiles, removedImage);
	const coverId =
		coverIsAiGenerated === null
			? getDefaultCoverId(onlyExistingGalleryFiles)?.id
			: findCoverImageByAiGeneratedValue(onlyExistingGalleryFiles, coverIsAiGenerated)?.id;

	const updateTipDto: TCreateTipDto = {
		...tip,
		coverId: coverId ?? null,
		title: patchedTipData?.title ?? tip.title,
		description: patchedTipData?.description ?? tip.description,
		tagsIds: tip.tags.map((tag) => tag.id),
		ownerId: tip.owner.id,
	};

	return updateTipDto;
}

type TUseEditOwnerTipMutationPayload = Partial<TPatchTipForm> & { coverImageIndex?: number | null };
