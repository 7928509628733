import { CommunityEntity, memoize } from '@utils';
import type { TCommunity, TGroupTabName, TUser } from '@typings';

/** Determines abilities to see single group tabs in order to restrict access. */
export const getGroupTabsPermissions = memoize((group: TCommunity | undefined, user: TUser) => {
	const tabs: TGroupTabName[] = [];

	if (group) {
		const isGroupUpcoming = CommunityEntity.isUpcomingGroup(group);
		const isMeetingsShown = CommunityEntity.shouldShowMeetingsOrEvents(group, user.roles) || isGroupUpcoming;
		const isMembersShown = CommunityEntity.shouldShowMembers(group, user.roles);
		const isDocumentsShown = CommunityEntity.shouldShowDocumentsV2(group, user.roles);
		const isResourcesShown = CommunityEntity.shouldShowResources(group);
		const isTipsShown = CommunityEntity.shouldShowTips(group, user);

		if (isMeetingsShown) tabs.push('meetings');
		if (isMembersShown) tabs.push('members');
		if (isDocumentsShown) tabs.push('files');
		if (isResourcesShown) tabs.push('resources');
		if (isTipsShown) tabs.push('tips');

		return {
			tabs,
			isGroupUpcoming,
			isMeetingsShown,
			isMembersShown,
			isDocumentsShown,
			isResourcesShown,
			isTipsShown,
		};
	} else {
		return {
			tabs,
		};
	}
});
