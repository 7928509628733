import React, { useEffect } from 'react';
import styles from './ShareableCard.module.css';
import { CloseIcon, DealsIcon, EventsIcon, NetworkFilledIcon, NetworkIcon } from '@ui-kit/icons';
import { Avatar } from '@ui-kit/index';
import EventAvatar from '@ui-modules/events/components/EventAvatar';
import { useSharedContent } from '@ui-modules/share/hooks/useSharedContent';
import type { TEvent } from '@typings';
import { clsx } from '@utils';

const ShareableCard: React.FC<IShareableCardProps> = ({ clearInternalShare, containerClassName, sharedContent }) => {
	const { data, isLoading, isError } = useSharedContent(sharedContent);

	useEffect(() => {
		if (isError) {
			clearInternalShare?.();
		}
	}, [isError]);

	const renderIcon = () => {
		switch (data.type) {
			case 'discussion':
				return <Avatar imageUri={data.image} outline={false} size={34} />;
			case 'network_connection':
				return data.image ? (
					<Avatar imageUri={data.image} outline={false} size={34} />
				) : (
					<NetworkFilledIcon className={styles.icon} />
				);
			case 'network':
			case 'group':
			case 'chapter':
			case 'network_connection_post':
				return data.image ? (
					<Avatar imageUri={data.image} outline={false} size={34} />
				) : (
					<NetworkIcon className={styles.icon} />
				);
			case 'deal':
			case 'deal_post':
				return data.image ? (
					<Avatar imageUri={data.image} outline={false} size={34} />
				) : (
					<DealsIcon className={styles.icon} />
				);
			case 'event':
			case 'meeting':
				return data.compactEvent?.startDate || data.compactEvent?.endDate ? (
					<EventAvatar event={data.compactEvent as TEvent} />
				) : (
					<EventsIcon className={styles.icon} />
				);
			default:
				return null;
		}
	};

	return (
		<div className={clsx(styles.card, containerClassName)}>
			{/* Background gradient */}
			<div className={styles.gradientBackground} />
			{/* Card content */}
			<div className={styles.content}>
				{isLoading ? (
					<div className={styles.loader}>Loading...</div>
				) : (
					<>
						{renderIcon()}
						<div className={styles.information}>
							{data.source && (
								<div className={styles.source} title={data.source}>
									{data.source}
								</div>
							)}
							{data.title && (
								<div className={styles.title} title={data.title}>
									{data.title}
								</div>
							)}
							{data.subtitle && (
								<div className={styles.subtitle} title={data.subtitle}>
									{data.subtitle}
								</div>
							)}
						</div>
					</>
				)}
			</div>
			{/* Close Button */}
			{clearInternalShare && (
				<button className={styles.closeButton} onClick={clearInternalShare}>
					<CloseIcon fill={'#333'} height={8} width={8} />
				</button>
			)}
		</div>
	);
};

export interface IShareableCardProps {
	clearInternalShare?: () => void;
	containerClassName?: string;
	sharedContent: string;
}

export default ShareableCard;
