import { useMemo } from '@hooks';
import type { TTip, TTipContext } from '@typings';
import { useTipReviewsQuery } from './useTipReviewsQuery';
import { useTipQuery } from './useTipQuery';

export const useTipContributorsQuery = (tipId: TTip['id'], tipContext?: TTipContext, reviewOwnerCount = 4) => {
	const {
		data: tip,
		isFetching: isTipFetching,
		isInitialLoading: isTipInitialLoading,
	} = useTipQuery(tipId, tipContext);
	const {
		data: tipReviews,
		isFetching: isTipReviewsFetching,
		isInitialLoading: isTipReviewsInitialLoading,
	} = useTipReviewsQuery(tipId, tipContext, true);

	const tipContributors = useMemo(() => {
		if (!tip || !tipReviews) return undefined;
		const reviewOwners = tipReviews.slice(0, reviewOwnerCount).map((review) => review.owner);
		const reviewsCount = tip.details.reviewsCount;
		return { reviewOwners, reviewsCount };
	}, [tip, tipReviews]);

	return {
		tipContributors,
		isFetching: isTipFetching || isTipReviewsFetching,
		isInitialLoading: isTipInitialLoading || isTipReviewsInitialLoading,
	};
};
