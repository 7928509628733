import type { IPatchTipFormMainProps } from '@ui-modules/tipJar/components/PatchTipForm';
import type { TPatchTipImage } from '@schemas';
import type { IInfinitePaginatedListData, TTip, TTipWithDetails } from '@typings';
import { useMutation, useService } from '@hooks';
import { useTipQuery } from './useTipQuery';

const useRemoveTipUserImageMutation = ({ galleryFiles, tipId, onTipPatched }: IUseRemoveTipUserImageMutation) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	const { data: tip } = useTipQuery(tipId);

	return useMutation(
		async () => {
			if (!tipId || !tip) return;

			const userTipImage = tip.galleryFiles.find((image) => !image.isAiGenerated);
			if (userTipImage) {
				await api.tips.deleteTipImage(userTipImage.id);
			}
			const updatedTip: TTip = {
				...tip,
				galleryFiles: tip.galleryFiles.filter((image) => image.isAiGenerated),
			};
			return updatedTip;
		},
		{
			onSuccess: async (tip) => {
				if (tipId && tip) {
					const galleryFiles = tip.galleryFiles.filter((image) => image.isAiGenerated);
					onTipPatched?.({ galleryFiles });
					reactQuery.queryClient.setQueriesData<TTip>(reactQuery.queryKeys.tip(String(tipId)), (existingTip) => {
						if (!existingTip) return existingTip;
						return { ...existingTip, galleryFiles };
					});

					reactQuery.queryClient.setQueryData<IInfinitePaginatedListData<TTipWithDetails>>(
						reactQuery.queryKeys.myTips(),
						reactQuery.mutateEntityListInfiniteQueryCache<TTipWithDetails>(tip.id, (tip) => {
							if (!tip) return tip;
							tip.galleryFiles = galleryFiles;
							return tip;
						}),
					);

					reactQuery.queryClient.setQueryData<IInfinitePaginatedListData<TTipWithDetails>>(
						reactQuery.queryKeys.allTips(),
						reactQuery.mutateEntityListInfiniteQueryCache<TTipWithDetails>(tip.id, (tip) => {
							if (!tip) return tip;
							tip.galleryFiles = galleryFiles;
							return tip;
						}),
					);

					reactQuery.queryClient.setQueryData<IInfinitePaginatedListData<TTipWithDetails>>(
						reactQuery.queryKeys.groupsTips(),
						reactQuery.mutateEntityListInfiniteQueryCache<TTipWithDetails>(tip.id, (tip) => {
							if (!tip) return tip;
							tip.galleryFiles = galleryFiles;
							return tip;
						}),
					);

					reactQuery.queryClient.setQueryData<IInfinitePaginatedListData<TTipWithDetails>>(
						reactQuery.queryKeys.communityTips(),
						reactQuery.mutateEntityListInfiniteQueryCache<TTipWithDetails>(tip.id, (tip) => {
							if (!tip) return tip;
							tip.galleryFiles = galleryFiles;
							return tip;
						}),
					);
				} else {
					onTipPatched?.({
						galleryFiles: galleryFiles.filter((image) => image.isAiGenerated),
					});
				}
			},
		},
	);
};

interface IUseRemoveTipUserImageMutation extends IPatchTipFormMainProps {
	galleryFiles: TPatchTipImage[];
}

export default useRemoveTipUserImageMutation;
