import { useState } from 'react';
import { useSearchParams } from '@hooks';

export const useStoredShareContent = (sharedContent?: string | null | undefined) => {
	const [storedShareContent, setStoredShareContent] = useState<string | null | undefined>(sharedContent);
	const [searchParams, setSearchParams] = useSearchParams();

	const clearStoredShareContent = () => {
		if (searchParams) {
			const newParams = new URLSearchParams(searchParams);
			newParams.delete('internalShare');
			setSearchParams(newParams);
		}
		setStoredShareContent(null);
	};

	return {
		storedShareContent,
		clearStoredShareContent,
		setStoredShareContent,
	};
};
